import React, { Fragment } from "react";
import Lottie from 'react-lottie';
import loader from 'assets/animations/loader.json'
import "./loader.scss";

const LoaderPopUp = () => {
  return (
    <Fragment>
      <section className="loader_pop_up_wrapper">
        <div className="loader_pop_up_inner_wrapper">
          {/* <Loader type="TailSpin" color="#FE9100" width={150} height={150} /> */}
          <Lottie
            options={{ loop: true, autoplay: true, animationData: loader }}
            height={'100%'}
            width={'100%'}
          />
        </div>
      </section>
    </Fragment>
  );
};

export default LoaderPopUp;
