import React from 'react';
import TourTooltip from 'Components/TourTooltip/TourTooltip';
import { levelColor } from 'utils/Functions';
import './ProgressIndicator.scss';

/**
 *
 * @param {state} inprogress, complete, locked
 * @param {type} defaul '' else subtopic. subtopic is passed as class name and is used to
 * reduce the size of progress circle
 * @returns
 */
const ProgressIndicator = ({ state, type = '', level = 0, percentage, isCompleted = false }) => {
    const computeProgress = () => {
        return 339.292 - 339.292 * (percentage / 100);
    };

    return (
        <div className={`indicator-container ${state} ${type}`}>
            <TourTooltip path={1} step={2} show={state === 'inprogress' && type !== 'subtopic'}>
                <div className="svg-wrapper">
                    {state !== 'completed' && (
                        <svg viewBox="0 0 120 120">
                            <circle className="progress__meter" cx="60" cy="60" r="54" strokeWidth={type === 'subtopic' ? 3 : 8} />
                            <circle className="progress__value" cx="60" cy="60" r="54" strokeWidth={type === 'subtopic' ? 3 : 8} strokeDasharray={339.292} strokeDashoffset={computeProgress()} />
                        </svg>
                    )}
                    <img className="c-icon-topic" src={require(`assets/svgIcons/planning-topic-${isCompleted ? 'complete' : 'inprogress'}.svg`)} />
                </div>
            </TourTooltip>
            {/* <div className="progress"></div> */}
            <div className="level" style={{ backgroundColor: levelColor[level % 4] }}>
                {level}
            </div>
        </div>
    );
};

export default ProgressIndicator;
