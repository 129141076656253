import React from 'react';
import "./Notifications.scss";

const NotificationItem = () => {
    return (
        <div className='scheduler-notification-item-container'>
            <div className='scheduler-notification-unseen-dot-container'></div>
            <div className='scheduler-notification-flame-container'>
                <img
                    src={require("assets/svg/Notification_Flame.svg")}
                    alt="notification_flame"
                    className='notification_svg'
                />
            </div>
            <div className='scheduler-notification-data-container'>
                <div className='scheduler-notification-data-title'>
                    Your password has been changed successfully
                </div>
                <div className='scheduler-notification-data-time'>
                    Aug 23, 2021 at 05:32 PM
                </div>
            </div>
        </div>
    )
}

const Notifications = () => {
    return (
        <div className='scheduler-notifications-main-container'>
            <div className='scheduler-notification-inner-container'>
                <div className='scheduler-notification-head-text-container'>
                    <div className="shceduler-notification-text-cotnainer">
                        Notifications
                    </div>
                    <div className='scheduler-notification-mark-all-container'>
                        <img 
                            src={require("assets/svg/notification_tick_mark_read.svg")}
                            alt="tick_notification"
                            className='notification_mark_tick'
                        />
                        Mark all read
                    </div>
                </div>
                <div className='scheduler-notification-notifications-container'>
                    <NotificationItem />
                    <NotificationItem />
                    <NotificationItem />
                </div>
                <div className='scheduler-notification-view-all-container'>
                    View all notifications
                </div>
            </div>
        </div>
    );
};

export default Notifications;