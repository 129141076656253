import React from 'react';
import './ModuleIntro.scss';

const ModuleIntro = ({ title, description, imgSrc, onButtonClick }) => {
    return (
        <div className="intro-popup-wrapper">
            <div className="top-popup-section">
                <h2 className="popup-heading">{title}</h2>
                <p className="popup-description">{description}</p>
            </div>
            <div className="middle-popup-section">
                <img src={imgSrc} />
            </div>
            <div className="bottom-popup-section">
                <button onClick={onButtonClick}>Let's explore</button>
            </div>
        </div>
    );
};

export default ModuleIntro;