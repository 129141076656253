export const SETASSESSMENTDATA = "SETASSESSMENTDATA";
export const SETRESPONSE = "SETRESPONSE";
export const SETATTEMPTLATER = "SETATTEMPTLATER";
export const SETRESULT = "SETRESULT";
export const SETREWARDS = "SETREWARDS";
export const CLEARREWARDS = "CLEARREWARDS";
export const UPDATERESULT = "UPDATERESULT";
export const SETALREADYTAKEN = "SETALREADYTAKEN";
export const INITIALIZEASSESSMENTDATA = "INITIALIZEASSESSMENTDATA";
export const SETSHOWANSWERS = "SETSHOWANSWERS";
export const SKIPASSESSMENT = "SKIPASSESSMENT";
export const CLEARDATA = "CLEARDATA";
