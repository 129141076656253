import {trackEvent, trackNewEvent} from './base';

export const trackSchedulerTaskClicked = taskIndex => {
  trackNewEvent('Scheduler Event', {
    [`Task_${taskIndex}_Clicked`]: true,
  });
};

export const trackSchedulerTaskCompleted = taskIndex => {
  trackNewEvent('Scheduler Event', {
    [`Task_${taskIndex}_Completed`]: true,
  });
};

export const trackNewTasksAssigned = () => {
  trackNewEvent('Scheduler Event', {
    New_Task_Added: true,
  });
};

export const trackAllTasksCompleted = () => {
  trackNewEvent('Scheduler Event', {
    Daily_Task_Completed: true,
  });
};