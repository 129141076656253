import React from 'react';
import Popup from 'Components/Popup/Popup';

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        error: null,
        info: null
    };

    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
            error: error,
            info: info
        });
    }

    render() {
        if (this.state.hasError) {
            return <Popup
                isOpen={true}
                type={'center'}
                data={this.state}
                id={3}
                onClose={() => {
                    this.setState({ hasError: false });
                }}
            />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
