import React from 'react';
import './PopupContainer.scss';
import { useSelector } from 'react-redux';
import ProgressBar from 'Components/ProgressBar/ProgressBar';
import { useDispatch } from 'react-redux';
import { setPopup } from 'redux/actions/uiActions/actions';
import { clearTestRewards } from 'redux/actions/assessmentActions/actions';
import Lottie from 'react-lottie';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { trackRewardsPopup } from 'analytics/stats';
import ShareCards from './ShareCards/ShareCards';
const PopupContainer = ({ onClosePopup }) => {
    let popupData = useSelector((store) => store.ui.popupData);
    const { Continue } = popupData ?? { Continue: () => {} };
    const rewards = useSelector((store) => store.assessment.rewards);
    const statDetail = useSelector((store) => store.stat.statDetail);
    const dispatch = useDispatch();

    useEffect(() => {
        let level = popupData?.type === 'level' ? statDetail?.level : null;
        trackRewardsPopup(popupData?.xp, level);
    }, []);

    const callStreak = () => {
        dispatch(
            setPopup(13, {
                Continue,
                source: 'streak completion',
            })
        );
    };
    const callLevel = () => {
        dispatch(
            setPopup(12, {
                animationPath: require('assets/animations/level-up.json'),
                share: true,
                progress: false,
                type: 'level',
                xp: rewards?.isLevelUp?.xpProgress,
                Continue,
                source: 'level up completion',
            })
        );
    };

    const callTopicComplete = () => {
        dispatch(
            setPopup(12, {
                heading: "You've completed a Topic!",
                subHeading: 'Piece of cake! On to the next one…!',
                share: true,
                animationPath: require('assets/animations/topic-complete.json'),
                progress: false,
                type: 'topic',
                xp: rewards?.isTopicComplete?.xpEarned,
                Continue,
                source: 'topic completion',
            })
        );
    };
    const callDailyTask = () => {
        dispatch(
            setPopup(12, {
                heading: 'Your daily task is complete!',
                subHeading: "All in a day's work for you 😉. Awesome job!",
                animationPath: require('assets/animations/daily-task.json'),
                share: false,
                progress: false,
                type: 'task',
                xp: rewards?.dailyTask?.xpEarned,
                Continue,
                source: 'daily task completion',
            })
        );
    };

    const onContinue = () => {
        if (popupData?.type === 'xp') {
            if (rewards?.isStreak) {
                callStreak();
            } else if (rewards?.dailyTask) {
                callDailyTask();
            } else if (rewards?.isTopicComplete) {
                callTopicComplete();
            } else if (rewards?.isLevelUp) {
                callLevel();
            } else {
                onClosePopup();
                dispatch(clearTestRewards());
                Continue();
            }
        } else if (popupData?.type === 'task') {
            if (rewards?.isTopicComplete) {
                callTopicComplete();
            } else if (rewards?.isLevelUp) {
                callLevel();
            } else {
                onClosePopup();
                dispatch(clearTestRewards());
                Continue();
            }
        } else if (popupData?.type === 'topic') {
            if (rewards?.isLevelUp) {
                callLevel();
            } else {
                onClosePopup();
                dispatch(clearTestRewards());
                Continue();
            }
        } else {
            onClosePopup();
            dispatch(clearTestRewards());
            Continue();
        }
    };
    const defaultOptions = {
        loop: false,
        autoplay: true,
    };
    return (
        <div className="popup-box">
            {popupData?.title && <div className={`title ${popupData?.type ? `${popupData?.type}` : ''}`}>{popupData?.title}</div>}
            <div className="image-container">
                {popupData?.animationPath && (
                    <Lottie
                        options={{
                            ...defaultOptions,
                            animationData: cloneDeep(popupData?.animationPath),
                        }}
                        height={'100%'}
                        width={'100%'}
                    />
                )}
                {popupData?.type === 'level' && <div className="level-value">{statDetail?.level}</div>}
            </div>

            {popupData?.progress && (
                <div className="progress-box">
                    <ProgressBar percentage={70} stats={true} />
                    <img className="progress-img" src={require('assets/svg/achievement.svg')} alt="" />
                </div>
            )}

            {popupData?.heading && (
                <>
                    {popupData?.type === 'xp' ? (
                        <div className="heading">
                            You earned <span>{popupData?.xp}</span> XP
                        </div>
                    ) : (
                        <div className="heading">{popupData?.heading}</div>
                    )}
                    <div className="sub-heading">{popupData?.subHeading}</div>
                </>
            )}
            {popupData?.share && (
                <div className="mobile-share-cta btn-cta">
                    <ShareCards type={popupData?.type} source={popupData?.source} />
                </div>
            )}
            {popupData?.type == 'level' ? (
                <div className="box-wrapper">
                    <div className="box-1">
                        <p className="box-heading">XP Earned</p>
                        <img className="box-image" src={require('assets/svgIcons/diamond.svg')} alt="" />
                        <p className="box-value">{rewards?.isLevelUp?.xpEarned}</p>
                    </div>
                </div>
            ) : (
                <div className="box-wrapper">
                    <div className="box-1">
                        <p className="box-heading">XP Earned</p>
                        <img className="box-image" src={require('assets/svgIcons/diamond.svg')} alt="" />
                        <p className="box-value">{popupData?.xp}</p>
                    </div>
                </div>
            )}

            <div className="btn-cta">
                {popupData?.share && (
                    <button className="share hide" onClick={() => {}}>
                        Share Progress
                    </button>
                )}
                <button className="primary continue" onClick={onContinue}>
                    Continue
                </button>
            </div>
            <div className="cross" onClick={onContinue}>
                <img src={require('assets/svgIcons/cross.svg')} alt="" />
            </div>
        </div>
    );
};

export default PopupContainer;
