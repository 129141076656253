import {
    SETPLANLIST,
    SETUSERPLANS,
    SETGSTDATA
} from './actionType';

export const setPlanList = (plans) => dispatch => {
    let sortedPlan = plans.sort((plan1, plan2) => {
        return plan1?.priority - plan2?.priority
    });

    dispatch({
        type: SETPLANLIST,
        payload: sortedPlan.reverse()
    });
}

export const setUserPlans = (plans) => dispatch => {
    dispatch({
        type: SETUSERPLANS,
        payload: plans
    });
};

export const updateUserPlans = (plan) => (dispatch, getState) => {
    let plans = getState().plan.userPlans ?? [];
    plans.push(plan);

    dispatch({
        type: SETUSERPLANS,
        payload: [...plans]
    });
}

export const setGstData = (data) => dispatch => {
    dispatch({
        type: SETGSTDATA,
        payload: data
    })
}