import { SETSURVEYQUESTIONS, SETSURVEYDATA } from './actionType';

export const addSurveyQuestions = data => dispatch => {
  dispatch({
    type: SETSURVEYQUESTIONS,
    payload: data,
  });
};

export const initializeSurveyData = () => dispatch => {
  let data = localStorage.getItem('survey-data');
  if (data) {
    dispatch({
      type: SETSURVEYDATA,
      payload: JSON.parse(data),
    });
  }
};

export const setSurveyResponse = (surveyId, answer) => (dispatch, getState) => {
  let surveyQuestions = getState().survey.surveyQuestions;
  let surveyResponse = getState().survey.surveyResponse;
  let isSurveyComplete = false;
  let newSurveyData = {
    surveyId,
    option: answer,
  };

  surveyResponse = [...surveyResponse, newSurveyData];

  if (surveyQuestions?.length === surveyResponse?.length)
    isSurveyComplete = true;

  dispatch({
    type: SETSURVEYDATA,
    payload: { surveyResponse, isSurveyComplete },
  });
  localStorage.setItem('survey-data', JSON.stringify({ surveyResponse, isSurveyComplete }),);
};
