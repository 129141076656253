import Cookies from 'js-cookie';
import Request from 'utils/Request';
import {
    setAuthToken,
    logout
} from 'redux/actions/authActions/actions';
import {
    setUserData
} from 'redux/actions/userActions/actions';
import {
    checkSurveyAssessment
} from 'redux/actions/guestActions/actions';
import {
    clearLocalData
} from 'redux/actions/assessmentActions/actions';
import {
    updateUserData
} from 'services/user';
import {
    trackSignupSource,
    trackLoginSource
} from 'analytics/onboarding';
import {
    setAppreciationPopup
} from 'redux/actions/uiActions/actions';

/**
 * This function calls the msg91 api in background to send otp on the number passed
 * @param {*} mobileNo 
 * @returns 
 */
export const submitPhoneNumber = async (mobileNo, email, action, config) => {
    try {
        const response = await Request({
            method: 'POST',
            url: '/user/otp',
            //baseURL: 'https://api.examarly.com:2021/api/user/otp',
            data: {
                mobileNo,
                email,
                action
            },
            ...config
        });
        const { status_code, message } = response.data;

        if (status_code === 200) return true;
        else return message;

    } catch (error) {
        console.log(error);
    }
}

/**
 * This function verifies if the otp user entered is correct or not
 * @param {*} mobileNumber 
 * @param {*} otp 
 * @returns 
 */

export const verifyOtp = async (mobileNumber, otp, config) => {
    try {
        const response = await Request({
            method: 'GET',
            url: '/user/otp/verify',
            //baseURL: 'https://api.examarly.com:2021/api/user/otp/verify',
            params: {
                mobileNo: mobileNumber,
                otp
            },
            ...config
        });
        const { Code, message } = response.data;

        if (Code === 0) {
            return message;
        }
        if (Code === 1) {
            return true;
        }

    } catch (error) {
        console.log(error);
    }

    return false;
}

/**
 * 
 * @param {*} {mobileNumber} if user went with phone auth
 * @param {*} {authType} phone, google, facebook
 * @param {*} {token} token we get from facebook and google if user selects auth login
 * @returns 
 */
export const handleLogin = ({
    mobileNumber,
    authType = 'MOBILE',
    token,
    otp
}, config) => async (dispatch, getState) => {
    try {
        const user = getState().user.userInfo;
        const response = await Request({
            method: 'POST',
            url: '/user/login',
            ...config,
            data: {
                authType,
                token,
                otp,
                mobileNo: mobileNumber
            }
        })
        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            if (user?.role === 'guest') {
                dispatch(deleteGuestUser(user?.id));
                dispatch(logout());
            }
            dispatch(checkIfOldUser(data.user));
            dispatch(setUserData(data.user)); //this should go inside checkIfOldUser function
            dispatch(setAuthToken(data.Token, data.user?.role));
            trackLoginSource({authType});
            return true;
        } else return message;

    } catch (error) {
        return 'Something went wrong!';
    }
}

export const checkIfOldUser = (user) => dispatch => {
    let threshholdDate = new Date(2021, 5, 1);
    let userCreatedAt = new Date(user?.createdAt);
    if (userCreatedAt < threshholdDate) {
        dispatch(updateUserData({ isAssessmentComplete: true }));
    }
}


export const handleSocialLogin = ({
    authType = 'MOBILE',
    token
}, config) =>
    async (dispatch, getState) => {
        try {
            const user = getState().user.userInfo;
            const response = await Request({
                method: 'POST',
                url: '/user/sociallogin',
                ...config,
                data: {
                    authType,
                    token,
                },
            });
            const { status_code, message, data } = response.data;

            if (status_code === 200) {
                if (user?.role === 'guest') {
                    dispatch(deleteGuestUser(user?.id));
                    dispatch(logout());
                }
                dispatch(checkIfOldUser(data.user));
                dispatch(setUserData(data.user));
                dispatch(setAuthToken(data.Token, data.user?.role));
                trackLoginSource({authType});
                return true;
            } else return message;
        } catch (error) {
            return 'Something went wrong!';
        }
    };

export const handleSocialSignup = ({
    mobileNumber,
    otp,
    referredBy,
    token,
    authType = 'GOOGLE'
}, config) =>
    async (dispatch, getState) => {
        try {
            let userData = dispatch(checkSurveyAssessment());
            const user = getState().user.userInfo;

            const response = await Request({
                method: 'POST',
                url: '/user/socialsignup',
                ...config,
                data: {
                    mobileNo: mobileNumber,
                    otp: otp,
                    authType,
                    token,
                    referredBy,
                    courseId: ['1001'],
                    ...userData
                }
            });
            const { status_code, message, data } = response.data;

            if (status_code === 201) {
                if (data.user?.isAssessmentComplete) clearLocalData();
                dispatch(setUserData(data.user));
                dispatch(setAuthToken(data.Token, data.user?.role));
                dispatch(setAppreciationPopup(5));
                trackSignupSource({authType});
                return true
            } else return message;
        } catch (error) {
            return 'Something went wrong!';
        }
    };

/**
 * 
 * @param {*} {emailAddress} Only if user entered data in form
 * @param {*} {name} Only if user entered data in form
 * @param {*} {mobileNumber} Mendatory field
 * @param {*} {authType} phone, google, facebook
 * @param {*} {token} token we get from facebook and google if user selects auth login 
 * @returns 
 */
export const handleSignup = ({
    emailAddress,
    name,
    mobileNumber,
    referredBy,
    authType = 'MOBILE',
    token,
    otp
}, config) => async (dispatch, getState) => {
    try {
        let userData = dispatch(checkSurveyAssessment());

        const response = await Request({
            method: 'POST',
            url: '/user/signup',
            ...config,
            data: {
                email: emailAddress,
                fullName: name,
                mobileNo: mobileNumber,
                authType,
                token,
                referredBy,
                courseId: ['1001'],
                otp: otp,
                ...userData
            }
        });

        const { status_code, message, data } = response.data;

        if (status_code === 201) {
            if (data.user?.isAssessmentComplete) clearLocalData();
            dispatch(setUserData(data.user));
            dispatch(setAuthToken(data.Token, data.user?.role));
            dispatch(setAppreciationPopup(5));
            trackSignupSource({authType});
            return true
        } else return message
    } catch (error) {
        console.log(error)
    }
    return 'Something went wrong!';
};

export const guestLogin = (config) => async (dispatch, getState) => {
    try {
        let userData = dispatch(checkSurveyAssessment());

        const response = await Request({
            method: 'POST',
            url: '/user/guest',
            ...config,
            data: {
                courseId: ['1001'],
                ...userData
            }
        });

        const { status_code, message, data } = response.data;

        if (status_code === 201) {
            dispatch(setUserData(data.user));
            dispatch(setAuthToken(data.Token, data.user?.role));
            return true
        }
    } catch (error) {
        console.log(error)
    }
    return false;
};

export const handleGuestSignup = ({
    emailAddress,
    name,
    mobileNumber,
    authType = 'MOBILE',
    otp,
    token
}, config) =>
    async (dispatch, getState) => {
        try {
            const response = await Request({
                method: 'POST',
                url: '/user/guest/merge',
                ...config,
                data: {
                    email: emailAddress,
                    fullName: name,
                    mobileNo: mobileNumber,
                    authType: authType,
                    otp: otp,
                    token
                },
            });

            const { status_code, message, data } = response.data;

            if (status_code === 200) {
                if (data.user?.isAssessmentComplete) clearLocalData();
                dispatch(setUserData(data.user));
                dispatch(setAuthToken(data.Token, data.user?.userType));
                dispatch(setAppreciationPopup(5));
                trackSignupSource({authType});
                return true;
            } else return message;
        } catch (error) {
            console.log(error);
        }
        return 'Something went wrong!';
    };

export const deleteGuestUser = (userId, config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'POST',
            url: '/user/guest/del',
            ...config,
            data: { userId },
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const checkReferralCode = async (code) => {
    try {
        const response = await Request({
            method: 'POST',
            url: '/user/referral/Code',
            data: {
                referredBy: code
            }
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            return true;
        }
        return false;
    }
    catch (error) {
        console.log(error)
        return false;
    }
}

export const redirecttoCommunity = () => {
    let redirectedFromCommunity = Cookies.get('redirectedFromCommunity')
    if(redirectedFromCommunity){
        Cookies.remove('redirectedFromCommunity', { domain: '.examarly.com' });
        window.open(redirectedFromCommunity, "_self")
    }
}