import React from 'react';
import { useDispatch } from 'react-redux';
import { leaveGroup } from 'services/groupStudy';

import './LeaveGroup.scss';

const LeaveGroup = ({ onClosePopup }) => {
    const dispatch = useDispatch();

    const onLeaveGroup = async () => {
        await dispatch(leaveGroup({ loader: true }));
        onClosePopup();
    };

    return (
        <div className="leave-group-popup">
            <div onClick={onClosePopup} className="cross">
                <img src={require('assets/svgIcons/cross.svg')} />
            </div>
            <div className="tick-wrapper">
                <img src={require('assets/svgIcons/circle-tick.svg')} />
            </div>
            <p className="title">You sure about leaving us?</p>
            <p className="text">We hope to see you back soon</p>
            <button onClick={onLeaveGroup} className="btn-primary">
                Exit Group
            </button>
        </div>
    );
};

export default LeaveGroup;
