import React from 'react';
import { useDispatch } from 'react-redux';
import { setPopup } from 'redux/actions/uiActions/actions';
import { logout } from 'redux/actions/authActions/actions';
import './Confirmation.scss';

const LogoutConfirmation = () => {
    const dispatch = useDispatch();

    const onButtonClick = () => {
        dispatch(setPopup(null));
        dispatch(logout());
    }

    const onNoClick = () => {
        dispatch(setPopup(null));
    }

    return (
        <div className="trial-confirmation-container">
            <h1 className="popup-title">Are you sure you want to logout ? </h1>
            <div className="bottom-section">
                <button className="btn-secondary" onClick={onNoClick}>No</button>
                <button className="btn-primary" onClick={onButtonClick}>Yes</button>
            </div>
        </div>
    );
}

export default LogoutConfirmation;