// import {StyleSheet} from 'react-native';
// import {fonts, padding} from '~/styles/variables';

// const Style = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#fff',
//     paddingHorizontal: padding.horizontalPadding,
//     paddingVertical: padding.vericalPadding,
//   },
//   itemWrapper: {
//     padding: 20,
//     justifyContent: 'center',
//     alignItems: 'center',
//     borderBottomWidth: 1,
//     borderColor: 'rgba(203, 203, 203, 0.4)',
//   },
//   close: {
//     width: 24,
//     height: 24,
//     backgroundColor: 'rgba(253, 117, 94, 0.8)',
//     borderRadius: 100,
//     justifyContent: 'center',
//     alignItems: 'center',
//     position: 'absolute',
//     right: 0,
//     margin: 12,
//   },
//   orangeTxt: {
//     color: '#FD755E',
//     fontSize: 14,
//     fontFamily: fonts.fontBold,
//   },
// });

// export default Style;
