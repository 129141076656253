import Request from 'utils/Request';
import { addSurveyQuestions } from 'redux/actions/surveyActions/actions';
import { updateUserInfo } from 'redux/actions/userActions/actions';

export const getSurveyQuestions = config => async dispatch => {
    try {
        const response = await Request({
            url: '/survey/list',
            method: 'GET',
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(addSurveyQuestions(data?.surveyData ?? []));
        }
    } catch (e) {
        console.log(e);
    }
};

export const addSurveyResponse = config => async (dispatch, getState) => {
    try {
        const surveyResponse = getState().survey.surveyResponse;
        const userId = getState().user.userInfo?.id;

        const response = await Request({
            url: '/survey/response',
            method: 'POST',
            data: {
                userId: userId,
                surveyResponse,
            },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 201) {
            dispatch(updateUserInfo({ isSurveyComplete: true }))
            console.log(message);
        }
    } catch (e) {
        console.log(e);
    }
};
