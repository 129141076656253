import {
    SETUSERINFO,
    SETWALLETDETAILS,
    SETANIMATE
} from './actionType';

/**
 * This function extracts the necessary data from the response we get from api during login, signup and getUserDetails
 * and despatches it to redux for future use
 * @param {*} param0 
 * @returns 
 */
export const setUserData = ({ email, fullName, mobileNo, referralCode, status, firstDoubt, userStreak, weakSubject, isSurveyComplete, isAssessmentComplete, notificationEnable, userDemoExpire, userDemoCompleted, userDailyQuizCompleted, planEndDate, ratingCoinsRedeemed, courseId, newSubject, hasRevision, specializedSubject, userDailyQuiz, freeTrailDays, createdAt, initialTour, homeTour, planningTour, doubtTour, statTour, subtopicTour, coinsForNextPlan, revisionTour, freemiumTour, signUpCoinsRedeemed, weeklyCompletedTask, weeklyUserStreak, userType, id }) => dispatch => {
    let isPro = true;//computeIfPro(userDemoExpire, planEndDate);
    let ratingGiven = ratingCoinsRedeemed;
    let isDemoComplete = userDemoCompleted;
    userStreak = transformDate(userStreak);
    userDailyQuiz = transformDate(userDailyQuiz);
    userDailyQuizCompleted = transformDate(userDailyQuizCompleted);

    if (freeTrailDays < 1) {
        isDemoComplete = true;
    }

    dispatch({
        type: SETUSERINFO,
        payload: { email, fullName, mobileNo, referralCode, status, userStreak, weakSubject, isSurveyComplete, isAssessmentComplete, notificationEnable, isDemoComplete, isPro, courseId, userDemoExpire, ratingGiven, firstDoubt, planEndDate, newSubject, hasRevision, specializedSubject, userDailyQuiz, userDailyQuizCompleted, freeTrailDays, initialTour, homeTour, planningTour, doubtTour, statTour, subtopicTour, coinsForNextPlan, revisionTour, freemiumTour, signUpCoinsRedeemed, weeklyCompletedTask, weeklyUserStreak, userType, createdAt, id }
    });
}

const transformDate = (dateArray) => {
    return (dateArray ?? []).map(dateObj => {
        let date = new Date(dateObj);
        date.setHours(0, 0, 0, 0);
        return date.toISOString();
    })
};

const computeIfPro = (demoExpiryDate, planExpiryDate) => {
    let demoExpiry = new Date(demoExpiryDate);
    let planExpiry = new Date(planExpiryDate);
    let currentData = new Date();
    if (demoExpiry > currentData || planExpiry > currentData) {
        return true;
    }
    return false;
}

export const setUserInfo = (data) => dispatch => {
    dispatch({
        type: SETUSERINFO,
        payload: data
    })
}

export const setWalletDetails = (data, animate = null) => dispatch => {
    dispatch({
        type: SETWALLETDETAILS,
        payload: {
            wallet: data,
            animate
        }
    })
};

export const setAnimate = () => dispatch => {
    dispatch({
        type: SETANIMATE
    })
}

export const updateUserInfo = (data) => (dispatch, getState) => {
    const userInfo = getState().user.userInfo;

    dispatch({
        type: SETUSERINFO,
        payload: { ...userInfo, ...data }
    });
};

export const addStreak = () => (dispatch, getState) => {
    let streaks = getState().user.userInfo?.userStreak ?? [];
    let weeklyUserStreak = getState().user.userInfo?.weeklyUserStreak ?? 0;
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (streaks?.[streaks?.length - 1] !== currentDate.toISOString()) {
        streaks.push(currentDate.toISOString());

        dispatch(updateUserInfo({
            userStreak: streaks,
            weeklyUserStreak: weeklyUserStreak + 1
        }));
    }
}