import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPopup } from 'redux/actions/uiActions/actions';
import { activateTrial } from 'services/plan';
import './Confirmation.scss';

const TrialConfirmation = () => {
    const user = useSelector(store => store.user.userInfo);
    const dispatch = useDispatch();

    const onButtonClick = () => {
        dispatch(setPopup(null));
        dispatch(activateTrial({ loader: true, popup: true }));
    };

    const onNoClick = () => {
        dispatch(setPopup(null));
    };

    return (
        <div className="trial-confirmation-container">
            <h1 className="popup-title">Do you want to activate {user?.freeTrailDays ?? 0} days free trial ? </h1>
            <div className="bottom-section">
                <button className="btn-secondary" onClick={onNoClick}>No</button>
                <button className="btn-primary" onClick={onButtonClick}>Yes</button>
            </div>
        </div>
    );
}

export default TrialConfirmation;