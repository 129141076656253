import { getActiveLocalGroup } from 'services/groupStudy';
import { trackNewEvent } from './base';

export const trackGroupStudyEvent = (data) => {
    const groupId = getActiveLocalGroup();
    trackNewEvent('Group Study Event', { ...data, Group_Id: groupId });
};

export const trackCreateGroup = () => {
    trackGroupStudyEvent({ Create_Group: true });
};

export const trackJoinGroup = () => {
    trackGroupStudyEvent({ Join_group: true });
};

export const trackParticipantButton = () => {
    trackGroupStudyEvent({ Participant_Button: true });
};

export const trackGroupMemberCount = (count) => {
    trackGroupStudyEvent({ Member_Count: count });
};

export const trackGroupShareCopy = () => {
    trackGroupStudyEvent({ Share_Copy: true });
};

export const trackGroupShareSocial = () => {
    trackGroupStudyEvent({ Share_Social: true });
};

export const trackLeaveGroup = () => {
    trackGroupStudyEvent({ Leave_Group: true });
};
