import React, { useEffect, useState } from 'react';
import './ChatbotLauncher.scss';

const ChatbotLauncher = ({ type = 'desktop' }) => {
    const [unread, setUnread] = useState(0);

    useEffect(() => {
        window.Intercom('onUnreadCountChange', function (unreadCount) {
            setUnread(unreadCount)
        });
    }, []);

    return (
        <div className={`chatbot-button chatbot-action ${type}`}>
            <img src={require('assets/svgIcons/chatbot-icon.svg')} />
            <div className={`unread-count ${unread > 0 ? 'show' : ''}`}>
                {unread}
            </div>
        </div>
    );
};

export default ChatbotLauncher;