import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector} from "react-redux";
/**
 * This checks if token is present or not
 * If no present then it redirects the user from verified route to a unverified route(/)
 * @param {*} param0 
 * @returns 
 */
const ProtectedRoute = ({ path, Component, name, ...rest }) => {
  const token = useSelector(store => store.auth.token);
  const userInfo = useSelector(store => store.user.userInfo);

  const computeRedirect = () => {
    if (token) {
      if (userInfo?.isSurveyComplete === false) {
        return <Redirect to='/user-survey' />
      }
      else {
        return <Route render={(props) => <Component {...props} name={name} />} path={path} exact {...rest} />
      }
    } else {
      return <Redirect to='/' />
    }
  }

  return (
    computeRedirect()
  )
}

export default ProtectedRoute;
