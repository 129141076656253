import React, { Suspense, useEffect, useState } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoaderPopUp from "../Components/loader/loader";
import UnverifiedRoute from "../Components/AuthMiddleware/UnverifiedRoute";
import ProtectedRoute from "../Components/AuthMiddleware/ProtectedRoute";
import GuestRoute from "Components/AuthMiddleware/GuestRoute";
import GroupRoute from "Components/AuthMiddleware/GroupRoute";
import NoGroupRoute from "Components/AuthMiddleware/NoGroupRoute";
import { initializeSurveyData } from "redux/actions/surveyActions/actions";
import {
  getCourseList,
  getSubjectList,
  getSubjectPreferenceList,
} from "services/planningAndTracking";
import { getSurveyQuestions } from "services/survey";
import { setPopup } from "redux/actions/uiActions/actions";
import { initializeDailyTask } from "redux/actions/homeActions/actions";
import {
  initializeAlreadyTaken,
  initializeAssessmentData,
} from "redux/actions/assessmentActions/actions";
import { Routes } from "../utils/Routes";
import { cancelApiRequests } from "redux/actions/uiActions/actions";
import { getUserData } from "services/user";
import { trackPage, identifyUser } from "analytics/base";
import { initializeMixPanel } from "config/MixPanel";
import ErrorBoundary from "Components/ErrorBoundaries/ErrorBoundaries";
import Popup from "Components/Popup/Popup";
import AppreciationPopups from "Components/AppreciationPopups/AppreciationPopups";
import ChatbotLauncher from "Components/ChatbotLauncher/ChatbotLauncher";
import { getCourseStats, getMonthlyStreak, getStatData } from "services/stats";
import { getUserGroups } from "services/groupStudy";
import { isRedirectedFromCashFree, showEarlyBirdPopup } from "services/payments";
import { redirecttoCommunity } from 'services/auth';

const App = () => {
  /* state */
  const [initialTime, setInitialTime] = useState(new Date());

  /* redux */
  const dispatch = useDispatch();
  const subjects = useSelector((store) => store.planning.subjectList);
  const pendingRequests = useSelector((store) => store.ui.activeApiTokens);
  const popup = useSelector((store) => store.ui.popupId);
  const appreciationPopup = useSelector((store) => store.ui.appreciationId);
  const token = useSelector((store) => store.auth.token);
  const userInfo = useSelector((store) => store.user.userInfo);
  const isTourActive = useSelector((store) => store.ui.isTourActive);
  const activeCourse = useSelector((store) => store.planning.activeCourse);
  const subjectPreferenceList = useSelector(
    (store) => store.planning.subjectPreferenceList
  );

  /*router */
  const history = useHistory();

  /*use effect hooks */
  useEffect(() => {
    initialFunction();

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty(`--vh`, `${vh}px`);

    if (inIframe()) {
      setTimeout(() => {
        window.parent.postMessage(
          `${token}`,
          "https://community-test.examarly.com"
        );
      }, 1000);
    }
  }, []);

  /**
   * This runs everytime route changes in the browser and cancels the api requests that are in pending state to stop the loader
   * except the global api calls.
   */
  useEffect(() => {
    return history.listen(() => {
      trackPage();
      dispatch(cancelApiRequests());
      dispatch(setPopup(null));
      window.scrollTo(0, 0);
    });
  }, [history, userInfo]);

  /**
   * this runs everytime token is changed in redux. If token is not null the we get the data for user
   * from database by making an api call
   */
  useEffect(() => {
    if (token) {
      authFuctions();
      dispatch(isRedirectedFromCashFree({ loader: true }));
      redirecttoCommunity()
    }
  }, [token]);

  useEffect(() => {
    identifyUser(userInfo?.mobileNo, userInfo);
  }, [userInfo]);

  useEffect(() => {
    if (isTourActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isTourActive]);

  useEffect(() => {
    if (token && activeCourse) {
      dispatch(getSubjectList(activeCourse, { loader: true, type: "global" }));
      dispatch(getUserGroups());
      dispatch(getStatData());
      dispatch(getCourseStats());
      dispatch(showEarlyBirdPopup());
    }
  }, [activeCourse, token]);

  useEffect(() => {
    if (userInfo?.isSurveyComplete) {
      dispatch(getSubjectPreferenceList());
    }
  }, [activeCourse, token, userInfo]);

  // useEffect(() => {
  //     if (subjectPreferenceList && subjectPreferenceList.length > 0) {
  //         dispatch(getDailyTasks());
  //     }
  // }, [subjectPreferenceList]);

  /*functions */
  const initialFunction = () => {
    document.body.style.opacity = 1;
    initializeMixPanel();
    dispatch(initializeSurveyData());
    dispatch(initializeAssessmentData());
    dispatch(initializeAlreadyTaken());
    dispatch(initializeDailyTask());
    dispatch(getSurveyQuestions());
    dispatch(getCourseList());
  };

  const authFuctions = () => {
    dispatch(getUserData({ loader: true, type: "global" }));
    dispatch(
      getMonthlyStreak(new Date().getFullYear(), new Date().getMonth() + 1)
    );
  };

  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  return (
    <Suspense fallback={<LoaderPopUp />}>
      <ErrorBoundary history={history}>
        {pendingRequests.length !== 0 && <LoaderPopUp />}
        <Popup isOpen={popup != null} id={popup} type={"center"} />
        {appreciationPopup !== null && (
          <AppreciationPopups id={appreciationPopup} />
        )}
        <ChatbotLauncher />
        <Switch>
          {Routes.map(({ type, path, component: Component, name }, index) => {
            if (type === "verified") {
              return (
                <ProtectedRoute
                  path={path}
                  name={name}
                  exact
                  Component={Component}
                  key={name}
                />
              );
            } else if (type === "unverified") {
              return (
                <UnverifiedRoute
                  path={path}
                  name={name}
                  exact
                  Component={Component}
                  key={name}
                />
              );
            } else if (type === "guest") {
              return (
                <GuestRoute
                  path={path}
                  name={name}
                  exact
                  Component={Component}
                  key={name}
                />
              );
            } else if (type === "group") {
              return (
                <GroupRoute
                  path={path}
                  name={name}
                  exact
                  Component={Component}
                  key={name}
                />
              );
            } else if (type === "nogroup") {
              return (
                <NoGroupRoute
                  path={path}
                  name={name}
                  exact
                  Component={Component}
                  key={name}
                />
              );
            } else {
              return (
                <Route
                  render={(props) => <Component {...props} name={name} />}
                  path={path}
                  exact
                  key={name}
                />
              );
            }
          })}
          <Redirect to="/" />
        </Switch>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
