import { trackEvent } from './base';

export const trackPlanSelection = (planDuration, planPrice) => {
    trackEvent('Store Event', {
        plan_selection: true,
        plan_duration: planDuration,
        plan_price: planPrice
    });
};

export const trackMoreButton = () => {
    trackEvent('Store Event', {
        more_button: true
    });
};

export const trackCurrentPlan = (planDuration, planPrice) => {
    trackEvent('Store Event', {
        current_plan: true,
        plan_duration: planDuration,
        plan_price: planPrice
    });
};

export const trackPayButton = () => {
    trackEvent('Store Event', {
        pay_button: true
    });
};

export const trackRedeemButton = () => {
    trackEvent('Store Event', {
        coin_redeem: true
    });
};

export const trackCouponApplied = (couponCode) => {
    trackEvent('Store Event', {
        coupon_apply: couponCode
    });
};

export const trackGateway = () => {
    trackEvent('Store Event', {
        gateway: true
    });
};

export const trackPaymentStatus = (isPaymentSuccessfull, error) => {
    trackEvent('Store Event', {
        payment_successfull: isPaymentSuccessfull,
        error: error
    });
};

export const trackPaymentWindowExited = () => {
    trackEvent('Store Event', {
        payment_window_exited: true
    });
};

export const trackCoinsSpent = (amount, coinsSpentOn) => {
    trackEvent('Coins Spent', {
        amount,
        spent_on: coinsSpentOn
    });
};

export const trackCoinsEarned = (amount, coinsEarnedFrom) => {
    trackEvent('Coins Earned', {
        amount,
        earned_from: coinsEarnedFrom
    });
}