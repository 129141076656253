import React, { useEffect, useRef, useState } from 'react';
import "./DynamicPositionPopup.scss";

import { useDispatch, useSelector } from 'react-redux';
import { setPopup } from 'redux/actions/uiActions/actions';
import { AddDailyTask, More, Notification, SelectSubjects, WeakTopics } from './Components';


const DynamicPositionPopup = ({children}) => {
    let notificationComponent = useRef();
    const popupData = useSelector((store) => store.ui.popupData);
    const dispatch = useDispatch();
    const [curPos, setCurPos] = useState();
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    useEffect(() => {
        const parentPopUpContainer = document.querySelector(".popup");
        const parentPopUpMainContainer = document.querySelector(".popup-container");
        parentPopUpContainer.className += " dynamicNotification"
        parentPopUpMainContainer.className += " dynamicNotification"

        return (() => {
            parentPopUpContainer.classList.remove("dynamicNotification")
            parentPopUpMainContainer.classList.remove("dynamicNotification")
        })
    }, [])

    const isValidPosition = (positionToCheck) => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        const position = getPositionBasedOnProps(positionToCheck);

        const isLessBottom = (position.y + notificationComponent.current.getBoundingClientRect().height) <= height;
        const isLessRight = (position.x + notificationComponent.current.getBoundingClientRect().width) <= width;
        const isLessTop = position.y >= 0;
        const isLessLeft = position.x >= 0;

        switch (positionToCheck) {
            case "topCenter":
                return (isLessTop && isLessLeft && isLessRight);
            case "topRight":
                return (isLessTop && isLessRight);
            case "rightCenter":
                return (isLessTop && isLessRight && isLessBottom)
            case "bottomRight":
                return (isLessBottom && isLessRight)
            case "bottomCenter":
                return (isLessBottom && isLessLeft && isLessRight)
            case "bottomLeft":
                return (isLessBottom && isLessLeft)
            case "leftCenter":
                return (isLessLeft && isLessBottom && isLessTop);
            case "topLeft":
                return (isLessLeft && isLessTop);
            default:
                return (true);
        }
    }

    const getPositionBasedOnProps = (positionToCheck) => {
        switch (positionToCheck) {
            case "topCenter":
                return ({
                    y: popupData.cordinates.y - (notificationComponent.current.getBoundingClientRect().height + 30),
                    x: popupData.cordinates.x - (notificationComponent.current.getBoundingClientRect().width / 2),
                })
            case "topRight":
                return ({
                    y: popupData.cordinates.y - (notificationComponent.current.getBoundingClientRect().height + 30),
                    x: popupData.cordinates.x - ((popupData.width / 2)),
                })
            case "rightCenter":
                return ({
                    y: popupData.cordinates.y - (notificationComponent.current.getBoundingClientRect().height/2),
                    x: popupData.cordinates.x + 30,
                })
            case "bottomRight":
                return ({
                    y: popupData.cordinates.y + 30,
                    x: popupData.cordinates.x - (popupData.width/2),
                })
            case "bottomCenter":
                return ({
                    y: popupData.cordinates.y + 30,
                    x: popupData.cordinates.x - (notificationComponent.current.getBoundingClientRect().width / 2 ),
                })
            case "bottomLeft":
                return ({
                    y: popupData.cordinates.y + 30,
                    x: popupData.cordinates.x - ((notificationComponent.current.getBoundingClientRect().width)) + (popupData.width/2),
                })
            case "leftCenter":
                return ({
                    y: popupData.cordinates.y - (notificationComponent.current.getBoundingClientRect().height / 2),
                    x: popupData.cordinates.x - ((notificationComponent.current.getBoundingClientRect().width) + 30),
                })
            case "topLeft":
                return ({
                    y: popupData.cordinates.y - (notificationComponent.current.getBoundingClientRect().height) + (popupData.height / 2),
                    x: popupData.cordinates.x - ((notificationComponent.current.getBoundingClientRect().width) + 30),
                })
            case "centeralised":
                return ({
                    y: window.innerHeight/2 - (notificationComponent.current.getBoundingClientRect().height/2),
                    x: window.innerWidth/2 - (notificationComponent.current.getBoundingClientRect().width/2)
                })
            default:
                return ({
                    y: popupData.cordinates.y - (notificationComponent.current.getBoundingClientRect().height + popupData.height + 15),
                    x: popupData.cordinates.x - ((notificationComponent.current.getBoundingClientRect().width / 2) + (popupData.width / 2)),
                })

        }
    }

    const findNewValidPosition = (previousInvalidPosition) => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const position = getPositionBasedOnProps(previousInvalidPosition);

        const isLessBottom = (position.y + notificationComponent.current.getBoundingClientRect().height) <= height;
        const isLessRight = (position.x + notificationComponent.current.getBoundingClientRect().width) <= width;
        const isLessTop = position.y >= 0;
        const isLessLeft = position.x >= 0;

        switch (true) {
            case isLessTop && isLessLeft && isLessRight:
                return "topCenter";
            case isLessTop && isLessRight && isLessBottom:
                return "rightCenter";
            case isLessBottom && isLessLeft && isLessRight:
                return "bottomCenter";
            case isLessLeft && isLessBottom && isLessTop:
                return "leftCenter";
            case isLessTop && isLessRight:
                return "topRight";
            case isLessBottom && isLessRight:
                return "bottomRight";
            case isLessBottom && isLessLeft:
                return "bottomLeft";
            case isLessLeft && isLessTop:
                return "topLeft";
            default:
                return "topLeft";
        }


    }

    const initiateNotificationPositionValidator = () => {
        if(notificationComponent && notificationComponent.current) {
            const positionObject = getPositionBasedOnProps(popupData.position);
            setPositionX(positionObject.x - notificationComponent.current.getBoundingClientRect().left);
            setPositionY(positionObject.y);
            // if (isValidPosition(popupData.position)) {
            //     const positionObject = getPositionBasedOnProps(popupData.position);
            //     setPositionX(positionObject.x);
            //     setPositionY(positionObject.y);
            // } else {
            //     const newValidPosition = findNewValidPosition(popupData.position);
            //     console.log(newValidPosition);
            //     if (isValidPosition(newValidPosition)) {
            //         const positionObject = getPositionBasedOnProps(newValidPosition);
            //         setPositionX(positionObject.x);
            //         setPositionY(positionObject.y);
            //     } else {
            //         const isValidPositionArr = ["topRight", "bottomRight", "bottomLeft", "topLeft", "bottomCenter", "leftCenter", "rightCenter", "topCenter"];
                    
            //         for(let cur in isValidPositionArr) {
            //             if (isValidPosition(isValidPositionArr[cur])) {
            //                 const positionObject = getPositionBasedOnProps(cur);
            //                 setPositionX(positionObject.x);
            //                 setPositionY(positionObject.y);
            //                 break;
            //             }
            //         }
            //     }
            // }
        }
    }

    useEffect(() => {
        initiateNotificationPositionValidator();
    }, [notificationComponent])

    useEffect(() => {
        setCurPos(popupData.position);
    }, [])

    const handleOnClickMainContainer = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setPopup(null))
    }

    const handleOnClickPopUp = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <div className='notification-main-containerr' onClick={handleOnClickMainContainer}>
            <div className='notifications-inner-position-container' ref={notificationComponent}
            style={{left: `${positionX}px`, top: `${positionY}px`} } onClick={handleOnClickPopUp}
            >
                {
                    {
                        "Notification": <Notification />,
                        "More": <More />,
                        "WeakTopics": <WeakTopics />,
                        "SelectSubject": <SelectSubjects />,
                        "AddDailyTask": <AddDailyTask />
                    }[popupData.popUpToShow]
                }
            </div>
        </div>
    );
};

export default DynamicPositionPopup;