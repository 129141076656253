import { trackEvent, trackNewEvent } from './base';

let mapEvents = {
    quiz: 'Current Affairs Test',
    dailytest: 'Current Affairs Test',
    topictest: 'Syllabus Test',
    assessment: 'Assessment Test',
    retest: 'Revision Test',
};

export const trackQuizStarted = (eventName, numberOfQuestions, quizName = 'Home Quiz') => {
    let date = new Date();
    trackEvent(eventName, {
        name_of_quiz: quizName,
        number_of_questions: numberOfQuestions,
        time_duration: numberOfQuestions + ' mins',
        start_time: date.getTime(),
    });
};

export const trackQuizEnded = (eventName, score, correctAnswers, incorrectAnswers) => {
    trackEvent(eventName, {
        score: score,
        no_of_correct_answers: correctAnswers,
        no_of_incorrect_answers: incorrectAnswers,
    });
};

export const trackQuizExited = (eventName) => {
    trackEvent(eventName, {
        quit: true,
    });
};

export const trackQuizQuit = (eventName) => {
    trackEvent(eventName, {
        quiz_quit: true,
    });
};

export const trackReportQuestion = (id) => {
    trackNewEvent('Report', { Question_Id: id });
};

export const trackQuizEvents = (pageName, data) => {
    trackNewEvent(mapEvents[pageName], data);
};

export const trackViewAll = (pageName) => {
    trackQuizEvents(pageName, { View_All: true });
};

export const trackRevisionCount = (pageName, testCount) => {
    trackQuizEvents(pageName, { Revision_Count: testCount });
};

export const trackViewAllSolution = (pageName) => {
    trackQuizEvents(pageName, { GetAnswers_ViewAll: true });
};

export const trackViewSolution = (pageName) => {
    trackQuizEvents(pageName, { GetAnswers_ViewSolution: true });
};

export const trackResult = (pageName, data) => {
    trackQuizEvents(pageName, data);
};

export const trackRecommendedSubject = (pageName, subject) => {
    trackQuizEvents(pageName, { Recommended_Subject: subject });
};

export const trackGetAnswers = (pageName) => {
    trackQuizEvents(pageName, { Get_Answers_Button_Click: true });
};

export const distinguishQuizStartEvents = (pageName, data) => {
    trackQuizEvents(pageName, {
        [`${mapEvents[pageName].replaceAll(' ', '_')}_Start`]: true,
        ...data,
    });

    switch (pageName) {
        case 'quiz':
            trackQuizStarted('Home Page Quiz Start', {});
            break;
        case 'dailytest':
            trackQuizStarted('Home Subject Test Start', {});
            break;
        case 'topictest':
            trackQuizStarted('Planning Daily Test Start', {});
            break;
        case 'assessment':
            trackQuizStarted('Onboarding Test Start', {});
            break;
        case 'retest':
            trackQuizStarted('Revision Test Start', {});
            break;
    }
};

export const distinguishQuizEndEvents = (pageName, data) => {
    trackQuizEvents(pageName, {
        [`${mapEvents[pageName].replaceAll(' ', '_')}_Finish`]: true,
        ...data,
    });

    switch (pageName) {
        case 'quiz':
            trackQuizEnded('Home Page Quiz Finish', {});
            break;
        case 'dailytest':
            trackQuizEnded('Home Subject Test Finish', {});
            break;
        case 'topictest':
            trackQuizEnded('Planning Daily Test Finish', {});
            break;
        case 'assessment':
            trackQuizEnded('Onboarding Test Finish', {});
            break;
        case 'retest':
            trackQuizEnded('Revision Test Finish', {});
            break;
    }
};

export const distinguishQuizQuitEvents = (pageName) => {
    trackQuizEvents(pageName, {
        [`${mapEvents[pageName].replaceAll(' ', '_')}_Quit`]: true,
    });

    switch (pageName) {
        case 'quiz':
            trackQuizExited('Home Page Quiz Quit');
            break;
        case 'dailytest':
            trackQuizExited('Home Subject Test Quit');
            break;
        case 'topictest':
            trackQuizExited('Planning Daily Test Quit');
            break;
        case 'assessment':
            trackQuizExited('Onboarding Test Quit');
            break;
        case 'retest':
            trackQuizExited('Revision Test Quit');
            break;
    }
};
