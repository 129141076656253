import { SETLOADING, UPDATEAPITOKENS, SETPOPUPID, SETAPPRECIATIONPOPUPID, SETGUESTPROMPT, SETTOURINFO, INCREAMENTTOURSTEP, ENDTOUR, RESETCOURSE, SETPATH } from "./actionType";

export const setLoader = (state) => async (dispatch, getState) => {
    let loaderState = getState().ui.isLoading;

    if (loaderState !== state) {
        dispatch({
            type: SETLOADING,
        });
    }
};

export const addApiToken =
    (id, type = "local", source) =>
    (dispatch, getState) => {
        let tokens = getState().ui.activeApiTokens;
        tokens = [...tokens, { id, type, source }];

        dispatch({
            type: UPDATEAPITOKENS,
            payload: tokens,
        });
    };

export const removeApiToken =
    (id, type = "local") =>
    (dispatch, getState) => {
        let tokens = getState().ui.activeApiTokens;
        tokens = tokens.filter((token) => token.id !== id);

        dispatch({
            type: UPDATEAPITOKENS,
            payload: tokens,
        });
    };

export const cancelApiRequests = () => (dispatch, getState) => {
    let tokens = getState().ui.activeApiTokens;
    let globalTokens = [];

    tokens.forEach((token) => {
        if (token.type === "local") {
            token.source.cancel();
        } else {
            globalTokens.push(token);
        }
    });

    tokens?.length > 0 &&
        dispatch({
            type: UPDATEAPITOKENS,
            payload: globalTokens,
        });
};

export const resetCourse = () => (dispatch) => {
    dispatch({ type: RESETCOURSE });
};

export const setPopup =
    (id, data = {}) =>
    (dispatch) => {
        dispatch({
            type: SETPOPUPID,
            payload: { id, ...data },
        });
    };

export const setAppreciationPopup = (id) => (dispatch) => {
    dispatch({
        type: SETAPPRECIATIONPOPUPID,
        payload: id,
    });
};

export const setGuestPrompt = (bool) => (dispatch, getState) => {
    const userType = getState().auth.userType;

    if (userType == "guest") {
        dispatch({
            type: SETGUESTPROMPT,
            payload: bool,
        });
    }
};

export const setTourInfo = (path) => (dispatch) => {
    dispatch({
        type: SETTOURINFO,
        payload: path,
    });
};

export const increamentStep = () => (dispatch) => {
    dispatch({
        type: INCREAMENTTOURSTEP,
    });
};

export const endTour = () => (dispatch) => {
    dispatch({
        type: ENDTOUR,
    });
};

export const setPathForTestRedirect = (path) => (dispatch) => {
    dispatch({
        type: SETPATH,
        payload: path
    })
}
