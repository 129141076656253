import { cloneDeep } from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import Lottie from 'react-lottie';
import { useSelector, useDispatch } from 'react-redux';
import { clearTestRewards } from 'redux/actions/assessmentActions/actions';
import { setPopup } from 'redux/actions/uiActions/actions';
import ShareCards from '../PopupContainer/ShareCards/ShareCards';
import { getDaysInCurrentWeek } from 'utils/Functions';
import './StreakPopup.scss';
const StreakPopup = ({ onClosePopup }) => {
    const streakDetails = useSelector((store) => store.stat.streak);
    const days = getDaysInCurrentWeek();
    const streakList = useSelector((store) => store.stat.streakList);
    const values = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    const popupData = useSelector((store) => store.ui.popupData);
    const { Continue } = popupData;
    const rewards = useSelector((store) => store.assessment.rewards);
    const dispatch = useDispatch();
    const checkIfActive = useCallback((day) => {
        if ((streakList ?? []).includes(day)) {
            return 'active';
        } else {
            return '';
        }
    });
    const callLevel = () => {
        dispatch(
            setPopup(12, {
                animationPath: require('assets/animations/level-up.json'),
                share: true,
                progress: false,
                type: 'level',
                xp: rewards?.isLevelUp?.xpProgress,
                Continue,
                source: 'level up completion',
            })
        );
    };

    const callTopicComplete = () => {
        dispatch(
            setPopup(12, {
                heading: "You've completed a Topic!",
                subHeading: 'Piece of cake! On to the next one…!',
                share: true,
                animationPath: require('assets/animations/topic-complete.json'),
                progress: false,
                type: 'topic',
                xp: rewards?.isTopicComplete?.xpEarned,
                Continue,
                source: 'topic completion',
            })
        );
    };
    const callDailyTask = () => {
        dispatch(
            setPopup(12, {
                heading: 'Your daily task is complete!',
                subHeading: "All in a day's work for you 😉. Awesome job!",
                animationPath: require('assets/animations/daily-task.json'),
                share: false,
                progress: false,
                type: 'task',
                xp: rewards?.dailyTask.xpEarned,
                Continue,
                source: 'daily task completion',
            })
        );
    };
    const onContinue = () => {
        if (rewards?.dailyTask) {
            callDailyTask();
        } else if (rewards?.isTopicComplete) {
            callTopicComplete();
        } else if (rewards?.isLevelUp) {
            callLevel();
        } else {
            onClosePopup();
            dispatch(clearTestRewards());
            Continue();
        }
    };
    const defaultOptions = {
        loop: false,
        autoplay: true,
    };
    return (
        <div className="streak__container">
            <Lottie
                options={{
                    ...defaultOptions,
                    animationData: cloneDeep(require('assets/animations/streak.json')),
                }}
                height={'100%'}
                width={'100%'}
            />
            <div className="days-container">
                {days.map((day, index) => {
                    return (
                        <div className="wrapper" key={day}>
                            <div className={`day-circle ${checkIfActive(day)}`}>
                                <img src={require('assets/svgIcons/tick.svg')} alt="" />
                            </div>
                            <div className="value">{values[index]}</div>
                        </div>
                    );
                })}
            </div>
            <div className="heading">
                I'm on a {streakDetails?.currentStreak}
                {streakDetails?.currentStreak === 1 ? '-day ' : '-days'} streak!
            </div>
            <div className="sub-heading">Complete a daily task or test every day to build your streak.</div>
            <div className="btn-cta">
                <div className="mobile-share-cta">
                    <ShareCards type="streak" source={popupData?.source} />
                </div>
                <button className="continue" onClick={onContinue}>
                    Continue
                </button>
            </div>
            <div className="cross" onClick={onContinue}>
                <img src={require('assets/svgIcons/cross.svg')} alt="" />
            </div>
        </div>
    );
};

export default StreakPopup;
