import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import Lottie from "react-lottie";
import We_got_you from "assets/animations/We_got_you.json";
import { useDispatch } from "react-redux";
import { setPopup } from "redux/actions/uiActions/actions";
import { Options } from "./Component";
import "./EarlyBird.scss";
import { paymentGateway } from "services/payments";
import { trackSubscribeButton } from "analytics/earlybird";

export const earlyBirdOptions = [
  {
    id: 1,
    image: require("assets/svgIcons/night.svg"),
    title: "Dark Mode",
    description: "Late night studies are now more convenient.",
  },
  {
    id: 2,
    image: require("assets/svgIcons/exam.svg"),
    title: "Revise Again",
    description: "Benefits of unlimited revision tests to keep you prepared.",
  },
  {
    id: 3,
    image: require("assets/svgIcons/exam-timer.svg"),
    title: "Longer Syllabus Test",
    description: "More questions so you cover your entire syllabus easily.",
  },
  {
    id: 4,
    image: require("assets/svgIcons/appointmentIcon.svg"),
    title: "Add Tasks to Scheduler",
    description: "Personalise your scheduler by adding additional tasks.",
  },
  {
    id: 5,
    image: require("assets/svgIcons/bonus.svg"),
    title: "1.5x Interaction Bonus",
    description: "Interaction bonus for every module except community.",
  },
];

export const EarlyBird = () => {
  const dispatch = useDispatch();

  const defaultOptions = {
    loop: true,
    autoplay: true,
  };

  useEffect(()=>{
    trackSubscribeButton()
  },[])

  const handleOnClick = async () => {
    const payment = await dispatch(paymentGateway({ loader: true }));
  };

  return (
    <div className="early-bird-container">
      <div className="early-bird-logo">
        <Lottie
          className="early-bird-logo-lottie"
          options={{
            ...defaultOptions,
            animationData: cloneDeep(We_got_you),
          }}
        />
      </div>
      <div className="early-bird-container-description">
        <div className="early-bird-container-description-title animate">
          The Full Examarly Experience
        </div>
        <div className="early-bird-container-description-lines animate">
          <div className="early-bird-container-description-first-line">
            Fast-pace your preparation.
          </div>

          <div className="early-bird-container-description-second-line animate">
            Premium suite launching 25.02.23
          </div>
        </div>
      </div>

      <div className="early-bird-options-container animate">
        {earlyBirdOptions.map((earlyBirdOptions) => {
          return (
            <Options
              key={earlyBirdOptions.id}
              earlyBirdOptions={earlyBirdOptions}
            />
          );
        })}
      </div>
      <div className="early-bird-footer">
        <div className="early-bird-footer-title animate">SUBSCRIBE EARLY</div>
        <div className="early-bird-footer-description animate">
          Get 6 months of Examarly Premium worth &nbsp; <s>₹6000</s> &nbsp; only
          ₹2000!
        </div>
        <div className="ealry-bird-footer-description-for-mobile-view">
          Premium Subscription for 6 months at <br /> just <s>₹6000</s> ₹2000!
        </div>
        <div className="early-bird-footer-button animate">
          <button
            className="early-bird-dark-btn"
            onClick={() => {
              handleOnClick();
            }}
          >
            SUBSCRIBE
          </button>
        </div>
        <div className="early-bird-footer-button">
          <button
            className="early-bird-light-btn animate"
            onClick={() => {
              dispatch(setPopup(null));
            }}
          >
            Not now
          </button>
        </div>
      </div>
    </div>
  );
};
