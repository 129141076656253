import {
  SETUSERINFO,
  SETWALLETDETAILS,
  SETANIMATE
} from '../actions/userActions/actionType';
import {
  LOGOUT
} from '../actions/authActions/actionType';

const initalState = {
  userInfo: null,
  walletDetails: null,
  animate: null
};

const userReducer = (state = initalState, action) => {
  switch (action.type) {
    case SETUSERINFO:
      return {
        ...state,
        userInfo: action.payload,
      };

    case SETWALLETDETAILS:
      return {
        ...state,
        walletDetails: action.payload.wallet,
        animate: action.payload.animate
      };

    case SETANIMATE:
      return {
        ...state,
        animate: null
      }

    case LOGOUT:
      return {
        ...state,
        userInfo: null,
        walletDetails: null
      };

    default:
      return state;
  }
};

export default userReducer;
