import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

/**
 * This checks if token is present or not
 * If present then it redirects the user from unveriffied route to a verified route(/home)
 * @param {*} param0 
 * @returns 
 */
const UnverifiedRoute = ({ path, Component, name, ...rest }) => {
  const token = useSelector(store => store.auth.token);

  return (
    !token ? (
      <Route render={(props) => <Component {...props} name={name} />} path={path} exact {...rest} />
    ) : (
      <Redirect to='/home' />
    )
  )
}

export default UnverifiedRoute;
