import React, { useEffect, useState, useRef } from 'react';
import Lottie from 'react-lottie';
import useOnClickOutside from 'customhooks/useOutsideClick';
import { PopupData } from './data';
import { useSelector, useDispatch } from 'react-redux';
import useRedirect from 'customhooks/useRedirect';
import { resetWeakSubjects } from 'services/planningAndTracking';
import { createTest, getTestQuestion } from 'services/guest';
import { addRating, grantCoins } from 'services/user';
import { trackCoinsEarned } from 'analytics/store';
import { setAppreciationPopup } from 'redux/actions/uiActions/actions';
import confetti from 'assets/animations/confetti.json';
import { cloneDeep } from 'lodash';
import './AppreciationPopups.scss';

const AppreciationPopups = ({ id }) => {
    const [show, setshow] = useState(false);
    const [rating, setRating] = useState(0);

    let timeOut1 = null;
    let timeOut2 = null;

    const container = useRef(null);

    const dispatch = useDispatch();
    const result = useSelector(store => store.assessment.result);

    const redirect = useRedirect();
    useOnClickOutside(container, () => { dispatch(setAppreciationPopup(null)); });

    const defaultOptions = {
        loop: true,
        autoplay: true
    };

    useEffect(() => {
        timeOut1 = setTimeout(() => {
            setshow(true);
        }, 500);
        initiateClosePopup();

        return () => clearTimeouts();
    }, []);

    const initiateClosePopup = () => {
        if (PopupData[id]?.open) return;
        timeOut2 = setTimeout(() => {
            setshow(false);
            setTimeout(() => {
                dispatch(setAppreciationPopup(null));
            }, 500);
        }, 4500);
    };

    const closePopup = () => {
        setTimeout(() => dispatch(setAppreciationPopup(null)), 450);
    }

    const onStarClick = (rating) => {
        dispatch(addRating(rating));
        trackCoinsEarned(50, 'App Rating')
        setRating(rating);
        setshow(false);
        closePopup();
        if (rating > 3) {
            //redirect to playstore
        }
    };

    const onTakeAssessment = async () => {
        closePopup();
        await dispatch(resetWeakSubjects({ loader: true }));
        let testData = await dispatch(createTest({ loader: true }));
        if (testData) {
            let response = await dispatch(getTestQuestion(testData, { loader: true }));
            response && redirect('instructions/guest/assessment')
        }
    }

    const clearTimeouts = () => {
        clearTimeout(timeOut1);
        clearTimeout(timeOut2);
    }

    return (
        <div className={`appreciation-popup-container ${show ? 'show' : ''}`}>
            <div className="confettie-lottie">
                {PopupData[id]?.confettiAnimation && (
                    <Lottie
                        options={{ ...defaultOptions, animationData: cloneDeep(confetti) }}
                        height={'100%'}
                        width={'100%'}
                    />
                )}
            </div>
            <div className={`popup-content-container ${PopupData[id]?.open ? 'open' : ''}`} ref={container}>
                <div className="lottie-animation">
                    <Lottie
                        options={{ ...defaultOptions, animationData: cloneDeep(PopupData[id]?.animationPath) }}
                        height={'100%'}
                        width={'100%'}
                    />
                </div>
                <div className="content">
                    <div className="heading-wrapper">
                        <p className="heading">{PopupData[id]?.title}</p>
                    </div>
                    <div className="description-wrapper">
                        <p className="description">
                            {PopupData[id]?.description}{PopupData[id]?.score
                                ? parseInt(result?.percentage ?? 0) +
                                '%.\nContinue with our platform to improve your scores.'
                                : ''}
                        </p>
                    </div>

                    {PopupData[id]?.rating && (
                        <div className="star-container">
                            <div className="star-list">
                                {Array(5).fill(null).map((_, index) => (
                                    <div className={`${index < rating ? 'active' : ''}`}>
                                        <img className="inactive-star" src={require('assets/svgIcons/star.svg')} onClick={() => onStarClick(index + 1)} />
                                        <img className="active-star" src={require('assets/svgIcons/star-fill.svg')} onClick={() => onStarClick(index + 1)} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {PopupData[id]?.assessment && (
                        <div className="assessment-button-container">
                            <button className="btn-primary" onClick={onTakeAssessment}>
                                Take assessment
                            </button>
                            <p onClick={closePopup}>Take later</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AppreciationPopups;