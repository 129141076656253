import { trackEvent, trackNewEvent } from './base';

export const trackSubjectClick = (subjectName) => {
    trackEvent('Home Subject', {
        subject_name: subjectName
    });
};

export const trackIfTestStarted = () => {
    trackEvent('Home Subject', {
        test_started: true
    });
};

export const trackTestFinish = (score, isCompleted, attemptedQuestion) => {
    trackEvent('Home Subject', {
        score: score,
        completed: isCompleted,
        user_attempted_questions: attemptedQuestion
    });
};

export const trackCurrentAffairsLike = (status, type) => {
    // trackEvent('Home Page Current Affairs', {
    //     like: status,
    //     article_type: type
    // })
};

export const trackCurrentAffairsWishlist = (status, type) => {
    // trackEvent('Home Page Current Affairs', {
    //     book_mark: status,
    //     article_type: type
    // })
};

export const trackIfQuizStarted = (restarted) => {
    trackEvent('Home Quiz', {
        test_started: true,
        restarted
    });
};

export const trackIfStreaksClicked = () => {
    trackEvent('Home Page Main Event', {
        streak_view: true,
    });
};

export const trackTaskClicked = (taskType) => {
    // trackEvent('Home Page Main Event', {
    //     task_type: taskType,
    //     task_viewed: true
    // });
};

export const trackTaskVisited = (taskType) => {
    // trackEvent('Home Page Main Event Visited', {
    //     task_type: taskType,
    //     task_visited: true
    // });
};

export const trackIfTaskCompleted = (taskType) => {
    // trackEvent('Home Page Main Event', {
    //     task_type: taskType,
    //     task_completed: true
    // });
};

export const trackIfSubscriptionButtonClicked = () => {
    // trackEvent('Home Page Main Event', {
    //     subscription_button_clicked: true
    // });
};

export const trackBannerClicked = (bannerName, redirectUrl) => {
    // trackEvent('Home Page Main Event', {
    //     banner_name: bannerName,
    //     redirect_url: redirectUrl
    // });
};

export const trackHomeQuizStarted = (quizDate, questions) => {
    let date = new Date(quizDate);
    let currentDate = new Date();
    date.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    let isCurrentQuiz = date.getTime() === currentDate.getTime();

    trackEvent('Current Affairs Quiz Start', {
        date_of_quiz: date.toLocaleString(),
        no_of_questions: questions,
        start_time: new Date().toLocaleString(),
        current_Date: isCurrentQuiz,
        back_date: !isCurrentQuiz,
        coins_spent: isCurrentQuiz ? 0 : 20
    });
};

export const trackHomeQuizFinished = () => {

    trackEvent('Current Affairs Quiz Finished', {
        end_time: new Date().toLocaleString(),
        coins_earned: 10
    });
}

//New Events 

export const trackHomeQuizDropdown = (state = 'opened') => {
    // trackNewEvent('Home Page Event', {
    //     Current_Affairs_Dropdown: state
    // });
};

export const trackHomeReadCA = () => {
    // trackNewEvent('Home Page Event', {
    //     Read_Current_Affairs: true
    // });
}

export const trackHomeQuizLedger = () => {
    trackNewEvent('Home Page Event', {
        Quiz_Ledger: true
    });
}

export const trackHomePlanningDropdown = (state = 'opened') => {
    // trackNewEvent('Home Page Event', {
    //     Syllabus_Dropdown: state
    // });
}

export const trackHomePlanningButton = () => {
    // trackNewEvent('Home Page Event', {
    //     Complete_One_Unit: true
    // });
}

export const trackHomeNav = (tab) => {
    trackNewEvent('Home Page Event', {
        [tab]: true
    });
}

export const trackSidebarButtonClick = (tab) => {
    trackNewEvent('Home Page Event', {
        SideBar_Button_Click: tab
    });
}

export const trackTaskCompletion = (task)=>{
    // trackNewEvent('Home Page Event',{
    //     [`Daily_Task_${task}`]: true
    // });
}

export const trackDetailedStatButton = () => {
    // trackNewEvent('Home Page Event', {
    //     Detailed_Stats: true
    // });
};