import React from 'react';
import "./AddDailyTask.scss";

const AddDailyTask = () => {
    return (
        <div className='notification-add-daily-task-component-container'>
            <div className='notification-add-daily-task-component-inner-container'>
                <div className='notification-add-daily-task-component-text'>
                    Add a new task?
                </div>
                <div className='notification-add-daily-task-component-buttons-container'>
                    <div className='notification-add-daily-task-component-buttons NO btn-stp-cpy'>NO</div>
                    <div className='notification-add-daily-task-component-buttons Yes btn-stp-cpy'>YES</div>
                </div>
            </div>
        </div>
    );
};

export default AddDailyTask;