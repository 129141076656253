import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({ percentage, stats = false }) => {
  return (
    <div
      className="progressbar-wrapper"
      style={{
        height: stats ? "16px" : "5px",
        borderRadius: stats ? "2px" : "100px",
      }}
    >
      <div
        className="progress-bar"
        style={{
          width: percentage + "%",
          height: stats ? "16px" : "5px",
          borderRadius: stats ? "2px" : "100px",
        }}
      />
    </div>
  );
};

export default ProgressBar;
