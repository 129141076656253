import React from 'react';
import useRedirect from 'customhooks/useRedirect';
import { trackHomeReadCA } from 'analytics/home';
import './Task.scss';

const Task = ({ id, completed, taskName, description, isActive, onTaskClick, redirect, buttonText, onButtonClick, bottomBanner }) => {

    const redirectTo = useRedirect();

    const onRedirect = () => {
        trackHomeReadCA();
        redirectTo(redirect)
    }

    const onSubtopicClick = () => {
        redirectTo('home/quizlist');
    }

    return (
        <div className={`task-container ${completed ? 'complete' : ''} ${isActive ? 'show' : ''}`} onClick={onTaskClick}>
            <div className="task-front">
                <div className="left-task-section">
                    <div className="icon-wrapper">
                        <img src={require('assets/svgIcons/plan-checked.svg')} />
                    </div>
                    <div className="task-info-wrapper">
                        <p className="description">{taskName}</p>
                    </div>
                </div>
                <div className="right-task-section">
                    <div className="icon-wrapper">
                        {isActive ? (
                            <img src={require('assets/svgIcons/subtopic-arrow2.svg')} />
                        ) : (
                            <img src={require('assets/svgIcons/subtopic-arrow1.svg')} />
                        )}
                    </div>
                </div>
            </div>
            <div className="task-detail">
                <p className="detail">{description}</p>
                {redirect && (
                    <div className="task-action-container" onClick={onRedirect}>
                        Read Current Affairs
                        <img src={require('assets/svgIcons/task-arrow.svg')} />
                    </div>
                )}
                <button className="btn-primary" onClick={onButtonClick}>
                    {id === 2 || !completed ? buttonText : 'Attempt Another Quiz'}
                </button>
            </div>
        </div>
    );
};

export default Task;