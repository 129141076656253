import { lazy } from 'react';
import { gsap } from 'gsap';

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const getSeconds = (date1, date2) => {
    return Math.abs((date1.getTime() - date2.getTime()) / 1000).toFixed(2);
};

/**
 *
 * @param {*} tag Classname, Id or Tag that is common with all the elements
 */
export const sameHeightDiv = (tag) => {
    const allParagraph = document.querySelectorAll(tag);
    let maxHeight = 0;
    allParagraph.forEach((element) => {
        if (element.clientHeight > maxHeight) {
            maxHeight = element.clientHeight;
        }
    });

    allParagraph.forEach((element) => {
        element.style.height = maxHeight + 'px';
    });
};

export const heightOnDestroy = (tag) => {
    const allParagraph = document.querySelectorAll(tag);

    allParagraph.forEach((element) => {
        element.style.height = 'auto';
    });
};
/**
 *
 * @param {*} factory Component import the needs to be lazy loaded with prefetch property
 * @returns
 */
export const lazyWithPreload = (factory) => {
    const Component = lazy(factory);
    Component.preload = factory;
    return Component;
};

export const getArrayValueString = (array) => {
    let string = '';
    Object.keys(array).forEach((key) => (string += array[key]));
    return string;
};

export const getAlphabet = (number) => {
    switch (number) {
        case 0:
            return 'A';
        case 1:
            return 'B';
        case 2:
            return 'C';
        case 3:
            return 'D';
        default:
            return 'E';
    }
};

export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'Ios';
    }

    return 'unknown';
};

// Copy to Clopboard
export const copyToClipboard = (element) => {
    var range = document.createRange();
    range.selectNode(document.querySelector(element));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand('copy');
    window.getSelection().removeAllRanges(); // to deselect
};

export const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
};

/**
 * to check if phone number valid has a valid length or not
 * @param {*} e
 * @param {*} setFieldValue name of the field
 * @returns
 */
export const validateNumber = (e, field, setFieldValue) => {
    if (e.currentTarget.value.length > 10) {
        return;
    }
    return setFieldValue(field, e.currentTarget.value);
};

export const transformDate = (data, seperator = '-') => {
    let date = new Date(data);
    let currentDate = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (currentDate < 10) currentDate = '0' + currentDate;
    if (month < 10) month = '0' + month;
    let transformedDate = `${year}${seperator}${month}${seperator}${currentDate}`;
    return transformedDate;
};

export const transformDateMonth = (data, short = false) => {
    let months = short ? shortMonthNames : monthNames;
    let date = new Date(data);
    let transformedDate = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
    return transformedDate;
};

export const createTimeline = (trigger, start, end, pin, duration) => {
    const timeLine = gsap.timeline({
        scrollTrigger: {
            trigger: trigger.current,
            start,
            end,
            scrub: 1,
            pin,
            duration,
            immediateRender: false,
        },
    });

    return timeLine;
};

export function getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    let startingDate = date.getDay();
    days = [...Array(startingDate).fill(null)];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
}

export function getDaysInCurrentWeek() {
    var date = new Date();
    var days = [];
    let startingDate = new Date();
    startingDate = new Date(date.setDate(date.getDate() - (date.getDay() ?? 7)));
    for (let i = 0; i < 7; i++) {
        startingDate = new Date(startingDate.setDate(startingDate.getDate() + 1));
        days.push(transformDate(startingDate));
    }
    return days;
}

export const getCurrentDate = () => {
    const date = new Date();
    return date;
};

export const getDaysLeft = (date1, date2) => {
    let daysLeft = (date1 - date2) / (1000 * 60 * 60 * 24);
    return daysLeft;
};

export const getShortName = (name) => {
    return name?.[0]?.toUpperCase();
};

export const filterData = (objName, dataArray) => {
    let filteredData = [];
    dataArray.forEach((subId) => {
        filteredData.push(subId?.[objName]);
    });
    return filteredData;
};

export const checkDateEquality = (date1, date2) => {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    d1 = d1.setHours(0, 0, 0, 0);
    d2 = d2.setHours(0, 0, 0, 0);

    return d1.toISOString() === d2.toISOString();
};

export const getVideoId = (url) => {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url?.match(regExp);
    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return '';
    }
};

export const checkIfIos = () => {
    return navigator.userAgent.match(/(iPhone|iPad)/i);
};

export const getCircleColor = (color) => {
    const colorVal = {
        '164,94,253': '#D2C7FE',
        '79,127,17': '#D1F9D3',
        '253,117,94': '#FECFC7',
    };
    return colorVal[color] ?? '#FECFC7';
};

export const levelColor = ['#FF8B4C', '#FC5D73', '#79A237', '#8272E6'];

export const findDepriciation = (testCount) => {
    let D = 0;
    if (testCount === 1) D = 20;
    else if (testCount === 2) D = 10;
    else if (testCount >= 3) D = 4;

    return D;
};

export const getGreetAccordingToTime = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 3 && currentHour < 12) {
        return 'Morning';
    } else if (currentHour >= 12 && currentHour < 17) {
        return 'Afternoon';
    } else {
        return 'Evening';
    }
};
export const getEquationTxt = (text) => {
    text = text.replaceAll('</eq>', '<eq>');
    let textList = text.split('<eq>');
    return textList;
};

export const throttleFunction = function (func, delay) {
    let timerId;
    if (timerId) {
        return;
    }
    // Schedule a setTimeout after delay seconds
    timerId = setTimeout(function () {
        func();
        // Once setTimeout function execution is finished, timerId = undefined so that in <br>
        // the next scroll event function execution can be scheduled by the setTimeout
        timerId = undefined;
    }, delay);
};

export const Base64 = {
    // private property
    _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

    // public method for encoding
    encode: function (input) {
        var output = '';
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;

        input = Base64._utf8_encode(input);

        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
        }
        return output;
    },

    // public method for decoding
    decode: function (input) {
        var output = '';
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

        while (i < input.length) {
            enc1 = this._keyStr.indexOf(input.charAt(i++));
            enc2 = this._keyStr.indexOf(input.charAt(i++));
            enc3 = this._keyStr.indexOf(input.charAt(i++));
            enc4 = this._keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }
        }

        output = Base64._utf8_decode(output);

        return output;
    },

    // private method for UTF-8 encoding
    _utf8_encode: function (string) {
        string = string.toString().replace(/\r\n/g, '\n');
        var utftext = '';

        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            } else if (c > 127 && c < 2048) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
        }
        return utftext;
    },

    // private method for UTF-8 decoding
    _utf8_decode: function (utftext) {
        var string = '';
        var i = 0;
        var c = 0,
            c1 = 0,
            c2 = 0;

        while (i < utftext.length) {
            c = utftext.charCodeAt(i);

            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            } else if (c > 191 && c < 224) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            } else {
                c2 = utftext.charCodeAt(i + 1);
                let c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }
        return string;
    },
};
