import React, { useEffect } from "react";
import { hydrate, render } from "react-dom";

//for react spinner and loader
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

//Slick-Carousel CSS and theme-CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./index.css";
import "./styles/styles.scss";
import App from "./App/App";
import * as serviceworker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/createStore/store";
import { setRequestResponseInterceptor } from 'utils/Request'
import { getAuthToken } from 'redux/actions/authActions/actions';

store.dispatch(setRequestResponseInterceptor());// this line intializes interceptors before dom is rendered
store.dispatch(getAuthToken());// this lines gets the auth token if it exists from localstorage and dispatches it to redux

serviceworker.register();

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {

  hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </Provider>, rootElement);
} else {

  render(
    <Provider store={store}>
      <BrowserRouter>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </Provider>, rootElement);
}