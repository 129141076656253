import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackBar from "Components/SnackBar/SnackBar";
import useWindowHeight from "customhooks/useWindowHeight";
import { setPopup } from "redux/actions/uiActions/actions";
import TrialConfirmation from "./Popups/Confirmation/TrialConfirmation";
import LogoutConfirmation from "./Popups/Confirmation/LogoutConfirmation";
import PlanSuccess from "./Popups/PlanSuccess/PlanSuccess";
import Error from "./Popups/Error/Error";
import ModuleIntro from "./Popups/ModuleIntro/ModuleIntro";
import GuestPrompt from "./Popups/GuestPrompt/GuestPrompt";
import AddFriends from "./Popups/AddFriends/AddFriends";
import MakeLeader from "./Popups/MakeLeader/MakeLeader";
import LeaveGroup from "./Popups/LeaveGroup/LeaveGroup";
import RevisionIntro from "./Popups/RevisionInto/RevisionIntro";
import "./Popup.scss";
import { lazyWithPreload } from "utils/Functions";
import PopupContainer from "./Popups/PopupContainer/PopupContainer";
import Streak from "Components/Streak/Streak";
import StreakPopup from "./Popups/StreakPopup/StreakPopup";
import RewardsIntro from "./Popups/RewardsIntro/RewardsIntro";
import DynamicPositionPopup from "Components/DynamicPositionPopup";
import { EarlyBird } from "./Popups/Earlybird/Earlybird";
import EarlyBirdCongratulations from "./Popups/Earlybird/Congratulations";
import EarlyBirdPopup from "./Popups/Earlybird/EarlyBirdPopup";
import PaymentFailed from "./Popups/Earlybird/PaymentFailed";
import Style from "Pages/GroupMembers/BottomIosPopup/Style";

const Popup = ({
  isOpen = false,
  id,
  onPopupClose,
  children,
  type = "bottom",
  ...props
}) => {
  const container = useRef(null);
  useWindowHeight(container);

  const popupData = useSelector((store) => store.ui.popupData);
  const dispatch = useDispatch();

  const onClosePopup = () => {
    dispatch(setPopup(null));
  };

  const renderPopup = (id, props) => {
    switch (id) {
      case 1:
        return <TrialConfirmation isOpen={isOpen} {...props} />;
      case 2:
        return <PlanSuccess isOpen={isOpen} {...props} />;
      case 3:
        return <Error isOpen={isOpen} {...props} />;
      case 4:
        return <LogoutConfirmation isOpen={isOpen} {...props} />;
      case 5:
        return <ModuleIntro isOpen={isOpen} {...props} />;
      case 6:
        return <GuestPrompt isOpen={isOpen} {...props} />;
      case 7:
        return (
          <AddFriends isOpen={isOpen} onClosePopup={onClosePopup} {...props} />
        );
      case 8:
        return (
          <MakeLeader isOpen={isOpen} onClosePopup={onClosePopup} {...props} />
        );
      case 9:
        return (
          <LeaveGroup isOpen={isOpen} onClosePopup={onClosePopup} {...props} />
        );
      case 11:
        return (
          <RevisionIntro
            isOpen={isOpen}
            onClosePopup={onClosePopup}
            {...props}
          />
        );
      case 12:
        return (
          <PopupContainer
            isOpen={isOpen}
            onClosePopup={onClosePopup}
            {...props}
          />
        );
      case 13:
        return (
          <StreakPopup isOpen={isOpen} onClosePopup={onClosePopup} {...props} />
        );
      case 14:
        return (
          <RewardsIntro
            isOpen={isOpen}
            onClosePopup={onClosePopup}
            {...props}
          />
        );
      case 15:
        return <DynamicPositionPopup {...props} />;

      // Early Bird Subscription
      case 16:
        return <EarlyBird />;

      case 17:
        return <EarlyBirdCongratulations />;

      case 19:
        return <PaymentFailed />;
      default:
        return null;
    }
  };

  if (id === 10)
    return (
      <SnackBar
        error={popupData?.text}
        closeSnack={onClosePopup}
        type={popupData?.snackType}
        time={popupData?.time}
        alignment="center"
      />
    );

  return (
    <div className={`popup-container ${!isOpen ? "hide" : ""}`} ref={container}>
      <div
        className={`${
          id == 16 || id == 17 || id == 19 ? "allEarlyBirdPopups" : "popup"
        }   ${type}`}
      >
        {onPopupClose && (
          <img
            className="close"
            src={require("assets/svgIcons/close.svg")}
            onClick={onPopupClose}
          />
        )}
        {children}
        <div className="popup-inner">{renderPopup(id, props)}</div>
      </div>
    </div>
  );
};

export default Popup;
