import { SETCOURSESTATDATA, SETSCOREDATA, SETSTATDATA, SETSTREAKDETAILS, SETSTREAKLIST } from './actonsType';

export const setScoreData = (data) => (dispatch) => {
    let currentDate = new Date();
    let currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    let currentMonthEnd = new Date(currentMonthStart).setMonth(currentMonthStart.getMonth() + 1);
    let weekIntervals = [currentMonthStart];
    let yourScore = [...Array(4).fill(0)];
    let averageScore = [...Array(4).fill(0)];
    let weekCount = 1;
    let score = 0;
    let avgScore = 0;
    let count = 0;

    if (data?.tests?.length > 0) {
        for (let i = 0; i < 4; i++) {
            if (i === 3) {
                weekIntervals.push(new Date(currentMonthEnd));
            } else {
                let date = new Date(weekIntervals[i]);
                let nextDate = new Date(date).setDate(date.getDate() + 7);
                weekIntervals.push(new Date(nextDate));
            }
        }

        (data?.tests ?? []).forEach((test) => {
            let completionDate = new Date(test?.created_at);
            if (completionDate < weekIntervals[0]) return;
            for (let i = 1; i <= 4; i++) {
                if (completionDate <= weekIntervals[i] && completionDate >= weekIntervals[i - 1]) {
                    if (weekCount < i) {
                        yourScore[weekCount - 1] = isNaN(parseInt(score / count)) ? 0 : parseInt(score / count);
                        averageScore[weekCount - 1] = isNaN(parseInt(avgScore / count)) ? 0 : parseInt(avgScore / count);
                        score = 0;
                        avgScore = 0;
                        count = 0;
                        weekCount = i;
                        score += test?.score;
                        avgScore += test?.score - 15;
                        count++;
                    } else {
                        score += test?.score;
                        avgScore += test?.score - 15;
                        count++;
                    }
                }
            }
        });

        yourScore[weekCount - 1] = isNaN(parseInt(score / count)) ? 0 : parseInt(score / count);
        averageScore[weekCount - 1] = isNaN(parseInt(avgScore / count)) ? 0 : parseInt(avgScore / count);
        score = 0;
        avgScore = 0;
        count = 0;
        weekCount++;
    }

    data.yourScore = yourScore;
    data.averageScore = averageScore;

    dispatch({
        type: SETSCOREDATA,
        payload: data,
    });
};

export const setStatDetails = (data) => (dispatch) => {
    dispatch({
        type: SETSTATDATA,
        payload: data,
    });
};

export const setStreakDetails = (data) => (dispatch) => {
    dispatch({
        type: SETSTREAKDETAILS,
        payload: data,
    });
};

export const setStreakList = (data) => (dispatch) => {
    dispatch({
        type: SETSTREAKLIST,
        payload: data,
    });
};

export const setCourseStats = (data) => (dispatch) => {
    dispatch({
        type: SETCOURSESTATDATA,
        payload: data,
    });
};
