import Request from 'utils/Request';
import {
    setPlanList,
    setUserPlans,
    setGstData,
    updateUserPlans
} from 'redux/actions/planActions/actions';
import {
    updateUserInfo,
    setWalletDetails
} from 'redux/actions/userActions/actions';
import {
    setAppreciationPopup
} from 'redux/actions/uiActions/actions';
import {
    trackTrialActivation
} from 'analytics/miscellaneous'

export const getPlanList = (config) => async dispatch => {
    try {
        const response = await Request({
            method: 'GET',
            url: '/store/plan/get',
            ...config
        })

        const { Code, data } = response.data;

        if (Code === 1) {
            dispatch(setPlanList(data?.plan?.plan));
            return true;
        }
        return false;
    }
    catch (error) {
        console.log(error)
        return false;
    }
}

export const getUserPlans = (config) => async dispatch => {
    try {
        const response = await Request({
            method: 'GET',
            url: '/payment/user/purchase/list?userId=5fa2766f0e4b2a5260a2a008',
            ...config
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            dispatch(setUserPlans(data?.purchaseList));
            return true;
        }
        return false;
    }
    catch (error) {
        console.log(error)
        return false;
    }
};

export const getGstDetail = (config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'GET',
            url: '/gst/detail',
            ...config
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            dispatch(setGstData(data?.gstData));
            return true;
        }
        return false;
    }
    catch (error) {
        console.log(error)
        return false;
    }
}

export const activateTrial = (config) => async dispatch => {
    try {
        const response = await Request({
            method: 'POST',
            url: '/user/activate/trial',
            ...config
        })

        const { Code, data } = response.data;

        if (Code === 1) {
            config?.popup && dispatch(setAppreciationPopup(6));
            dispatch(updateUserInfo({
                isPro: true,
                isDemoComplete: true,
                userDemoExpire: data?.currentDate
            }));
            trackTrialActivation(data?.currentDate);
        }
    }
    catch (error) {

    }
};

export const applyCouponCode = async (couponCode, config) => {
    try {
        const response = await Request({
            method: 'GET',
            url: '/store/coupon/apply',
            params: {
                couponCode
            },
            ...config
        })

        const { Code, data } = response.data;

        if (Code === 1) {
            return {
                discount: data?.detail?.couponDiscount,
                maxDiscount: data?.detail?.couponMaxDiscount
            };
        }
        return false;
    }
    catch (error) {
        return false;
    }
};

export const getOrderId = async (planId, couponCode, coinsRedeemed, config) => {
    try {
        const response = await Request({
            method: 'POST',
            url: '/payment/orderId',
            data: {
                planId,
                couponCode,
                coinsRedeemed
            },
            ...config
        })

        const { Code, data } = response.data;

        if (Code === 1) {
            return {
                orderId: data?.order?.id,
                amount: data?.order?.amount
            }
        }
    }
    catch (error) {

    }
};

export const orderSuccess = ({ razorpay_order_id, razorpay_payment_id, razorpay_signature }, config) => async (dispatch, getState) => {
    try {
        const courseId = getState().user.userInfo?.courseId?.[0];
        const response = await Request({
            method: 'POST',
            url: '/payment/purchase',
            data: {
                razorpay_order_id,
                razorpay_payment_id,
                razorpay_signature,
                courseId
            },
            ...config
        })

        const { Code, data } = response.data;

        if (Code === 1) {
            dispatch(setWalletDetails(data?.wallet, 'earn'));
            return data?.purchase;
        }
    }
    catch (error) {
        console.log(error)
    }
};