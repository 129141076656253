import React from 'react';
import "./WeakTopics.scss";

const WeakTopics = () => {
    const windowheight = (window.innerHeight - 50)/10;
    return (
        <div className='notifications-weak-topics-main-container' style={{maxHeight: `${windowheight}`}}>
            <div className='notifications-weak-topics-inner-container'>
                <div className='notifications-weak-topics-title-container'>
                    <div className='notifications-weak-topics-weak-topic-title'>
                        Weak Topics
                    </div>
                </div>
                <div className='notifications-weak-topics-container'>
                    <div className='notifications-weak-topics-item-container'>
                        <div className='notifications-weak-topics-left-container'>
                            <div className='notifications-weak-topics-left-subject-name'>
                                Ancient History
                            </div>
                            <div className='notifications-weak-topics-left-topic-name'>
                                Vedic Age
                            </div>
                        </div>
                        <div className='notifications-weak-topics-right-container'>
                            <div className='notifications-weak-topics-right-chevron-container'>
                                <img 
                                    src={require("assets/svg/notification_weak_topic_right_chevron.svg")}
                                    alt="right_chevron"
                                    className='right_chevron_weak_topic'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='notifications-weak-topics-item-container'>
                        <div className='notifications-weak-topics-left-container'>
                            <div className='notifications-weak-topics-left-subject-name'>
                                Ancient History
                            </div>
                            <div className='notifications-weak-topics-left-topic-name'>
                                Vedic Age
                            </div>
                        </div>
                        <div className='notifications-weak-topics-right-container'>
                            <div className='notifications-weak-topics-right-chevron-container'>
                                <img 
                                    src={require("assets/svg/notification_weak_topic_right_chevron.svg")}
                                    alt="right_chevron"
                                    className='right_chevron_weak_topic'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='notifications-weak-topics-item-container'>
                        <div className='notifications-weak-topics-left-container'>
                            <div className='notifications-weak-topics-left-subject-name'>
                                Ancient History
                            </div>
                            <div className='notifications-weak-topics-left-topic-name'>
                                Vedic Age
                            </div>
                        </div>
                        <div className='notifications-weak-topics-right-container'>
                            <div className='notifications-weak-topics-right-chevron-container'>
                                <img 
                                    src={require("assets/svg/notification_weak_topic_right_chevron.svg")}
                                    alt="right_chevron"
                                    className='right_chevron_weak_topic'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='notifications-weak-topics-item-container'>
                        <div className='notifications-weak-topics-left-container'>
                            <div className='notifications-weak-topics-left-subject-name'>
                                Ancient History
                            </div>
                            <div className='notifications-weak-topics-left-topic-name'>
                                Vedic Age
                            </div>
                        </div>
                        <div className='notifications-weak-topics-right-container'>
                            <div className='notifications-weak-topics-right-chevron-container'>
                                <img 
                                    src={require("assets/svg/notification_weak_topic_right_chevron.svg")}
                                    alt="right_chevron"
                                    className='right_chevron_weak_topic'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WeakTopics;