import React, { useEffect } from 'react';
import useRedirect from 'customhooks/useRedirect';
import { useHistory } from 'react-router-dom';
import { trackError } from 'analytics/miscellaneous';
import './Error.scss';

const Error = ({ onClose, data }) => {
    const redirect = useRedirect();
    const history = useHistory();

    useEffect(() => {
        trackError(history?.location?.pathname, data?.error, data?.info);
    }, []);

    const onReload = () => {
        redirect('');
        window.location.reload();
        onClose();
    }

    return (
        <div className="error-container">
            <div className="image-wrapper">
                <img src={require('assets/images/error-img.png')} />
            </div>
            <button className="btn-primary" onClick={onReload}>Try Again</button>
        </div>
    );
};

export default Error;