import { RESETGROUP, SETACTIVEGROUP, SETCOURSERANKLIST, SETGROUPLIST, SETGROUPMEMBERS, SETSUBJECTRANKLIST } from "../actions/groupActions/actionType";
import { LOGOUT } from "../actions/authActions/actionType";
import { RESETCOURSE } from "../actions/uiActions/actionType";

const initialState = {
    activeGroup: null,
    groups: [],
    groupMembers: [],
    courseRankList: [],
    subjectRankList: [],
};

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETACTIVEGROUP:
            return {
                ...state,
                activeGroup: action.payload,
            };
        case SETGROUPLIST:
            return {
                ...state,
                groups: action.payload,
            };

        case SETGROUPMEMBERS:
            return {
                ...state,
                groupMembers: action.payload,
            };

        case SETCOURSERANKLIST:
            return {
                ...state,
                courseRankList: action.payload,
            };

        case SETSUBJECTRANKLIST:
            return {
                ...state,
                subjectRankList: action.payload,
            };

        case RESETGROUP:
            return {
                ...state,
                activeGroup: null,
                groupMembers: [],
                courseRankList: [],
                subjectRankList: [],
            };
        case RESETCOURSE:
            return {
                ...state,
                groupMembers: [],
                courseRankList: [],
                subjectRankList: [],
            };

        default:
            return state;
    }
};

export default groupReducer;
