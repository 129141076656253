import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRedirect from 'customhooks/useRedirect';
import { guestLogin } from 'services/auth';
import { setGuestPrompt, setPopup } from 'redux/actions/uiActions/actions';
import { setShowAnswers } from 'redux/actions/assessmentActions/actions';
import { trackGuestSignup } from 'analytics/guest';
import './Guest.scss';

const Guest = ({ showLater = true }) => {
    const token = useSelector(store => store.auth.token);
    const showAnswers = useSelector(store => store.assessment.showAnswers);
    const dispatch = useDispatch();

    const redirect = useRedirect();

    const onCreateProfile = () => {
        trackGuestSignup({Create_Profile_Button: true});
        redirect('signup');
    }

    const onLater = async () => {
        if (token) {
            dispatch(setGuestPrompt(false));
            dispatch(setPopup(null));
        } else {
            const response = await dispatch(guestLogin({ loader: true, global: true }));
            response && trackGuestSignup({Create_Profile_Later: true});
            if (response === true && showAnswers) {
                redirect('quiz/guest/assessment/1');
                dispatch(setShowAnswers(false));
            }
        }
    }

    return (
        <div className="guest-login-container">
            <div className="profile-wrapper">
                <img src={require('assets/svgIcons/profile-icon.svg')} />
            </div>
            <div className="message-wrapper">
                <p className="message-title">Time to create a profile!</p>
                <p className="message-description">Create your Examarly Profile to save your Progress and Continue Learning for Free.</p>
            </div>
            <div className="guest-action-container">
                <button className="btn-primary" onClick={onCreateProfile}>
                    Create Profile
                </button>
                {showLater && (
                    <p className="text-action" onClick={onLater}>Later</p>
                )}
            </div>
        </div>
    );
};

export default Guest;