import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { increamentStep, endTour } from 'redux/actions/uiActions/actions';
import tourData from './TourPath.json';
import './TourTooltip.scss';

const TourTooltip = ({ children, id, path, step, show = true, mobile = false }) => {
    const [isActive, setActive] = useState(false);

    const isTourActive = useSelector(store => store.ui.isTourActive);
    const tourPath = useSelector(store => store.ui.tourPath);
    const tourStep = useSelector(store => store.ui.tourStep);
    const isMobile = useSelector(store => store.ui.isMobile);
    const dispatch = useDispatch();

    let data = tourData?.[tourPath]?.steps?.[tourStep];
    let steps = tourData?.[tourPath]?.[isMobile ? 'mobileSteps' : 'webSteps'];

    useEffect(() => {
        return () => {
            //isActive && dispatch(endTour())
            dispatch(endTour());
        }
    }, [])

    useEffect(() => {
        checkIfActive();
    }, [tourStep, tourPath])

    useEffect(() => {
        if (data?.scrollTo && isMobile) {
            let element = document.querySelector(data?.scrollTo);
            element.scrollIntoView({ behaviour: 'smooth' });
        }
        if (data?.scrollToTop && isMobile) {
            window.scrollTo(0, 0);
        }
    }, [tourStep]);

    useEffect(() => {
        isActive && positionTooltip();
    }, [tourStep, show, isActive]);

    const checkIfActive = () => {
        if (isTourActive && tourPath === path && tourStep === step) {
            if (mobile) {
                if (!isMobile) {
                    setActive(false);
                    dispatch(increamentStep());
                    return;
                }
            }
            setActive(true);
        } else {
            setActive(false);
        }
    }

    const positionTooltip = () => {
        let element = document.querySelector(`.tip-${path}${step}`);
        if (data?.orientation.includes('left') && isMobile) {
            let position = element.getBoundingClientRect();
            if (position?.left !== 15) {
                if (position?.left > 15) {
                    element.style.left = `-${position.left - 15}px`
                } else {
                    element.style.left = `${15 - position.left}px`
                }
            }
        }
        if (element) element.style.opacity = 1;
    }

    const onNext = () => {
        setActive(false);
        dispatch(increamentStep());
    };

    const onSkip = () => {
        setActive(false);
        dispatch(endTour());
    }

    return (
        <>
            {show ? <div className={`tour-tooltip-parent ${isActive ? 'show' : ''} ${data?.orientation} ${data?.type} ${id}`}>
                <div className="overlay"></div>
                <div className={`tour-tooltip-container ${data?.orientation} ${'tip-' + path + step}`}>
                    <div className="tour-top-section">
                        <p className="tour-title">{data?.heading}</p>
                        <p className="tour-description">{data?.description}</p>
                    </div>
                    <div className="tour-mid-section">
                        {data?.image && <img className="tour-img" src={require(`assets/images/${data?.image}.svg`)} />}
                    </div>
                    {(tourData?.[tourPath]?.steps?.length > 1 || data?.last) && (
                        <div className="tour-bottom-section">
                            <p className="step-count">{tourStep + 1}/{steps}</p>
                            <div className="btn-container">
                                {!data?.last && <p className="skip-txt" onClick={onSkip}>skip</p>}
                                {data?.next && <div className="next-btn" onClick={onNext}>Next</div>}
                                {data?.last && <div className="next-btn" onClick={onSkip}>Finish</div>}
                            </div>
                        </div>
                    )}
                </div>
                <div className={`wrapper ${isActive ? 'tooltip-active' : ''}`}>
                    <div className="highlight"></div>
                    {children}
                </div>
            </div> : children
            }
        </>
    );
};

export default TourTooltip;