import Request from 'utils/Request';
import {
    setBooks,
    setPlanningData,
    setSubjectList,
    setSubtopicData,
    updateSubtopic,
    onSubtopicUnlock,
    setSubtopicContent,
    sortSubjects,
    setCourseList,
    setActiveCourse,
    setSubjectPreferenceList,
    setAutoAssignedPreferenceList,
} from 'redux/actions/planningTrackingActions/actions';
import { setAssessmentQuestions } from 'redux/actions/assessmentActions/actions';
import { updateDailyTask } from 'redux/actions/homeActions/actions';
import { updateUserInfo, addStreak, setWalletDetails } from 'redux/actions/userActions/actions';
import { filterData } from 'utils/Functions';
import { setAppreciationPopup, setGuestPrompt, resetCourse, setPopup } from 'redux/actions/uiActions/actions';
import { getStreakData } from './stats';
import { getGroupRankList } from './groupStudy';
import {browserHistory} from "react-router";
import { getDailyTasks } from './home';
const { default: useRedirect } = require("customhooks/useRedirect")

export const getCourseList = (config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'GET',
            url: `/course/list`,
            ...config,
        });

        const { status_code, data } = response.data;

        if (status_code === 200) {
            dispatch(setCourseList(data?.courseList));
            let course = getActiveCourse();
            if (course) dispatch(setActiveCourse(course));
            else dispatch(setLocalCourse(data?.courseList?.[0]?.id));
        }
        return false;
    } catch (error) {
        console.log(error);
    }
};

export const getPlanningData = (subjectId, config) => async (dispatch, getState) => {
    try {
        let id = null;
        const subjects = getState().planning.subjectList ?? [];
        if (subjectId === 'undefined') id = subjects[0]?.id;
        else id = subjectId;
        const response = await Request({
            method: 'GET',
            url: `/v1_2/topic/list`,
            params: { subjectId: id },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(setPlanningData(subjectId, data?.topicList ?? []));
            return data?.topicList ?? [];
        }
        return false;
    } catch (error) {
        console.log(error);
    }
};

export const getSubtopicData = (topicId, config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'GET',
            url: `/v1_2/subtopic/list`,
            params: { topicId },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(setSubtopicData(topicId, data));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
    }
};

export const getSubtopicContent = (subtopicId, config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'GET',
            url: `/subtopic/content`,
            params: { subtopicId },
            ...config,
        });

        const { status_code, data } = response.data;

        if (status_code === 200) {
            dispatch(setSubtopicContent(subtopicId, data));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
    }
};

export const getSubjectList = (courseId, config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'GET',
            url: 'v1_2/subject/list',
            params: {
                courseId,
            },
            ...config,
        });

        const { status_code, data } = response.data;
        if (status_code === 200) {
            dispatch(resetCourse());
            dispatch(getStreakData());
            await dispatch(updateUserInfo(data));
            dispatch(setSubjectList(data.subjectList));
        }
    } catch (error) {
        console.log(error);
    }
};

export const getSubjectPreferenceList = (config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: 'GET',
            url: '/scheduler/getUserSubjectPreferenceList',
            params: {
                courseId,
            },
            ...config,
        });

        const { status_code, data } = response.data;

        if (status_code === 200) {
            dispatch(setSubjectPreferenceList(data));
            dispatch(getDailyTasks());
        } 
        
        if (status_code === 404) {
            if (!window.location.href.includes('/home/onboard')) {
                const redirectLink = window.location.origin + '/home/onboard'
                window.open(redirectLink, "_self").focus()
            }
        }
    } catch (error) {
        console.log(error);
    }
};

export const setUserSubjectPreferenceList = (preferenceList, config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: 'POST',
            url: '/scheduler/setUserSubjectPreferenceList',
            data: {
                courseId,
                preferenceList,
            },
            ...config,
        });

        const { status_code, data } = response.data;
        if (status_code === 200) {
            dispatch(setSubjectPreferenceList(data));
            // navigate here too
            window.open(window.location.origin + '/home', '_self').focus();
        }
    } catch (error) {
        console.log(error);
    }
};

export const getBooks = (topicId, config) => async (dispatch, getState) => {
    try {
        const response = await Request({
            method: 'GET',
            url: '/booklist/list/bookslist',
            params: {
                topicId,
            },
            ...config,
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            dispatch(setBooks(topicId, data.booksList));
        }
    } catch (error) {
        console.log(error);
    }
};

export const takeTest = (subtopics, config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'POST',
            url: '/planning/subtopic/test',
            data: {
                subtopics,
            },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(setAssessmentQuestions(data?.questions, 1, { ...data?.testDetails }));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getTestQuestion = (testData, topicId, subjectId, subtopicId, isLastSubtopic, isLastTopic, config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'POST',
            url: '/planning/tracking/test/question',
            data: {
                testId: testData?._id,
            },
            ...config,
        });

        const { Code, data } = response.data;

        testData.topicId = topicId;
        testData.subjectId = subjectId;
        testData.subtopicId = subtopicId;
        testData.isLastSubtopic = isLastSubtopic;
        testData.isLastTopic = isLastTopic;

        if (Code === 1) {
            dispatch(setAssessmentQuestions(data?.questions, testData?._id, testData));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const submitUserResponse = (config) => async (dispatch, getState) => {
    try {
        const transformedResponse = dispatch(transformResponse());
        const quizDetails = getState().assessment.quizDetails;
        const response = await Request({
            method: 'POST',
            url: '/planning/tracking/test/submit',
            data: {
                submittedSolutions: transformedResponse,
                todayPlanDetails: [
                    {
                        score: parseInt(quizDetails?.totalMarks ?? 0),
                        timeTaken: '60',
                    },
                ],
                testId: quizDetails?._id,
            },
            ...config,
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const transformResponse = () => (dispatch, getState) => {
    const questions = getState().assessment.questions;
    const response = getState().assessment.response;
    const transformedResponse = [];

    questions.forEach(({ _id }) => {
        let responseObj = {};
        responseObj.question = _id;
        let responseOptionId = response?.[_id] ?? null;
        responseObj.userAnswer = [responseOptionId?.optionId];
        transformedResponse.push(responseObj);
    });

    return transformedResponse;
};

export const addSpecialSubject = (subjectId, config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        let weakSubject = getState().user.userInfo?.weakSubject ?? [];
        let specializedSubject = getState().user.userInfo?.specializedSubject ?? [];

        const response = await Request({
            method: 'POST',
            url: '/subject/specializedSubject/add',
            data: {
                courseId,
                subjectId,
            },
            ...config,
        });

        const { status_code } = response.data;

        if (status_code === 200) {
            weakSubject = weakSubject.filter((sub) => sub !== subjectId);
            specializedSubject = [...specializedSubject, subjectId];
            dispatch(
                updateUserInfo({
                    weakSubject: weakSubject,
                    specializedSubject: specializedSubject,
                })
            );
            dispatch(sortSubjects(weakSubject[0]));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const onSubtopicComplete = (config) => async (dispatch, getState) => {
    try {
        const specializedSubject = getState()?.user?.userInfo?.specializedSubject;
        const result = getState().assessment.result;
        const quizDetails = getState().assessment.quizDetails;
        const { isLastTopic = false, isLastSubtopic = false } = quizDetails;
        const isLast = isLastSubtopic && isLastTopic;
        let subTopicScores = [];

        (quizDetails?.subtopicId ?? []).forEach((id) => {
            subTopicScores.push(result?.subtopicScore?.[id?.testSubtopicId] ?? 0);
        });

        const response = await Request({
            method: 'POST',
            url: '/planning/tracking/subtopic/complete',
            data: {
                subTopic: filterData('subtopicId', quizDetails?.subtopicId),
                lastSubTopic: isLast,
                isLastSubtopic: quizDetails?.isLastSubtopic,
                topicId: quizDetails?.topicId,
                subTopicPercentage: result?.percentage,
                subTopicScores,
            },
            ...config,
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            if (isLast) {
                dispatch(
                    updateUserInfo({
                        newSubject: true,
                        weakSubject: [],
                        specializedSubject: [...specializedSubject, quizDetails?.subjectId],
                    })
                );
            }
            // isLastSubtopic && !isLastTopic && dispatch(setAppreciationPopup(7));
            dispatch(setGuestPrompt(true));
            dispatch(updateSubtopic(quizDetails?.subtopicId, quizDetails?.topicId, quizDetails?.subjectId, isLast));
            dispatch(updateDailyTask(1));
            dispatch(getGroupRankList());
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const resetWeakSubjects = (config) => async (dispatch, getState) => {
    try {
        const response = await Request({
            method: 'POST',
            url: '/user/subject/clear',
            ...config,
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            dispatch(
                updateUserInfo({
                    specializedSubject: [],
                    newSubject: false,
                })
            );
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const onUnlockSubtopic = (topicId, index, config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'POST',
            url: '/user/unlock/plan',
            ...config,
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            dispatch(onSubtopicUnlock(topicId, index));
            dispatch(setWalletDetails(data?.wallet, 'spend'));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getActiveCourse = () => {
    let activeCourseId = localStorage.getItem('course');
    return activeCourseId;
};

export const setLocalCourse = (id) => async (dispatch) => {
    dispatch(setActiveCourse(id));
    localStorage.setItem('course', id);
};
