import {
    SETREVISIONSUBTOPIC,
    UPDATEREVISIONSUBTOPIC,
    SETCOMPLETEDSUBTOPIC,
    SETTESTTAKEN
} from '../actions/revisionActions/actionType';

const initialState = {
    revisionSubtopics: null,
    testTaken: false,
    isLocked: false,
    completedSubtopic: []
};

const revisionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETREVISIONSUBTOPIC: return {
            ...state,
            revisionSubtopics: action.payload.subtopics,
            isLocked: action.payload.isLocked,
            testTaken: false
        };

        case SETCOMPLETEDSUBTOPIC: return {
            ...state,
            completedSubtopic: action.payload
        }

        case UPDATEREVISIONSUBTOPIC: return {
            ...state,
            revisionSubtopics: action.payload
        }

        case SETTESTTAKEN: return {
            ...state,
            testTaken: action.payload,
            completedSubtopic: []
        }

        default:
            return state;
    }
};

export default revisionReducer;
