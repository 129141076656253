import {
    SETSURVEYDATA,
    SETASSESSMENTDATA,
    SETUSERRESPONSE,
    SETASSESSMENTRESULT
} from '../actions/guestActions/actionType';
import {
    LOGOUT
} from '../actions/authActions/actionType';

const initialState = {
    surveyData: {
        isComplete: false,
        surveyAnswers: []
    },
    testData: {
        isComplete: false,
        questions: [],
        answers: {},
        result: {}
    }
};

const guestReducer = (state = initialState, action) => {
    switch (action.type) {

        case SETSURVEYDATA: return {
            ...state,
            surveyData: action.payload
        };

        case SETASSESSMENTDATA: return {
            ...state,
            testData: action.payload
        }

        case SETUSERRESPONSE: return {
            ...state,
            testData: {
                ...state.testData,
                answers: action.payload
            }
        }

        case SETASSESSMENTRESULT: return {
            ...state,
            testData: {
                ...state.testData,
                isComplete: true,
                result: action.payload
            }
        }

        case LOGOUT: return {
            ...state,
            surveyData: {
                isComplete: false,
                surveyAnswers: []
            }
        }

        default: return { ...state };
    }
};

export default guestReducer;