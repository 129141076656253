import Request from 'utils/Request';
import { setBanners, setSubjects, getDailyUserTasks, updateDailyTask, addNewDailyTask, changeTaskStatus } from 'redux/actions/homeActions/actions';
import { setAssessmentQuestions, setTestRewards } from 'redux/actions/assessmentActions/actions';
import { addStreak, updateUserInfo } from 'redux/actions/userActions/actions';
import { dailyTask } from 'Pages/HomePage/DailyTask/DailyTask';
import { trackLatestStreak } from 'analytics/streak';
import { transformDate } from 'utils/Functions';
import { trackTaskCompletion } from 'analytics/home';
import { setStatDetails } from 'redux/actions/statActions/actions';
import { trackAllTasksCompleted, trackNewTasksAssigned, trackSchedulerTaskCompleted } from 'analytics/scheduler';

export const getBanners = (config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'GET',
            url: '/banner/list',
        });

        const { Code, data } = response.data;

        if (Code === 0) {
        }
        if (Code === 1) {
            dispatch(setBanners(data?.list));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getSubjects = (config) => async (dispatch, getState) => {
    const courseId = getState().user.userInfo?.courseId?.[0];
    try {
        const response = await Request({
            method: 'GET',
            url: '/course/subjects',
            params: {
                courseId,
            },
            ...config,
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            dispatch(setSubjects(data));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const createDailyTest = (masterSubjectId, config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'POST',
            url: '/planning/daily/test/creation',
            data: {
                masterSubjectId,
            },
            ...config,
        });

        const { Code, data, message } = response.data;

        if (Code === 1) {
            return data?.trackingTest;
        }
        if (Code === 0) {
            return message;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getDailyTestQuestion = (testData, config) => async (dispatch) => {
    try {
        const response = await Request({
            method: 'POST',
            url: '/planning/tracking/test/question',
            data: {
                testId: testData?._id,
            },
            ...config,
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            dispatch(setAssessmentQuestions(data?.questions, testData?._id, testData));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getDailyQuizQuestions = (body, config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: 'POST',
            url: '/currentAffairs/test',
            data: { ...body, courseId },
            ...config,
        });

        const { status_code, data } = response.data;

        if (status_code === 200) {
            dispatch(setAssessmentQuestions(data?.questions, 1, { ...data?.testDetails, date: transformDate(body?.date ?? new Date()) }));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const updateDailyTaskCount = () => async (dispatch, getState) => {
    try {
        let weeklyCompletedTask = getState().user.userInfo?.weeklyCompletedTask ?? 0;
        const response = await Request({
            method: 'POST',
            url: '/user/weekly/task',
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            dispatch(
                updateUserInfo({
                    weeklyCompletedTask: weeklyCompletedTask + 1,
                })
            );

            dispatch(addStreak());

            return true;
        }

        return false;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const getDailyTasks = (config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: 'GET',
            url: '/scheduler/getTasks',
            params: { courseId },
            ...config,
        });

        const { status_code, data } = response.data;

        if (status_code === 200) {
            dispatch(getDailyUserTasks(data ?? []));
            let todayTasks = data?.filter(
                task =>
                  task?.taskType !== 'dailyQuiz' &&
                  task?.taskType !== 'currentAffairs' &&
                  task?.alreadyAssigned === false
              );
              if (todayTasks?.length > 0) {
                trackNewTasksAssigned();
              }
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const addNewTask = (config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: 'GET',
            url: '/scheduler/new-task',
            params: { courseId },
            ...config,
        });

        const { status_code, data } = response.data;

        if (status_code === 200) {
            dispatch(addNewDailyTask(data?.details));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const changeDailyTaskStatus = (taskId, status, type, config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        let rewards = getState().assessment.rewards ?? {};
        const dailyTasks = getState().home.dailyTasks;
        const response = await Request({
            method: 'POST',
            url: '/scheduler/updateTaskStatus',
            data: {
                courseId,
                uuid: taskId,
                status,
                taskType: type,
            },
            ...config,
        });

        const { status_code, data } = response.data;

        if (status_code === 200) {
            if (status === 'completed') {
                if (type !== 'dailyQuiz' && type !== 'currentAffairs') {
                  let taskIndex = dailyTasks?.findIndex(
                    task => task?.uuid === taskId,
                  );
                  trackSchedulerTaskCompleted(taskIndex + 1);
                }
                let incompleteTasks = dailyTasks?.filter(
                  task =>
                    task?.taskType !== 'dailyQuiz' &&
                    task?.taskType !== 'currentAffairs' &&
                    task?.status !== 'completed',
                );
                if (incompleteTasks?.length === 1) {
                  trackAllTasksCompleted();
                }
              }
            dispatch(changeTaskStatus(taskId, status));
            let dailyTask = null;
            if (data?.xpEarned) {
                dailyTask = {};
                dailyTask['xpEarned'] = data?.xpEarned;
            }
            if (data?.isLevelUp) {
                rewards['isLevelUp'] = data?.isLevelUp;
            }
            if (data?.isStreak) {
                rewards['isStreak'] = true;
            }
            rewards['dailyTask'] = dailyTask;

            dispatch(setTestRewards({ ...rewards }));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const addDailyTask = (taskId, config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        let rewards = getState().assessment.rewards ?? {};

        const response = await Request({
            method: 'POST',
            url: '/dailyTasks/add',
            data: {
                taskId: taskId,
                courseId,
            },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 201) {
            trackTaskCompletion(taskId);
            trackLatestStreak(transformDate(new Date()));
            dispatch(updateDailyTask())

 
            if (data?.isStreak) {
                rewards['isStreak'] = true;
            }
   

            dispatch(setTestRewards({ ...rewards }));

            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const checkRewardsOnboardingStatus = async () => {
    let onBoarding = localStorage.getItem('rewards-onboarding');
    if (onBoarding) return true;
    else localStorage.setItem('rewards-onboarding', 'done');
};
