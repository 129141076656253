import {
  SETFAQDATA,
  SETABOUTUSDATA,
  SETPRIVACYPOLICYDATA,
  SETTERMSANDCONDITIONDATA
} from '../actions/dataActions/actionType';

const initialState = {
  faqData: null,
  aboutusData: null,
  privacyPolicyData: null,
  termsAndConditionData: null
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETFAQDATA:
      return {
        ...state,
        faqData: action.payload
      }
    case SETABOUTUSDATA:
      return {
        ...state,
        aboutusData: action.payload
      }
    case SETPRIVACYPOLICYDATA:
      return {
        ...state,
        privacyPolicyData: action.payload
      }
    case SETTERMSANDCONDITIONDATA:
      return {
        ...state,
        termsAndConditionData: action.payload
      }
    default:
      return state;
  }
};

export default dataReducer;