import Request from 'utils/Request';
import { updateUserInfo } from 'redux/actions/userActions/actions';
import {
    setAssessmentQuestions,
    clearLocalData
} from 'redux/actions/assessmentActions/actions';
import {
    clearPlanningData
} from 'redux/actions/planningTrackingActions/actions';
import {
    filterData
} from 'utils/Functions';

//REFACTORING REQUIRED
export const addSurveyData = (config) => async (dispatch, getState) => {
    try {
        let surveyData = getState().guest.surveyData?.surveyAnswers ?? [];
        let sourceChannel = surveyData.find(survey => survey.type === 'sourceChannel');
        let targetYear = surveyData.find(survey => survey.type === 'targetYear');
        let selfDeclaredLevel = surveyData.find(survey => survey.type === 'selfDeclaredLevel');

        const response = await Request({
            method: 'POST',
            url: '/user/update/extra',
            data: {
                sourceChannel: sourceChannel.answer,
                targetYear: targetYear.answer,
                selfDeclaredLevel: selfDeclaredLevel.answer
            },
            ...config
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            dispatch(updateUserInfo({ isSurveyComplete: true }))
            return true;
        }
        return false;
    }
    catch (error) {
        console.log(error);
    }
}

export const createTest = (config) => async (dispatch, getState) => {
    const courseId = getState().planning.activeCourse;
    const user = getState().user.userInfo;
    try {
        const response = await Request({
            method: 'POST',
            url: '/planning/course/test',
            data: {
                courseId,
                userId: user?.id
            },
            ...config
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(setAssessmentQuestions(data?.questions, 1, data?.testDetails));
            return true;
        }
        return false;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

export const getTestQuestion = (testData, config) => async (dispatch, getState) => {
    const token = getState().auth.token;
    const url = token ? '/planning/test/question/list/user' : '/planning/test/question'
    try {
        const response = await Request({
            method: 'POST',
            url,
            data: {
                testId: testData?._id
            },
            ...config
        });
        const { Code, data } = response.data;
        if (Code === 1) {
            dispatch(setAssessmentQuestions(data?.questions, testData?._id, testData));
            return true;
        }
        return false;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

export const submitTestResult = (config) => async (dispatch, getState) => {
    try {
        const quizDetails = getState().assessment.quizDetails;
        const result = getState().assessment.result;
        const submittedSolutions = dispatch(transformResponse());

        const response = await Request({
            method: 'POST',
            url: '/planning/test/question/answer/user',
            data: {
                course: [
                    "60084e8b9e588d1c6915c9bd"
                ],
                isPass: result?.isPass,
                isTrackSubject: false,
                leaderBoardDetails: [],
                submittedSolutions,
                testId: quizDetails?._id,
                weakSubject: result?.weakSubjects?.[0]?.subjectId,
                weakSubjectId: result?.weakSubjects?.[0]?.subjectId,
                specializedSubject: filterData('subjectId', result?.specializedSubject)
            },
            ...config
        });

        const { Code, data } = response.data;

        if (Code === 1) {
            clearLocalData();
            dispatch(clearPlanningData());
            dispatch(updateUserInfo({
                isAssessmentComplete: true,
                weakSubject: [result?.weakSubjects?.[0]?.subjectId],
                specializedSubject: filterData('subjectId', result?.specializedSubject)
            }));
        }
    }
    catch (error) {
        console.log(error)
    }
}

const transformResponse = () => (dispatch, getState) => {
    const questions = getState().assessment.questions;
    const response = getState().assessment.response;
    const transformedResponse = [];

    questions.forEach(({ _id, subject }) => {
        let responseObj = {};
        let responseOptionId = response?.[_id] ?? null;
        responseObj.userAnswer = [responseOptionId?.optionId];
        responseObj.question = _id;
        responseObj.subjectId = subject;
        transformedResponse.push(responseObj);
    });

    return transformedResponse;
};

