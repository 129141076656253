import { trackEvent, trackNewEvent } from './base';

export const trackGuestSignup = ({
    SignUp_PopUp_Skip,
    Create_Profile_Button,
    Create_Profile_Later,
  }) => {
    trackEvent('userPathSelection', {
      guestPath: Create_Profile_Later,
    });
    trackNewEvent('Soft Signup Event', {
      SignUp_PopUp_Skip,
      Create_Profile_Button,
      Create_Profile_Later,
    });
  };

