import { SETACTIVEGROUP, SETGROUPMEMBERS, SETGROUPLIST, SETCOURSERANKLIST, SETSUBJECTRANKLIST, RESETGROUP } from './actionType';

export const setActiveGroup = (group) => (dispatch, getState) => {
    dispatch({
        type: SETACTIVEGROUP,
        payload: group,
    });
};

export const setGroupList = (data) => (dispatch) => {
    dispatch({
        type: SETGROUPLIST,
        payload: data,
    });
};

export const setGroupMembers = (data) => (dispatch) => {
    dispatch({
        type: SETGROUPMEMBERS,
        payload: data,
    });
};

export const addNewGroup = (group) => (dispatch, getState) => {
    const groups = getState().group.groups ?? [];
    dispatch({
        type: SETGROUPLIST,
        payload: [...groups, group],
    });
};

export const setCourseRankList = (data) => (dispatch) => {
    dispatch({
        type: SETCOURSERANKLIST,
        payload: data,
    });
};

export const setSubjectRankList = (data) => (dispatch) => {
    dispatch({
        type: SETSUBJECTRANKLIST,
        payload: data,
    });
};

export const removeMember = userId => (dispatch, getState) => {
    let groupMembers = getState().group.groupMembers;
    let courseRankList = getState().group.courseRankList;
    let subjectRankList = getState().group.subjectRankList;
    groupMembers = groupMembers?.filter(member => member?.userId !== userId);
    courseRankList = courseRankList?.filter(member => member?.userId !== userId);
    subjectRankList.forEach(({rankList}) => {
      rankList = rankList?.filter(member => member?.userId !== userId);
    });
    dispatch(setGroupMembers([...groupMembers]));
    dispatch(setCourseRankList([...courseRankList]));
    dispatch(setSubjectRankList([...subjectRankList]));
};

export const removeGroup = (groupId) => (dispatch, getState) => {
    let groups = getState().group.groups;
    groups = groups?.filter((group) => group?.id !== groupId);
    dispatch({ type: RESETGROUP });
    dispatch({
        type: SETGROUPLIST,
        payload: [...groups],
    });
};

export const changeRole = (userId, role) => (dispatch, getState) => {
    let groupMembers = getState().group.groupMembers;
    let courseRankList = getState().group.courseRankList;
    let subjectRankList = getState().group.subjectRankList;
    groupMembers.forEach(member => {
      if (member.userId === userId) {
        member.groupRole = role;
      }
    });
    courseRankList.forEach(member => {
      if (member.userId === userId) {
        member.groupRole = role;
      }
    });
    subjectRankList.forEach(({rankList}) => {
      rankList.forEach(member => {
        if (member.userId === userId) {
          member.groupRole = role;
        }
      });
    });
    dispatch(setGroupMembers([...groupMembers]));
    dispatch(setCourseRankList([...courseRankList]));
    dispatch(setSubjectRankList([...subjectRankList]));
};
