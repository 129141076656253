import useRedirect from 'customhooks/useRedirect';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setPopup } from 'redux/actions/uiActions/actions';
import "./More.scss";

const More = () => {
    const redirect = useRedirect()
    const dispatch = useDispatch()
    const onLogout = (e) => {
        e.stopPropagation()
        e.preventDefault()
        dispatch(setPopup(4));

    }
    return (
        <div className='scheduler-more-button-container'>
            <div className='scheduler-more-button-inner-container'>
                <div className='scheduler-more-button-top-section'>
                    <div className='scheduler-more-button-top-button-item helpandsupport chatbot-action'>
                        <img 
                            src={require("assets/svg/popup_sidebar_more_help.svg")}
                            alt="more_help"
                            className='more-popup-icon help'
                        />
                        Help & Support
                    </div>
                    <div className='scheduler-more-button-top-button-item about' onClick={() => redirect("aboutus")}>
                        <img 
                            src={require("assets/svg/popup_sidebar_more_about.svg")}
                            alt="more_about"
                            className='more-popup-icon about'
                        />
                        About
                    </div>
                    <div className='scheduler-more-button-top-button-item faq' onClick={() => redirect("faq")}>
                        <img 
                            src={require("assets/svg/popup_sidebar_more_faq.svg")}
                            alt="more_faq"
                            className='more-popup-icon faq'
                        />
                        FAQ's
                    </div>
                </div>
                <div className='scheduler-more-button-tandc-section'>
                    <div className='scheduler-more-button-tandc-item terms' onClick={() => redirect("terms")}>
                        Terms of Services
                    </div>
                    <div className='scheduler-more-button-tandc-item privacy' onClick={() => redirect("privacypolicy")}>
                        Privacy Policy
                    </div>
                </div>
                <div className='scheduler-more-button-logout-section' onClick={onLogout}>
                    <div className='scheduler-more-button-top-button-item logout'>
                        <img 
                            src={require("assets/svg/popup_sidebar_more_logout.svg")}
                            alt="more_logout"
                            className='more-popup-icon logout'
                        />
                        Logout
                    </div>
                </div>
            </div>
        </div>
    );
};

export default More;

// popup_sidebar_more_faq.svg
// popup_sidebar_more_help.svg
// popup_sidebar_more_about.svg