import { trackCreateGroup, trackGroupMemberCount, trackJoinGroup, trackLeaveGroup } from "analytics/group";
import { addNewGroup, changeRole, removeGroup, removeMember, setActiveGroup, setCourseRankList, setGroupList, setGroupMembers, setSubjectRankList } from "redux/actions/groupActions/actions";
import { setPopup } from "redux/actions/uiActions/actions";
import Request from "utils/Request";

export const createNewGroup = (name, config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: "POST",
            url: "/groupStudy/createGroup",
            data: { courseId, name },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(addNewGroup(data?.group));
            dispatch(setActiveGroup(data?.group));
            await setActiveLocalGroup(data?.group?.groupUniqueId);
            dispatch(setPopup(7, { title: "Group Successfully Created" }));
            trackCreateGroup();
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const joinGroup = (groupUniqueId, config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: "POST",
            url: "/groupStudy/joinGroup",
            data: { courseId, groupUniqueId },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(addNewGroup(data?.group));
            dispatch(setActiveGroup(data?.group));
            await setActiveLocalGroup(data?.group?.groupUniqueId);
            trackJoinGroup();
            return true;
        }
        dispatch(setPopup(10, { text: message }));
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getGroupMembers = (config) => async (dispatch, getState) => {
    try {
        const groupId = getState().group.activeGroup?.id;
        if (!groupId) return false;
        const response = await Request({
            method: "GET",
            url: "/groupStudy/groupMembers",
            params: { groupId },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(setGroupMembers(data?.memberList ?? []));
            trackGroupMemberCount(data?.memberList?.length);
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const editGroup = (name, config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: "POST",
            url: "/groupStudy/editGroup",
            data: { name },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getUserGroups = (config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: "GET",
            url: "/groupStudy/listUserGroups",
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(setGroupList(data?.list ?? []));
            const currGroup = data?.list?.filter((item) => item?.courseId === courseId)?.[0];
            dispatch(setActiveGroup(currGroup));
            setActiveLocalGroup(currGroup?.groupUniqueId ?? "");
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getGroupRankList = (config) => async (dispatch, getState) => {
    try {
        const groupId = getState().group.activeGroup?.id;
        if (!groupId) return false;
        const response = await Request({
            method: "GET",
            url: "/groupStudy/rankList",
            params: { groupId },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(setCourseRankList(data?.courseRankList ?? []));
            dispatch(setSubjectRankList(data?.subjectRankList ?? []));
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const leaveGroup = (config) => async (dispatch, getState) => {
    try {
        const groupId = getState().group.activeGroup?.id;
        const response = await Request({
            method: "DELETE",
            url: "/groupStudy/leaveGroup",
            data: { groupId },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 204) {
            dispatch(removeGroup(groupId));
            trackLeaveGroup();
            await setActiveLocalGroup("");
            return true;
        }
        setTimeout(() => dispatch(setPopup(10, { text: message })), 500);
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const changeUserRole = (selectedUser, role, config) => async (dispatch, getState) => {
    try {
        const user = getState().user.userInfo;
        const groupId = getState().group.activeGroup?.id;
        const response = await Request({
            method: "PUT",
            url: "/groupStudy/changeRole",
            data: { groupId, memberId: selectedUser?.memberId, role },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(changeRole(selectedUser?.userId, role));
            if (role === "leader") {
                dispatch(changeRole(user?.id, "admin"));
            }
            return true;
        }
        dispatch(setPopup(10, { text: message }));
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const removeFromGroup = (userId, config) => async (dispatch, getState) => {
    try {
        const groupId = getState().group.activeGroup?.id;
        const response = await Request({
            method: "DELETE",
            url: "/groupStudy/removeMember",
            data: { groupId, userIdToRemove: userId },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 204) {
            dispatch(removeMember(userId));
            dispatch(setPopup(10, { text: message, snackType: "success" }));
            return true;
        }
        dispatch(setPopup(10, { text: message }));
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const setActiveLocalGroup = (uuid) => {
    localStorage.setItem("groupId", uuid);
};

export const getActiveLocalGroup = () => {
    return localStorage.getItem("groupId");
};
