import {
    SETSURVEYQUESTIONS,
    SETSURVEYDATA,
} from '../actions/surveyActions/actionType';
import {
    LOGOUT
} from '../actions/authActions/actionType';

const initialState = {
    surveyQuestions: [],
    surveyResponse: [],
    isSurveyComplete: false
};

const surveyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETSURVEYQUESTIONS:
            return {
                ...state,
                surveyQuestions: action.payload,
            };
        case SETSURVEYDATA:
            return {
                ...state,
                surveyResponse: action.payload.surveyResponse,
                isSurveyComplete: action.payload.isSurveyComplete,
            };
        case LOGOUT:
            return {
                ...state,
                surveyResponse: [],
                isSurveyComplete: false,
            }    
        default:
            return state;
    }
};

export default surveyReducer;