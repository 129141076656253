import mixpanel from 'mixpanel-browser';

export const identifyUser = async (userId, data) => {
    let user = {
        mobile_no: data?.mobileNo,
        full_name: data?.fullName,
        email: data?.email,
    };

    window.analytics.identify(userId, user);
    // userId && window.Moengage.add_unique_user_id(userId);
    if (userId) {
        mixpanel.identify(userId);
        mixpanel.people.set('mobileNo', data?.mobileNo);
        mixpanel.people.set('$email', data?.email);
        mixpanel.people.set('email', data?.email);
        mixpanel.people.set('name', data?.fullName);
        mixpanel.people.set('$moengage_user_id', data?.mobileNo);
    }
    window.Intercom('boot', {
        app_id: 'b7y62yf5',
        custom_launcher_selector: '.chatbot-action',
        user_id: userId ? userId : data?.id,
        name: data?.fullName,
        email: data?.email,
    });
};

export const trackEvent = (eventName, data) => {
    window.analytics.track(eventName, { ...data });
};

export const trackNewEvent = (eventName, data) => {
    const courseId = localStorage.getItem('course');
    mixpanel.track(eventName, { ...data, Course_Id: courseId });
};

export const trackLink = (linkRef, eventName, data) => {
    window.analytics.trackLink(linkRef, eventName, { ...data });
};

export const trackPage = () => {
    window.analytics.page();
};
