import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { getActiveLocalGroup } from "services/groupStudy";

const NoGroupRoute = ({ path, Component, name, ...rest }) => {
    const token = useSelector((store) => store.auth.token);
    const activeGroup = useSelector((store) => store.group.activeGroup);
    const groupId = getActiveLocalGroup();

    if (!token) return <Redirect to="/" />;

    return groupId || activeGroup?.id ? (
        <Redirect to={`/group-study/${groupId || activeGroup?.groupUniqueId}`} />
    ) : (
        <Route render={(props) => <Component {...props} name={name} />} path={path} exact {...rest} />
    );
};

export default NoGroupRoute;
