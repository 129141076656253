import { trackEvent, trackNewEvent } from './base';

export const trackRewardsPopup = (xpTotal, level) => {
    // trackNewEvent('Rewards Event', {
    //     Xp_Total: xpTotal,
    //     Level: level,
    // });
};

export const trackRewardsShare = (source) => {
    // trackNewEvent('Rewards Event', {
    //     share: true,
    //     source: source,
    // });
};

export const trackSubjectSelection = (subjectName) => {
    trackEvent('Stats', {
        subject_name: subjectName,
    });
};

export const trackScreenTime = (screenTime) => {
    trackEvent('Stats', {
        screen_time: screenTime,
    });
};

export const trackScrollPercentage = (scrollPercentage) => {
    trackEvent('Stats', {
        scroll_percentage: scrollPercentage,
    });
};

export const trackStreakMonth = (month, year) => {
    trackEvent('Stats', {
        streak_view: true,
        month: month,
        year: year,
    });
};

export const trackRewardsCollect = (totalXp, collectedXp, level) => {
    trackNewEvent('Rewards Event', {
      Collect_Button_Click: true,
      Xp_Total: totalXp,
      Xp_Collected: collectedXp,
      Level_Count: level,
    });
  };
