import React, { useEffect } from 'react';
import Lottie from 'react-lottie';

import ProgressIndicator from 'Components/ProgressIndicator/ProgressIndicator';
import OrangeLiquid from 'assets/gif/liquid-orange.gif';
import Liquid from 'assets/gif/liquid.gif';
import './RevisionIntro.scss';

const RevisionIntro = ({ onClosePopup }) => {
    useEffect(() => {
        const popupContainer = document.querySelector(".popup");
        popupContainer.className += " introPopUp";

        return (() => {
            popupContainer.classList.remove("introPopUp");
        })
    }, [])

    const defaultOptions = {
        loop: true,
        autoplay: true,
    };

    return (
        <div className="intro-popup-wrapper">
            <div className="anim">
                <Lottie options={{ ...defaultOptions, animationData: require('assets/animations/revision-hand.json') }} height={70} width={70} />
            </div>
            <div className="top-popup-section">
                <h2 className="popup-heading">Revise Completed Topics with Examarly!</h2>
                <p className="popup-description">You can now revise older topics by reattempting completed tests to ensure you NEVER forget what you learn!</p>
            </div>
            <div className="inner-popup-section">
                <div className="row">
                    <div className="circle-wrapper">
                        <ProgressIndicator percentage={100} state="completed" type="subtopic" />
                        <img className="c-icon" src={require('assets/svgIcons/complete.svg')} />
                    </div>
                    <p className="text">GREEN - You’ve completed the topic.</p>
                </div>
                <div className="row">
                    <div className="circle-wrapper">
                        <img src={OrangeLiquid} className="liquid" />
                        <img className="c-icon" src={require('assets/svgIcons/complete.svg')} />
                    </div>
                    <p className="text">ORANGE - Your retention levels are decreasing. You will need to revise the topic soon.</p>
                </div>
                <div className="row">
                    <div className="circle-wrapper">
                        <img src={Liquid} className="liquid" />
                    </div>
                    <p className="text">RED - Your retention levels are critically low. Time to revise and retake the test.</p>
                </div>
                <p className="sub-text">The test unlocks only at the last stage.</p>

                <button className="btn-primary" onClick={onClosePopup}>
                    Let's explore
                </button>
            </div>
        </div>
    );
};

export default RevisionIntro;
