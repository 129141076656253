import {
    SETCURRENTAFFAIRS,
    SETQUIZLIST
} from '../actions/currentAffairsActions/actionType';
import {RESETCOURSE} from '../actions/uiActions/actionType';
import {
    LOGOUT
} from '../actions/authActions/actionType';

const initialState = {
    updatedAt: null,
    currentAffairs: null,
    page: 0,
    quizList: null
};

const currentAffairsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETCURRENTAFFAIRS:
            return {
                ...state,
                currentAffairs: action.payload,
                page: state.page + 1
            };

        case SETQUIZLIST:
            return {
                ...state,
                quizList: action.payload,
            };

        case RESETCOURSE:
            return initialState;

        case LOGOUT:
            return {
                ...state,
                currentAffairs: null,
                page: 0
            };

        default:
            return state;
    }
};

export default currentAffairsReducer;