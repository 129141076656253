import React from "react";
import "./EarlyBird.scss";
import "./Congratulations";

const Options = ({ earlyBirdOptions }) => {
  return (
    <div className="early-bird-options">
      <div className="early-bird-options-signage">
        <img
          className="early-bird-options-image"
          src={earlyBirdOptions.image}
        />
      </div>
      <div className="early-bird-options-content">
        <div className="early-bird-options-title">{earlyBirdOptions.title}</div>
        <div className="early-bird-options-description">
          {earlyBirdOptions.description}
        </div>
      </div>
    </div>
  );
};

const OptionsForCongratulations = ({ earlyBirdOptions }) => {
  return (
    <div className="early-bird-congratulations-options">
      <div className="early-bird-congratulations-options-signage">
        <img
          className="early-bird-congratulations-options-image"
          src={earlyBirdOptions.image}
        />
      </div>
      <div className="ealry-bird-congratulations-options-content">
        <div className="early-bird-congratulations-options-title">
          {earlyBirdOptions.title}
        </div>
        <div className="early-bird-congratulations-options-description">
          {earlyBirdOptions.description}
        </div>
      </div>
    </div>
  );
};

export { Options, OptionsForCongratulations };
