import { SETLOADING, UPDATEAPITOKENS, SETPOPUPID, SETAPPRECIATIONPOPUPID, SETTOURINFO, INCREAMENTTOURSTEP, SETGUESTPROMPT, ENDTOUR, SETPATH } from "../actions/uiActions/actionType";

const initalState = {
    isLoading: false,
    guestPrompt: false,
    activeApiTokens: [],
    popupId: null,
    popupData: {}, 
    appreciationId: null,
    isTourActive: false,
    tourPath: null,
    tourStep: null,
    isMobile: document.documentElement.clientWidth < 767,
    historyPath: "subtopic"
};

const uiReducer = (state = initalState, action) => {
    switch (action.type) {
        case SETLOADING:
            return {
                ...state,
                isLoading: !state.isLoading,
            };

        case UPDATEAPITOKENS:
            return {
                ...state,
                activeApiTokens: action.payload,
            };

        case SETPOPUPID:
            return {
                ...state,
                popupId: action.payload.id,
                popupData: action.payload,
            };

        case SETAPPRECIATIONPOPUPID:
            return {
                ...state,
                appreciationId: action.payload,
            };

        case SETGUESTPROMPT:
            return {
                ...state,
                guestPrompt: action.payload,
            };

        case SETTOURINFO:
            return {
                ...state,
                // isTourActive: true,
                // tourPath: action.payload,
                // tourStep: 0
            };

        case INCREAMENTTOURSTEP:
            return {
                ...state,
                tourStep: state.tourStep + 1,
            };

        case ENDTOUR:
            return {
                ...state,
                isTourActive: false,
                tourPath: null,
                tourStep: null,
            };
        case SETPATH:
            return {
                ...state,
                historyPath: action.payload
            }
            
        default:
            return { ...state };
    }
};

export default uiReducer;
