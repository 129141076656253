import {trackNewEvent} from './base';

export const trackStreakData = (current, longest) => {
  trackNewEvent('Streak Event', {
    Current_Streak: current,
    Longest_Streak: longest,
  });
};

export const trackLatestStreak = date => {
  trackNewEvent('Streak Event', {
    Last_Streak_Date: date,
  });
};
