import {
  SETASSESSMENTDATA,
  SETRESPONSE,
  SETRESULT,
  UPDATERESULT,
  SETALREADYTAKEN,
  INITIALIZEASSESSMENTDATA,
  SETSHOWANSWERS,
  SKIPASSESSMENT,
  CLEARDATA,
  SETREWARDS,
  CLEARREWARDS,
} from "../actions/assessmentActions/actionType";

import { LOGOUT } from "../actions/authActions/actionType";

const initialState = {
  quizId: null,
  questions: [],
  solution: null,
  response: null,
  rewards: null,
  result: null,
  alreadyTaken: {},
  quizDetails: null,
  showAnswers: false,
  assessmentSkipped: false,
};

const currentAffairsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETASSESSMENTDATA:
      return {
        ...state,
        questions: action.payload.questions,
        quizId: action.payload.id,
        quizDetails: action.payload.details,
        solution: null,
        result: null,
        response: null,
      };

    case SETRESPONSE:
      return {
        ...state,
        response: action.payload,
      };
    case SETREWARDS:
      return {
        ...state,
        rewards: action.payload,
      };

    case SETRESULT:
      return {
        ...state,
        result: action.payload.result,
        solution: action.payload.solution,
      };

    case SETALREADYTAKEN:
      return {
        ...state,
        alreadyTaken: action.payload,
      };

    case INITIALIZEASSESSMENTDATA:
      return {
        ...state,
        ...action.payload,
      };

    case SETSHOWANSWERS:
      return {
        ...state,
        showAnswers: action.payload,
      };

    case SKIPASSESSMENT:
      return {
        ...state,
        assessmentSkipped: true,
      };

    case CLEARDATA:
      return {
        ...state,
        quizId: null,
        questions: [],
        solution: null,
        response: null,
        result: null,
        quizDetails: null,
      };
    case CLEARREWARDS:
      return {
        ...state,
        rewards: null,
      };

    case UPDATERESULT:
      return {
        ...state,
        result: action.payload,
      };

    case LOGOUT:
      return {
        ...state,
        assessmentSkipped: false,
      };

    default:
      return state;
  }
};

export default currentAffairsReducer;
