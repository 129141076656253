export const PopupData = [
  {
    title: 'All the best!',
    description:
      'Please read the instructions and complete the test to know the weaker areas of your preparation',
    animationPath: require('assets/animations/thumbs-up.json'),
    confettiAnimation: false,
  },
  {
    title: 'Congrats!',
    description:
      'On completing assessment test. You have been rewarded with 20',
    animationPath: require('assets/animations/coins.json'),
    confettiAnimation: false,
  },
  {
    title: 'Wohoooo!',
    titleColor: '#48BF84',
    description:
      'You have scored ',
    animationPath: require('assets/animations/emoji-wow.json'),
    confettiAnimation: false,
    score: true,
  },
  {
    title: 'Average!',
    titleColor: '#FF993F',
    description:
      'Your score is better than 40% of the candidates! Continue with our platform to improve your scores.',
    animationPath: require('assets/animations/emoji-average.json'),
    confettiAnimation: false,
  },
  {
    title: 'Oops!',
    titleColor: '#FC5D73',
    description:
      'You need to improve.\n Continue with our platform to improve your scores.',
    animationPath: require('assets/animations/emoji-sad.json'),
    confettiAnimation: false,
  },
  {
    title: 'Welcome onboard!',
    description:
      'We are happy that you are with us, now tight your seat belt and ready to improve your score.',
    animationPath: require('assets/animations/welcome.json'),
    confettiAnimation: true,
  },
  {
    title: 'Trial Activated!',
    titleColor: '#FC5D73',
    description:
      'Take assessment test to know about your preparation and work on your weakness.',
    animationPath: require('assets/animations/unlock.json'),
    confettiAnimation: false,
  },
  {
    title: 'Chapter completed!',
    description:
      'You are the right path, keep going!',
    animationPath: require('assets/animations/check.json'),
    confettiAnimation: true,
  },
  {
    title: 'Make this streak count',
    description:
      'Remember your streak will reset if you don\'t practice tomorrow',
    animationPath: require('assets/animations/flame.json'),
    confettiAnimation: true,
  },
  {
    title: 'Longest streak!',
    titleColor: '#FD755E',
    description:
      'You won 50 by beating your longest streak, keep it up with the good work.',
    animationPath: require('assets/animations/flame.json'),
    confettiAnimation: true,
  },
  {
    title: 'Excellent!',
    description:
      'You are the right path, your score improved by 5% which increased your chances of selection.',
    animationPath: require('assets/animations/badge.json'),
    confettiAnimation: true,
  },
  {
    title: 'Yayyy!',
    titleColor: '#FD755E',
    description: 'You have just asked your first doubt. This student-mentor relationship is official now! How much do you like us?',
    animationPath: require('assets/animations/doubt-solved.json'),
    confettiAnimation: true,
    rating: true,
    open: true
  },
  {
    title: 'Daily Task Completed',
    description: 'Congratulations! you have completed the tasks for today. Don\'t stop here, keep going and complete your syllabus faster!',
    animationPath: require('assets/animations/check.json'),
    confettiAnimation: true,
  },
  {
    title: 'All weak topics completed',
    description: 'Congratulations! You’ve covered all your weak areas. Take the assessment test to start your revision.',
    animationPath: require('assets/animations/check.json'),
    confettiAnimation: true,
    assessment: true,
    open: true
  },
  {
    title: 'Rate Us',
    titleColor: '#FD755E',
    description: 'Please rate us to get 50 coins',
    animationPath: require('assets/animations/doubt-solved.json'),
    rating: true,
    open: true
  }
];
