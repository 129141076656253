import React from 'react';
import Guest from 'Components/Guest/Guest';
import './GuestPrompt.scss';

const GuestPrompt = () => {
    return (
        <div className="guest-popup-container">
            <Guest />
        </div>
    );
};

export default GuestPrompt;