import React from "react";
import "./PaymentFailed.scss";
import { cloneDeep } from "lodash";
import Lottie from "react-lottie";
import { useSelector, useDispatch } from "react-redux";
import { setPopup } from "redux/actions/uiActions/actions";
import BirdFailure from "assets/animations/BirdFailure.json";

const PaymentFailed = () => {
  const dispatch = useDispatch();
  const handleSubscribeButton = () => {
    localStorage.removeItem("paymentsOrderId");
    dispatch(setPopup(16));
  };
  const handleClickButton = () => {
    localStorage.removeItem("paymentsOrderId");
    dispatch(setPopup(null));
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
  };

  return (
    <div className="early-bird-payment-failed-container">
      <div className="early-bird-payment-failed-logo">
        <img src={require("assets/svg/logo-examarly.svg")} />
      </div>
      <div className="early-bird-payment-failed-lottie">
        <Lottie
          options={{
            ...defaultOptions,
            animationData: cloneDeep(BirdFailure),
          }}
        />
      </div>
      <div className="early-bird-payment-center-container">
        <div className="early-bird-payment-failed-first-line animate">
          PAYMENT FAILED
        </div>
        <div className="early-bird-payment-failed-second-line animate">
          Your transaction couldn’t be processed!
          <br /> Please try again.
        </div>
      </div>
      <div className="early-bird-payment-failed-footer animate">
        <div className="early-bird-payment-failed-footer-dark-button">
          <button
            className="early-bird-dark-btn"
            onClick={() => {
              handleSubscribeButton();
            }}
          >
            TRY AGAIN
          </button>
        </div>
        <div className="early-bird-payment-failed-footer-light-button">
          <button
            className="early-bird-light-btn"
            onClick={() => {
              handleClickButton();
            }}
          >
            Not now
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
