import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '../utils/Routes';

/**
 * 
 * @param  {...any} args Takes in all the pathnames that needs to be prefetched. 
 * @returns 
 */
const useRedirect = (...args) => {
    const history = useHistory();

    useEffect(() => {
        args.forEach(path => {
            Routes.forEach(route => {
                if (route.path.includes(`/${path}`)) {
                    route.component.preload();
                }
            })
        })
    }, [])

    /**
     * 
     * @param {*} path Page to redirect to.
     */
    const redirect = (path, replace = false) => {
        if (replace) {
            history.replace(`/${path}`);
        } else {
            history.push(`/${path}`);
        }
    }

    return redirect;
}

export default useRedirect;