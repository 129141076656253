import { trackRewardsShare } from 'analytics/stats';
import { toBlob } from 'html-to-image';
import React from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import './ShareCards.scss';
const data = [
    {
        topic: "I'm on",
        subTopic: 'day streak!',
        img: require('assets/svgIcons/streak-share.svg'),
    },
    {
        topic: "I've earned",
        subTopic: 'on Examarly',
        img: require('assets/svgIcons/diamond-share.svg'),
    },
    {
        topic: "I've reached",
        subTopic: 'on Examarly',
        img: require('assets/svgIcons/achievement-share.svg'),
    },
    {
        topic: "I've completed a",
        subTopic: 'on Examarly',
        img: require('assets/svgIcons/popper-share.svg'),
    },
];
const ShareCards = ({ type, source }) => {
    const ref = useRef(null);
    const rewards = useSelector((store) => store.assessment.rewards);
    const statDetail = useSelector((store) => store.stat.statDetail);
    const streakDetails = useSelector((store) => store.stat.streak);

    const getData = () => {
        if (type === 'xp') {
            return data[1];
        } else if (type === 'topic') {
            return data[3];
        } else if (type === 'level') {
            return data[2];
        } else if (type === 'streak') {
            return data[0];
        }
    };

    const getCenterText = () => {
        if (type === 'xp') {
            return (rewards?.xpEarned ?? 0) + ' XP';
        } else if (type === 'topic') {
            return 'Topic';
        } else if (type === 'level') {
            return 'Level ' + (statDetail?.level ?? 0);
        } else if (type === 'streak') {
            return streakDetails?.currentStreak ?? 0;
        }
    };

    const currData = getData();

    const handleShare = async () => {
        trackRewardsShare(source);
        const file = await toBlob(ref.current);
        const data = {
            files: [
                new File([file], 'image.png', {
                    type: file?.type,
                }),
            ],
        };
        try {
            if (!navigator.canShare(data)) {
                console.error("Can't share");
            }
            await navigator.share(data);
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <>
            <button className="share" onClick={handleShare}>
                <img src={require('assets/svgIcons/u_share-alt.svg')} alt="" />
                Share Progress
            </button>

            <div className="card-hide-container">
                <div className={`card-container ${type}`} ref={ref}>
                    <p className="card-topic">{currData?.topic}</p>
                    <p className="card-value">{getCenterText()}</p>
                    <p className="card-subtopic">{currData?.subTopic}</p>
                    <img className="card-img" src={currData?.img} alt="" />
                </div>
            </div>
        </>
    );
};

export default ShareCards;
