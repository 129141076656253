import {
    SETSURVEYDATA,
} from './actionType';

/**
 * checks localstorage for survey data on first initialization
 * @returns 
 */
export const initializeSurveyData = () => dispatch => {
    let data = localStorage.getItem('survey-data');
    if (data) {
        dispatch({
            type: SETSURVEYDATA,
            payload: JSON.parse(data)
        })
    }
}

/**
 * Calls this function everytime user clicks on next when giving survey
 * 1. set users answers to all the question
 * 2. updates survey data in localstorage to keep count of the number of questions answered
 * @param {*} isComplete 
 * @param {*} answer 
 * @returns 
 */
export const setSurveyData = (isComplete, answer) => (dispatch, getState) => {
    let surveyData = getState().guest.surveyData;
    let updatedData = { isComplete, surveyAnswers: [...surveyData.surveyAnswers, answer] };

    dispatch({
        type: SETSURVEYDATA,
        payload: updatedData
    });

    localStorage.setItem('survey-data', JSON.stringify(updatedData));
}

export const checkSurveyAssessment = () => (dispatch, getState) => {
    const assessmentData = getState().assessment;
    const surveyResponse = getState().survey.surveyResponse;
    let userData = {}

    userData.surveyResponse = surveyResponse;

    if (assessmentData?.result) {
        userData.testIds = assessmentData?.quizDetails?.testId;
        userData.weakSubjects = [Object.keys(assessmentData?.result?.moduleResult)?.[0]];
    }

    return userData;
}