import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRedirect from 'customhooks/useRedirect';
import { setPopup } from 'redux/actions/uiActions/actions';
import './PlanSuccess.scss';
import { lazyWithPreload } from 'utils/Functions';

const PlanSuccess = () => {
    const user = useSelector(store => store.user.userInfo);
    const dispatch = useDispatch();

    const redirect = useRedirect();

    const onCloseClick = () => {
        dispatch(setPopup(null));
    }

    const onViewPlans = () => {
        redirect('store/plan');
    }

    return (
        <div className="success-container">
            <div className="image-wrapper">
                <img src={require('assets/svgIcons/success-scale.svg')} />
            </div>
            <p className="title">Congrats</p>
            <p className="description">Your {user?.freeTrailDays ?? 0} day free trail period is activated.</p>
            <div className="button-container">
                <button className="btn-primary" onClick={onViewPlans} >View Plans</button>
                <p className="close-text" onClick={onCloseClick}>Close</p>
            </div>
        </div>
    );
};

export default PlanSuccess;