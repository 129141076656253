import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getActiveLocalGroup, getGroupMembers, getGroupRankList } from "services/groupStudy";

const GroupRoute = ({ path, Component, name, ...rest }) => {
    const token = useSelector((store) => store.auth.token);
    const activeGroup = useSelector((store) => store.group.activeGroup);
    const groupMembers = useSelector((store) => store.group.groupMembers);
    const courseRankList = useSelector((store) => store.group.courseRankList);
    const dispatch = useDispatch();
    const groupId = getActiveLocalGroup();

    useEffect(() => {
        if (activeGroup?.id) {
            groupMembers?.length === 0 && dispatch(getGroupMembers());
            courseRankList?.length === 0 && dispatch(getGroupRankList({ loader: true }));
        }
    }, [activeGroup]);

    if (!token) return <Redirect to="/" />;

    return groupId || activeGroup?.id ? <Route render={(props) => <Component {...props} name={name} />} path={path} exact {...rest} /> : <Redirect to="/group-study" />;
};

export default GroupRoute;
