import { trackGroupShareCopy, trackGroupShareSocial } from "analytics/group";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPopup } from "redux/actions/uiActions/actions";
import { copyTextToClipboard } from "utils/Functions";
import "./AddFriends.scss";

const AddFriends = ({ onClosePopup }) => {
  const popupData = useSelector((store) => store.ui.popupData);
  const activeGroup = useSelector((store) => store.group.activeGroup);
  const dispatch = useDispatch();

  const onCopyGroupId = () => {
    trackGroupShareCopy();
    copyTextToClipboard(activeGroup?.groupUniqueId);
    dispatch(
      setPopup(10, {
        text: "Copied to clipboard",
        snackType: "success",
        time: 2000,
      })
    );
  };

  const onShare = (social) => {
    trackGroupShareSocial();
    const url = `https://examarly.com/groups?groupId=${activeGroup?.groupUniqueId}`;
    const message = `Click on this link: "${url}" to join my study group(id : ${activeGroup?.groupUniqueId}) on Examarly and compete together.`;

    switch (social) {
      case "EMAIL":
        return window.open(`mailto://?body=${message.replace("=", "%3D")}`);
      case "WHATSAPP":
        return window.open(`https://api.whatsapp.com/send?text=${message}`);
      case "SMS":
        return window.open(`sms:?&body=${message}`);
      default:
        return null;
    }
  };

  return (
    <div className="add-friend-popup">
      <div onClick={onClosePopup} className="cross">
        <img src={require("assets/svgIcons/cross.svg")} />
      </div>
      <div className="tick-wrapper">
        <img src={require("assets/svgIcons/circle-tick.svg")} />
      </div>
      <p className="title">
        {popupData?.title ?? "Add friends by sharing Group ID"}
      </p>
      <p className="text">
        Share your group ID{" "}
        <span className="group-uid">{activeGroup?.groupUniqueId}</span> to add
        your friends
      </p>
      <button onClick={onCopyGroupId} className="btn-primary">
        Copy
      </button>
      <p className="gray-text">OR</p>
      <p className="share-text">Share via</p>

      <div className="social-wrapper">
        <div onClick={() => onShare("WHATSAPP")} className="circle">
          <img src={require("assets/svgIcons/whatsapp.svg")} />
        </div>
        <div onClick={() => onShare("EMAIL")} className="circle">
          <img src={require("assets/svgIcons/gmail.svg")} />
        </div>
        <div onClick={() => onShare("SMS")} className="circle">
          <img src={require("assets/svgIcons/msg.svg")} />
        </div>
      </div>

      {/* <div className="more-wrapper">
                More
                <img src={require("assets/svgIcons/arrow-right2.svg")} />
            </div> */}
    </div>
  );
};

export default AddFriends;
