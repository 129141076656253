import { trackEvent, trackNewEvent } from './base';

export const trackSignupSource = ({authType, login}) => {
    trackEvent('Signup Event', {
      auth_type: authType,
    });
    trackNewEvent('Signup Event', {
      Auth_Type: authType === 'MOBILE' ? 'OTP Login' : authType,
      Login: login,
    });
  };
  
  export const trackLoginSource = ({authType, signup, Login_Click}) => {
    trackEvent('Login Event', {
      auth_type: authType,
    });
    trackNewEvent('Login Event', {
      Auth_Type: authType === 'MOBILE' ? 'OTP Login' : authType,
      Signup: signup,
      Login_Click,
    });
  };
  
  export const trackOnboarding = ({
    Get_Started,
    Hear_About_Us,
    Exam_Selection,
    Exam_Year,
    Knowledge_Level,
    Complete = false,
  }) => {
    trackNewEvent('Onboarding Event', {
      Get_Started,
      Hear_About_Us,
      Exam_Selection,
      Exam_Year,
      Knowledge_Level,
      Complete,
    });
  
    if (Hear_About_Us) {
      trackSurveySource(Hear_About_Us)
    }
    if (Exam_Selection) {
        trackSurveyExam(Exam_Selection)
    }
    if (Exam_Year) {
        trackSurveyExamDate(Exam_Year)
    }
    if (Knowledge_Level) {
        trackSurveyKnowledge(Knowledge_Level)
    }
  };

export const trackSurveyExam = (examName) => {
    trackEvent('Exam Select', {
        exam_name: examName
    });
};

export const trackSurveySource = (sourceName) => {
    trackEvent('How Did You Find Us', {
        option_name: sourceName
    });
};

export const trackSurveyExamDate = (year) => {
    trackEvent('Exam Attempt Date', {
        year: year
    });
};

export const trackSurveyKnowledge = (knowledgeLevel) => {
    trackEvent('Knowledge', {
        knowledge_level: knowledgeLevel
    });
};

export const trackOnboardngTestStart = () => {
    trackEvent('Onboarding Test Start', {
        started: true
    });
};

export const trackOnboardingTestButton = (continueClicked = false) => {
    trackEvent('Onboarding Test Options', {
        continue: continueClicked,
        take_later: !continueClicked
    });
};

export const trackOnboardngTestExited = () => {
    trackEvent('Onboarding Test Options', {
        exited: true
    });
};

export const trackOnboardngTestScoreAction = (viewAnswer = false) => {
    trackEvent('Onboarding Test Score Page', {
        view_answers: viewAnswer,
        continue: !viewAnswer
    });
};

export const trackWhereToStart = (subject) => {
    trackEvent('Where To Start', {
        subject_selection: subject
    });
};

export const trackOnboardingStart = (getStarted = false) => {
    trackEvent('Onboarding Start', {
        get_started: getStarted,
        login: !getStarted
    });
};

export const trackOnboardingFinished = () => {
    trackEvent('Onboarding Finished', {
        is_finished: true
    });
};

export const trackLandingPageSections = (section) => {
    trackEvent('Landing Page', {
        section_in_view: section
    });
};

export const trackIfAppDownloadClicked = () => {
    trackEvent('Landing Page', {
        download_clicked: true
    });
};

export const trackOtpNumberChanged = () => {
    trackEvent('Otp Page Actions', {
        number_changed: true
    });
};

export const trackOtpResend = () => {
    trackEvent('Otp Page Actions', {
        otp_resend: true
    });
}