import React from 'react';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';

import useRedirect from 'customhooks/useRedirect';
import './RewardsIntro.scss';

const RewardsIntro = ({ onClosePopup }) => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
    };

    const statDetail = useSelector((store) => store.stat.statDetail);

    const redirect = useRedirect();

    const onViewAchievements = () => {
        redirect('stats');
    };

    return (
        <div className="intro-popup-wrapper">
            <div className="anim">
                <img src={require(`assets/svgIcons/badge.svg`)} />
            </div>
            <div className="top-popup-section">
                <h2 className="popup-heading">Congratulations, you've aced it!</h2>
                <p className="popup-description">
                    You've earned {statDetail?.xpEarned} XP and moved up in the league to Level {statDetail?.level} ! Ready for the next big leap?
                </p>
            </div>
            <div className="inner-popup-section">
                <Lottie options={{ ...defaultOptions, animationData: require('assets/animations/level-up-intro.json') }} height={255} width={290} />

                <button className="btn-primary" onClick={onViewAchievements}>
                    View Achievements
                </button>
            </div>
        </div>
    );
};

export default RewardsIntro;
