import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { transformDate } from 'utils/Functions'
import './Streak.scss';

const Streak = ({ alignment = '' }) => {
    const userStreak = useSelector(store => store.stat.streakList);
    const [startDate, setStartDate] = useState(new Date());
    const [dateArrays, setDateArrays] = useState([]);

    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    useEffect(() => {
        let date = new Date();
        date.setDate(date.getDate() - new Date().getDay());
        date.setHours(0, 0, 0, 0);
        setStartDate(new Date(date));
    }, []);

    useEffect(() => {
        const newDateArrays = getDates(
            new Date(startDate),
            new Date(startDate).addDays(6),
        );
        setDateArrays(newDateArrays?.reverse());
    }, [startDate]);

    Date.prototype.addDays = function (days) {
        var dat = new Date(this.valueOf());
        dat.setDate(dat.getDate() + days);
        return dat;
    };

    Date.prototype.subDays = function (days) {
        var dat = new Date(this.valueOf());
        dat.setDate(dat.getDate() - days);
        return dat;
    };

    const getDates = (startDate, stopDate) => {
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push(currentDate);
            currentDate = currentDate.addDays(1);
        }
        return dateArray
    };

    const checkIfActive = item => {
        let transformedDate = transformDate(item)
        const isActive = userStreak.includes(transformedDate);
        if (isActive) {
            return 'complete';
        }
        return '';
    };


    return (
        <div className={`streak-container ${alignment}`}>
            <div className="topsection-wrapper">
                <h3>Streaks</h3>
                <div className="subhead-wrapper">
                    <p>complete 7 days to get 100</p>
                    <img src={require('assets/svgIcons/coin.svg')} />
                </div>
            </div>
            <div className="progress-component">
                {/* <div className="time-section">
                    <img className="arrow" src={require('assets/svgIcons/arrow.svg')} onClick={onPrevDate} />
                    <div className="week-time">
                        <p className="date-range">{`${dateArrays[0]?.getDate()} ${Months[dateArrays[0]?.getMonth()]} - ${dateArrays[6]?.getDate()} ${Months[dateArrays[6]?.getMonth()]}`}</p>
                    </div>
                    <img className="arrow" src={require('assets/svgIcons/arrow.svg')} onClick={onNextDate} />
                </div> */}
                <div className="day-list">
                    {dateArrays.map((item, index) => (
                        <div className={`day-container ${checkIfActive(item)}`}>
                            <div className="left-day-section">
                                <div className="progress">
                                    <div className="fill"></div>
                                </div>
                                <div className="circle">
                                    <img src={require('assets/svgIcons/tick.svg')} />
                                </div>
                            </div>
                            <div className="right-day-section">
                                <p className="day">{alignment === 'horizontal' ? days[item.getDay()][0] : days[item.getDay()]}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Streak;