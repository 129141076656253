import { SETCOURSESTATDATA, SETSCOREDATA, SETSTATDATA, SETSTREAKDETAILS, SETSTREAKLIST } from '../actions/statActions/actonsType';
import { RESETCOURSE } from '../actions/uiActions/actionType';

const initialState = {
    score: null,
    statDetail: null,
    streak: null,
    streakList: [],
    courseStats: null,
};

const planReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETSCOREDATA:
            return {
                ...state,
                score: action.payload,
            };
        case SETSTATDATA:
            return {
                ...state,
                statDetail: action.payload,
            };
        case SETSTREAKDETAILS:
            return {
                ...state,
                streak: action.payload,
            };
        case SETSTREAKLIST:
            return {
                ...state,
                streakList: action.payload,
            };
        case SETCOURSESTATDATA:
            return {
                ...state,
                courseStats: action.payload,
            };
        case RESETCOURSE:
            return {
                ...state,
                score: null,
            };
        default:
            return state;
    }
};

export default planReducer;
