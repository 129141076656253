import { combineReducers } from "redux";

import uiReducer from "./uiReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import guestReducer from "./guestReducer";
import dataReducer from "./dataReducer";
import currentAffairsReducer from "./currentAffairsReducer";
import assessmentReducer from "./assessmentReducer";
import planningTrackingReducer from "./planningTrackingReducer";
import revisionReducer from "./revisionReducer";
import planReducer from "./planReducer";
import homeReducer from "./homeReducer";
import statReducer from "./statReducer";
import surveyReducer from "./surveyReducer";
import groupReducer from "./groupReducer";
import updateSubscribedPopup from "./earlyBirdReducer";

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  user: userReducer,
  guest: guestReducer,
  data: dataReducer,
  affairs: currentAffairsReducer,
  assessment: assessmentReducer,
  planning: planningTrackingReducer,
  revision: revisionReducer,
  plan: planReducer,
  home: homeReducer,
  updateSubscribedPopup: updateSubscribedPopup,
  stat: statReducer,
  survey: surveyReducer,
  group: groupReducer,
});

export default rootReducer;
