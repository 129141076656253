import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useRedirect from 'customhooks/useRedirect';
import {
    trackTaskClicked,
    trackTaskVisited,
    trackHomeQuizDropdown,
    trackHomePlanningDropdown,
    trackHomePlanningButton,
} from 'analytics/home';
import { trackRevisionVisited } from 'analytics/revision';
import SnackBar from 'Components/SnackBar/SnackBar';
import Task from './Task/Task';
import './DailyTask.scss'
import { addDailyTask, getDailyQuizQuestions } from 'services/home';

export const dailyTask = [
    {
        id: 1,
        taskName: 'Daily current affairs quiz',
        completed: false,
        color: '255,153,63',
        buttonText: 'Attempt Today\'s Quiz',
        description:
            'Your daily dose of current affairs quiz served hot. Attempt these questions to see if you have covered the important articles for today.',
        redirect: 'home/currentaffairs',
        bottomBanner: true,
    },
    {
        id: 2,
        taskName: 'Let\'s do one more!',
        className: 'planning-task',
        completed: false,
        color: '45,193,32',
        buttonText: 'Go to Syllabus',
        description:
            "If you do 2 sub topics a day you can complete the entire syllabus in 100 days!",
    },
];

const DailyTask = () => {
    //state
    const [isActive, setTaskActive] = useState(null);
    const [snackError, setSnackError] = useState(false);

    //redux
    const user = useSelector(store => store.user.userInfo);
    const dailyTasks = useSelector(store => store.home.dailyTasks?.tasks ?? []);
    const dailyCompletedTasks = useSelector(
        store => store.home.dailyTasks?.dailyCompletedTasks ?? [],
    );

    const dispatch = useDispatch();

    const redirect = useRedirect();

    const onTaskClick = (task) => {
        trackTaskClicked(`task-${task?.id}`);
        if (task?.id === isActive) {
            setTaskActive(null);
            taskClickTrack(task, false);
        } else {
            setTaskActive(task?.id);
            taskClickTrack(task, true);
        }
    }

    const taskClickTrack = (task, isOpened) => {
        if (task?.id === 1) {
            trackHomeQuizDropdown(isOpened);
        } else {
            trackHomePlanningDropdown(isOpened);
        }
    }

    const onButtonClick = async (task) => {
        trackTaskVisited(`task-${task?.id}`);
        trackButtonClick(task);
        if (task?.id === 1) {
            const [_, completed] = checkIfComplete(task?.id)
            if(completed){
                redirect('home/quizlist');
                return;
            }
            const response = await dispatch(getDailyQuizQuestions({}, { loader: true }));
            response && redirect('instructions/home/dailytest');
            !response && setSnackError('Quiz not available yet. Please try again after 1pm.')
        }
        else {
            redirect(`syllabus/${user?.weakSubject?.[0]}`);
        }
    }

    const trackButtonClick = (task) => {
        if (task?.id === 1) {
        } else {
            trackHomePlanningButton();
        }
    }

    const onSnackClose = () => {
        setSnackError(false);
    }

    const checkIfComplete = (taskId) => {
        const currTask = dailyTasks.filter(dailyTask => dailyTask.taskIndex == taskId)?.[0];
        const completed =dailyCompletedTasks.filter(taskItem => taskItem.taskId === currTask?.id)?.length > 0;
        return [currTask, completed];
    }

    return (
        <div className="daily-task-container">
            <SnackBar
                error={snackError}
                closeSnack={onSnackClose}
            />
            <div className="heading-wrapper">
                <p className="heading">Your schedule for today</p>
            </div>
            <div className="description-wrapper">
                <p className="description">Complete your daily tasks to fastrack your preparation.</p>
            </div>
            <div className="task-list">
                {dailyTask?.map((task, index) => {
                    const [currTask, completed] = checkIfComplete(task?.id)
                    return (
                        <Task
                            id={task?.id}
                            completed={completed}
                            taskName={currTask?.title}
                            isActive={task?.id === isActive}
                            redirect={currTask?.id === 1 ? task?.redirect : null}
                            onTaskClick={() => onTaskClick(task)}
                            buttonText={task?.buttonText}
                            description={currTask?.description}
                            onButtonClick={() => onButtonClick(task)}
                            bottomBanner={task?.bottomBanner}
                            className={task?.className}
                        />
                    )
                })}
            </div>
        </div>
    );
};

export default DailyTask;