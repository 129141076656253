import React, { useEffect } from "react";
import "./SnackBar.scss";

const SnackBar = ({ error, closeSnack, type = "", alignment = "", time }) => {
    let timeout = null;

    useEffect(() => {
        error && autoClose();
    }, [error]);

    const autoClose = () => {
        timeout = setTimeout(
            () => {
                close();
            },
            time ? time : 5000
        );
    };

    const close = () => {
        clearTimeout(timeout);
        closeSnack();
    };

    return (
        <div className={`snackbar-container ${error ? "show" : "hide"} ${type} ${alignment}`}>
            <div className="content-wrapper">
                {type !== "success" && <img src={require("assets/svgIcons/error.svg")} />}
                <p className="error-text">{error ?? ""}</p>
                <div className="close-container">
                    <p className="close" onClick={close}>
                        CLOSE
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SnackBar;
