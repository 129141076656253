import { SETSUBJECTS, UPDATEDAILYTASK, SETDAILYQUIZ, SETBANNERS } from './actionType';
import { grantCoins } from 'services/user';
import { setAppreciationPopup } from 'redux/actions/uiActions/actions';
import { trackIfTaskCompleted } from 'analytics/home';
import { updateDailyTaskCount } from 'services/home';
import { setStreakDetails, setStreakList } from '../statActions/actions';
import { transformDate } from 'utils/Functions';

export const setSubjects = (data) => (dispatch) => {
    dispatch({
        type: SETSUBJECTS,
        payload: data.reverse(),
    });
};

export const setBanners = (data) => (dispatch) => {
    dispatch({
        type: SETBANNERS,
        payload: data,
    });
};

export const updateTestStatus = (masterSubjectId) => (dispatch, getState) => {
    const subjects = getState().home.subjects;

    subjects.map((subject) => {
        if (subject?.masterSubjectId === masterSubjectId) {
            subject.dailyFreeTest -= 1;
            return subject;
        }
        return subject;
    });

    dispatch({
        type: SETSUBJECTS,
        payload: [...subjects],
    });
};

export const getDailyUserTasks = (data) => (dispatch, getState) => {
    dispatch({
        type: UPDATEDAILYTASK,
        payload: data,
    });
};

export const addNewDailyTask = (task) => (dispatch, getState) => {
    let dailyTasks = getState().home.dailyTasks;
    dailyTasks = [...dailyTasks, task];
    dispatch({
        type: UPDATEDAILYTASK,
        payload: dailyTasks,
    });
};

export const changeTaskStatus = (taskId, status) => (dispatch, getState) => {
    const dailyTasks = getState().home.dailyTasks;
    dailyTasks.map((task) => {
        if (task?.uuid === taskId) task.status = status;
    });
    dispatch({
        type: UPDATEDAILYTASK,
        payload: [...dailyTasks],
    });
};

export const updateDailyTask = () => (dispatch, getState) => {
    let streakDetails = getState().stat.streak;
    let streakList = getState().stat.streakList;
    streakDetails.weeklyTaskCount = (streakDetails?.weeklyTaskCount ?? 0) + 1;
    let currDate = transformDate(new Date());
    if (!streakDetails?.weeklyStreakList?.includes(currDate)) {
      streakList = [...streakList, currDate];
      streakDetails.weeklyStreakList = [
        ...streakDetails?.weeklyStreakList,
        currDate,
      ];
      streakDetails.currentStreak = (streakDetails?.currentStreak ?? 0) + 1;
      streakDetails.weeklyStreakCount =
        (streakDetails?.weeklyStreakCount ?? 0) + 1;
      if (streakDetails?.longestStreak < streakDetails?.currentStreak) {
        streakDetails.longestStreak = streakDetails?.currentStreak ?? 0;
      }
    }
    dispatch(setStreakList(streakList));
    dispatch(setStreakDetails(streakDetails));
  };

export const initializeDailyTask = () => (dispatch, getState) => {
    let dailyTask = getState().home.dailyTasks;
    let storedTask = localStorage.getItem('daily-task');

    if (storedTask) {
        storedTask = JSON.parse(storedTask);
        if (new Date(dailyTask?.taskDate) > new Date(storedTask?.taskDate)) {
            localStorage.removeItem('daily-task');
        } else {
            dispatch({
                type: UPDATEDAILYTASK,
                payload: { ...storedTask },
            });
        }
    }
};

export const setDailyQuiz = (quizData) => (dispatch) => {
    dispatch({
        type: SETDAILYQUIZ,
        payload: quizData,
    });
};
