import { trackEvent, trackNewEvent } from './base';

export const trackCopiedCode = (code) => {
    trackEvent('Referral', {
        copied_code: code
    });
};

export const trackSharedThrough = (socialName) => {
    trackEvent('Referral', {
        shared_through: socialName
    });
};

export const trackNavClicked = (navigationTab) => {
    trackEvent('Hamburger', {
        nav_tab_name: navigationTab
    });
};


export const trackMessage = ({ email, mobileNo, message }) => {
    trackEvent('Help & Support', {
        enter_number: mobileNo,
        enter_email: email,
        query_description: message,
        submit_button: true
    });
};

export const trackSupportLinks = (type) => {
    trackEvent('Help & Support', {
        support_link: type
    });
};

export const trackTrialActivation = (date) => {
    trackEvent('Trial Activation', {
        trial_activation: true,
        trial_end_date: new Date(date).toISOString()
    });
}

export const trackError = (source, error, info) => {
    console.log(error)
    trackEvent('Error Page Try Again', {
        source_of_page: source,
        reason: error.toString(),
        info: JSON.stringify(info)
    });
}

export const trackScreenTime = (screen, time) => {
    trackEvent('Screen Time', { screen, time });
};

//new events
export const trackTabClicked = (page) => {
    trackNewEvent('Side Bar', {
        [page.replaceAll(' ', '_')]: true
    });
}