import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPopup } from 'redux/actions/uiActions/actions';
import { setLocalCourse } from 'services/planningAndTracking';
import "./SelectSubjects.scss";

const SelectSubjects = () => {
    const [activeCourse, setActiveCourse] = useState("1001");
    const activeCourseD = useSelector(store => store.planning.activeCourse);
    const courses = useSelector(store => store.planning.courseList);
    const dispatch = useDispatch();

    useEffect(() => {
        setActiveCourse(activeCourseD)
    }, [activeCourseD])

    const handleSubjectChangeButton = (e, subjectSelected) => {
        e.stopPropagation();
        setActiveCourse(subjectSelected);
    }

    const onApply = () => {
        dispatch(setLocalCourse(activeCourse));
        dispatch(setPopup(null))
    };

    return (
        <div className='scheduler-select-subject-main-container'>
            <div className='scheduler-select-subject-inner-container'>
                <div className='scheduler-select-subject-select-exam-title'>
                    Select Your Exam
                </div>
                <div className='scheduler-select-subject-container'>
                    {
                        courses?.map((course) => {
                            return (
                                <div className={`scheduler-select-subject-item ${activeCourse === course.id ? "active" : ""}`} onClick={(e) => handleSubjectChangeButton(e, course.id)}>
                                    <div className='scheduler-select-subject-item-sub-name'>
                                        {course.short_name}
                                    </div>
                                    <div className='scheduler-select-subject-item-active' />
                                </div>
                            )
                        })
                    }
                </div>
                <div className='scheduler-select-subject-apply' onClick={onApply}>
                    Apply
                </div>
            </div>
        </div>
    );
};

export default SelectSubjects;