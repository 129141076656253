import { SETSUBJECTS, UPDATEDAILYTASK, SETDAILYQUIZ, SETBANNERS } from '../actions/homeActions/actionType';
import { LOGOUT } from '../actions/authActions/actionType';

const initialState = {
    subjects: null,
    dailyTasks: null,
    dailyQuiz: null,
    banners: null,
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETBANNERS:
            return {
                ...state,
                banners: action.payload,
            };

        case SETSUBJECTS:
            return {
                ...state,
                subjects: action.payload,
            };

        case UPDATEDAILYTASK:
            return {
                ...state,
                dailyTasks: action.payload,
            };

        case SETDAILYQUIZ:
            return {
                ...state,
                dailyQuiz: action.payload,
            };

        case LOGOUT:
            return {
                ...state,
                dailyTasks: null,
            };

        default:
            return state;
    }
};

export default homeReducer;
