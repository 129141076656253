import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeUserRole } from "services/groupStudy";
import { getCircleColor, getShortName } from "utils/Functions";

import "./MakeLeader.scss";

const MakeLeader = ({ onClosePopup }) => {
    const popupData = useSelector((store) => store.ui.popupData);
    const user = useSelector((store) => store.user.userInfo);
    const groupMembers = useSelector((store) => store.group.groupMembers);
    const dispatch = useDispatch();

    const [filteredMembers, setFilteredMembers] = useState(groupMembers);
    const [selectedUser, setSelectedUser] = useState(null);

    const onChangeText = (e) => {
        let members = groupMembers.filter((member) => member?.fullName?.toLowerCase()?.includes(e.target.value?.toLowerCase()));
        setFilteredMembers(members);
    };

    const onSelectUser = (user) => {
        setSelectedUser(user);
    };

    const onMakeLeader = async () => {
        if (selectedUser) {
            await dispatch(changeUserRole(selectedUser, "leader", { loader: true }));
            onClosePopup();
        }
    };

    const getCircleStyle = (member) => {
        if (!member) return { opacity: 0 };
        let backgroundColor = "#FECFC7";
        let color = "#FECFC7";
        if (member?.color) {
            backgroundColor = getCircleColor(member?.color);
            color = `rgb(${member?.color})`;
        }
        return { backgroundColor, color };
    };

    return (
        <div className="make-leader-popup">
            <div onClick={onClosePopup} className="cross">
                <img src={require("assets/svgIcons/cross.svg")} />
            </div>
            <div className="tick-wrapper">
                <img src={require("assets/svgIcons/circle-tick.svg")} />
            </div>
            <p className="title">{popupData?.title ?? "Transfer your leadership!"}</p>
            <div className="search-container">
                <p className="search-heading">Friends</p>
                <div className="search-box">
                    <img src={require("assets/svgIcons/group-study-search.svg")} />
                    <input onChange={onChangeText} />
                </div>
            </div>
            <div className="user-list">
                {filteredMembers?.map((member, index) => {
                    if (member?.userId === user.id) return null;
                    return (
                        <div onClick={() => onSelectUser(member)} key={index} className={`list-wrapper ${selectedUser?.id === member?.id ? "active" : ""}`}>
                            <div className="list-circle" style={getCircleStyle(member)}>
                                {getShortName(member?.fullName)}
                            </div>
                            <p className="name">{member?.fullName}</p>
                        </div>
                    );
                })}
            </div>
            <button onClick={onMakeLeader} className="btn-primary">
                Make Leader
            </button>
        </div>
    );
};

export default MakeLeader;
