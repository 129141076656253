import { lazyWithPreload } from './Functions';
const HomePageRevised = lazyWithPreload(() => import('Pages/HomePageRevised/HomePageRevised'));
const UserOnboardingOne = lazyWithPreload(() => import('Pages/UserOnboardingOne/UserOnboardingOne'));
const UserOnboardingTwo = lazyWithPreload(() => import('Pages/UserOnboardingTwo/UserOnboardingTwo'));
const AboutExamarly = lazyWithPreload(() => import('Pages/AboutExamarly/AboutExamarly'));
const GuestAssessment = lazyWithPreload(() => import('Pages/UserAssessment/UserAssessment'));
const WrapperComponentForLandingPage = lazyWithPreload(() => import("Pages/../Components/WrapperComponentForLandingPage"));
const GuestAssessmentResult = lazyWithPreload(() => import('Pages/UserAssessmentResult/UserAssessmentResult'));
const UserSurvey = lazyWithPreload(() => import('Pages/UserSurvey/UserSurvey'));
const WeakSubjects = lazyWithPreload(() => import('Pages/WeakSubjects/WeakSubjects'));
const SignUp = lazyWithPreload(() => import('Pages/SignUp/SignUp'));
const Login = lazyWithPreload(() => import('Pages/Login/Login'));
const HomePage = lazyWithPreload(() => import('Pages/HomePage/HomePage'));
const Planning = lazyWithPreload(() => import('Pages/Planning/Planning'));
const Revision = lazyWithPreload(() => import('Pages/Revision/Revision'));
const SubTopic = lazyWithPreload(() => import('Pages/SubTopic/SubTopic'));
const SubtopicResource = lazyWithPreload(() => import('Pages/SubtopicResource/SubtopicResource'));
const ResourceViewer = lazyWithPreload(() => import('Pages/ResourceViewer/ResourceViewer'));
const AboutUs = lazyWithPreload(() => import('Pages/AboutUs/AboutUs'));
const PrivacyPolicy = lazyWithPreload(() => import('Pages/PrivacyPolicy/PrivacyPolicy'));
const TermsAndCondition = lazyWithPreload(() => import('Pages/TermsAndCondition/TermsAndCondition'));
// const FAQ = lazyWithPreload(() => import('Pages/FAQ/FAQ'));
const HelpAndSupport = lazyWithPreload(() => import('Pages/HelpAndSupport/HelpAndSupport'));
const Doubts = lazyWithPreload(() => import('Pages/Doubts/Doubts'));
const Store = lazyWithPreload(() => import('Pages/Store/Store'));
const Refree = lazyWithPreload(() => import('Pages/Refree/Refree'));
const CurrentAffairs = lazyWithPreload(() => import('Pages/CurrentAffairs/CurrentAffairs'));
const Profile = lazyWithPreload(() => import('Pages/UserProfile/UserProfile'));
const Stats = lazyWithPreload(() => import('Pages/Stats/Stats'));
const QuizList = lazyWithPreload(() => import('Pages/QuizList/QuizList'));
const Quiz = lazyWithPreload(() => import('Pages/Quiz/Quiz'));
const QuizInstructions = lazyWithPreload(() => import('Pages/QuizInstructions/QuizInstructions'));
const Subject = lazyWithPreload(() => import('Pages/Subject/Subject'));
// const LandingPage = lazyWithPreload(() => import('Pages/LandingPage/LandingPage'));
const Result = lazyWithPreload(() => import('Pages/AssessmentResult/AssessmentResult'));
const ReferRedirect = lazyWithPreload(() => import('Pages/ReferRedirect/ReferRedirect'));
const GuestLogin = lazyWithPreload(() => import('Pages/GuestLogin/GuestLogin'));
const InitialGroupPage = lazyWithPreload(() => import('Pages/InitialGroupPage/InitialGroupPage'));
const CreateGroup = lazyWithPreload(() => import('Pages/CreateGroup/CreateGroup'));
const JoinGroup = lazyWithPreload(() => import('Pages/JoinGroup/JoinGroup'));
const UserGroup = lazyWithPreload(() => import('Pages/UserGroup/UserGroup'));
const GroupLeaderBoard = lazyWithPreload(() => import('Pages/GroupLeaderBoard/GroupLeaderBoard'));
const GroupMembers = lazyWithPreload(() => import('Pages/GroupMembers/GroupMembers'));
const Scheduler = lazyWithPreload(() => import('Pages/Scheduler'));
const SchedulerOnboarding = lazyWithPreload(() => import('Pages/SchedulerOnboarding'));


export const Routes = [
    {
        name: 'Homepage',
        path: '/home',
        component: Scheduler,
        type: 'verified',
    },
    {
        name: "About",
        path: "/aboutUs",
        component: WrapperComponentForLandingPage,
        type: "common",
    },
    {
        name: "About",
        path: "/aboutus/meetTheTeam",
        component: WrapperComponentForLandingPage,
        type: "common",
    },
    {
        name: 'Homepage',
        path: '/temp',
        component: Result,
        type: 'common',
    },
    {
        name: 'Quiz List',
        path: '/home/quizlist',
        component: QuizList,
        type: 'verified',
    },
    {
        name: 'quiz',
        path: '/home/quiz/:questionId',
        component: Quiz,
        type: 'verified',
    },
    {
        name: 'quiz-instructions',
        path: '/instructions/:page/:quiz',
        component: QuizInstructions,
        type: 'common',
    },
    {
        name: 'Quiz Module',
        path: '/quiz/:page/:quiz/:questionId',
        component: Quiz,
        type: 'common',
    },
    {
        name: 'Subject',
        path: '/home/subject/:subjectId',
        component: Subject,
        type: 'verified',
    },
    {
        name: 'SchedulerOnboarding',
        path: "/home/onboard",
        component: SchedulerOnboarding,
        type: 'verified',
    },
    {
        name: 'Syllabus',
        path: '/syllabus/:subjectId',
        component: Planning,
        type: 'verified',
    },
    {
        name: 'Rivision',
        path: '/revision',
        component: Revision,
        type: 'verified',
        exact: true,
    },
    {
        name: 'Sub Topic',
        path: '/syllabus/subtopic/:topicId',
        component: SubTopic,
        type: 'verified',
    },
    {
        name: 'Sub Topic Resource',
        path: '/syllabus/subtopic/:resource/:subtopicId/:resourceId',
        component: SubtopicResource,
        type: 'verified',
    },
    {
        name: 'Article Page',
        path: '/syllabus/subtopic/:resource/:subtopicId/:resourceId/:subResourceId',
        component: ResourceViewer,
        type: 'verified',
    },
    {
        name: 'Doubts',
        path: '/doubts',
        component: Doubts,
        type: 'verified',
    },
    {
        name: "ContactUs",
        path: "/contactUs",
        component: WrapperComponentForLandingPage,
        type: "common",
    },
    {
        name: 'Store',
        path: '/store/:type',
        component: Store,
        type: 'verified',
    },
    {
        name: 'Current Affair',
        path: '/home/currentaffairs',
        component: CurrentAffairs,
        type: 'verified',
    },
    {
        name: 'User Profile',
        path: '/profile',
        component: Profile,
        type: 'verified',
    },
    {
        name: 'User Statistics',
        path: '/stats',
        component: Stats,
        type: 'verified',
    },
    {
        name: 'Privacy Policy',
        path: '/privacypolicy',
        component: PrivacyPolicy,
        type: 'common',
    },
    {
        name: 'Terms And Conditions',
        path: '/terms',
        component: TermsAndCondition,
        type: 'common',
    },
    {
        name: 'Landing Page',
        path: '/',
        component: WrapperComponentForLandingPage,
        type: 'unverified',
    },
    {
        name: 'Assessment Rules',
        path: '/assessment-rules',
        component: UserOnboardingTwo,
        type: 'common',
    },
    {
        name: 'Guest Assessment Result',
        path: '/assessment-result',
        component: GuestAssessmentResult,
        type: 'common',
    },
    {
        name: 'Survey',
        path: '/user-survey',
        component: UserSurvey,
        type: 'common',
    },
    {
        name: 'Weak Subjects',
        path: '/weak-subjects',
        component: WeakSubjects,
        type: 'common',
    },
    {
        name: 'Signup',
        path: '/signup',
        component: SignUp,
        type: 'guest',
    },
    {
        name: 'Login',
        path: '/login',
        component: Login,
        type: 'guest',
    },
    {
        name: 'About Us',
        path: '/aboutus',
        component: WrapperComponentForLandingPage,
        type: 'common',
    },
    {
        name: 'FAQ',
        path: '/faq',
        component: WrapperComponentForLandingPage,
        type: 'common',
    },
    {
        name: 'Help And Support',
        path: '/helpandsupport',
        component: HelpAndSupport,
        type: 'common',
    },
    {
        name: 'Refer And Earn',
        path: '/referandearn',
        component: Refree,
        type: 'common',
    },
    {
        name: 'Refer Redirect',
        path: '/referredirect',
        component: ReferRedirect,
        type: 'common',
    },
    {
        name: 'Guest Login',
        path: '/guest',
        component: GuestLogin,
        type: 'unverified',
    },
    {
        name: 'GroupId Redirect',
        path: '/groups',
        component: JoinGroup,
        type: 'nogroup',
    },
    {
        name: 'Group Study',
        path: '/group-study',
        component: InitialGroupPage,
        type: 'nogroup',
    },
    {
        name: 'Create Group',
        path: '/group-study/create',
        component: CreateGroup,
        type: 'nogroup',
    },
    {
        name: 'Join Group',
        path: '/group-study/join',
        component: JoinGroup,
        type: 'nogroup',
    },
    {
        name: 'User Group',
        path: '/group-study/:groupId',
        component: UserGroup,
        type: 'group',
    },
    {
        name: 'Group Members',
        path: '/group-study/:groupId/members',
        component: GroupMembers,
        type: 'group',
    },
    {
        name: 'Group Leaderboard',
        path: '/group-study/:groupId/leaderboard/:title',
        component: GroupLeaderBoard,
        type: 'group',
    },
];

export const CrawlRoutes = [
    {
        name: 'Privacy Policy',
        path: '/privacypolicy',
        component: PrivacyPolicy,
        type: 'common',
    },
    {
        name: "ContactUs",
        path: "/contactUs",
        component: WrapperComponentForLandingPage,
        type: "common",
    },
    {
        name: 'Terms And Conditions',
        path: '/terms',
        component: TermsAndCondition,
        type: 'common',
    },
    {
        name: 'Landing Page',
        path: '',
        component: WrapperComponentForLandingPage,
        type: 'unverified',
    },
    {
        name: 'Survey',
        path: '/user-survey',
        component: UserSurvey,
        type: 'common',
    },
    {
        name: 'Signup',
        path: '/signup',
        component: SignUp,
        type: 'guest',
    },
    {
        name: 'Login',
        path: '/login',
        component: Login,
        type: 'guest',
    },
    {
        name: 'About Us',
        path: '/aboutus',
        component: WrapperComponentForLandingPage,
        type: 'common',
    },
    {
        name: 'FAQ',
        path: '/faq',
        component: WrapperComponentForLandingPage,
        type: 'common',
    },
];
