import React from "react";
import Options from "./Component";
import "./EarlyBird.scss";
import { useSelector, useDispatch } from "react-redux";
import { setPopup } from "redux/actions/uiActions/actions";
import { updateEarlyBirdPopup } from "services/payments";
import { setUpdateSubscribedPopup } from "redux/actions/EARLYBIRD/actions";
import { throttleFunction } from "utils/Functions";

const EarlyBirdPopup = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setPopup(null));
    dispatch(setUpdateSubscribedPopup("skip"));
    dispatch(updateEarlyBirdPopup({ loader: true }));
  };

  let vh = window.innerHeight * 0.01;
  return (
    <div
      className="early-bird-popup animate"
      onClick={() => {
        dispatch(setPopup(16));
      }}
    >
      <div className="early-bird-popup-container">
        <img
          className="early-bird-popup-image"
          src={require("assets/svg/EarlyBirdPopup.svg")}
          alt="earlyBird"
        />
      </div>
      <div className="early-bird-popup-content">
        <div className="early-bird-popup-center-part">
          <img
            className="early-bird-popup-premium-image"
            src={require("assets/svg/examarlyPremium.svg")}
            alt="examarlyPremium"
          />
          <div className="early-bird-popup-center-part-second-line">
            Howdy! Early Bird
          </div>
          <div className="early-bird-popup-center-part-third-line">
            ready to step-up your preparations?
          </div>
          <div className="early-bird-popup-center-part-fourth-line">
            SUBSCRIBE NOW
          </div>
        </div>

        <div className="early-bird-popup-cross">
          <div
            onClick={() => {
              handleClick();
            }}
          >
            <img
              width="15px"
              src={require("assets/svgIcons/earlyBirdCrossFrame.svg")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EarlyBirdPopup;
