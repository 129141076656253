import Request from 'utils/Request';
import { setUserData, setWalletDetails, updateUserInfo } from 'redux/actions/userActions/actions';
import { setTourInfo } from 'redux/actions/uiActions/actions';
import Axios from 'axios';

/**
 * This function is used to get User data when token is stored in local storage
 * @param {*} config {loader} {type}
 * @returns
 */
export const getUserData = (config) => async (dispatch) => {
  try {
    const response = await Request({
      method: "GET",
      url: "/user/detail",
      ...config,
    });

    const { status_code, data } = response.data;

    if (status_code === 200) {
      dispatch(setUserData(data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUserWalletDetails = (config) => async (dispatch) => {
  try {
    const response = await Request({
      method: "GET",
      url: "/user/wallet/detail",
      ...config,
    });

    const { Code, message, data } = response.data;

    if (Code === 0) {
      //show error here if needed
    }
    if (Code === 1) {
      dispatch(setWalletDetails(data?.userWallet ?? null));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCommunityProfile = (userData) => async(dispatch, getState) => {
    try {
        const token = getState().auth.token
        let header = {
            headers: {"Authorization" : token} 
        }

        let bodyData = {
            'username': userData?.fullName
        }

        const url = "https://communitybackend-stage.examarly.com/api/community/profiles"

        const response = await Axios.put(url, bodyData,header)
        const { status_code, data } = response.data;

        if (status_code === 200) {
            return true
        } else {
            return false
        }
        
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const updateUserData = (userData) => async (dispatch) => {
  try {
      const response = await Request({
          method: 'POST',
          url: '/user/update',
          data: userData,
      });

      const { status_code, data } = response.data;
      if (status_code === 204) {
          dispatch(
              updateUserInfo({
                  fullName: data?.user?.fullName,
              })
          );
          dispatch(updateCommunityProfile(userData))
          return true;
      }
      return false;
  } catch (error) {
      console.log(error);
      return false;
  }
};

export const updateWeakSubject =
  (weakSubject, config) => async (dispatch, getState) => {
    try {
      const courseId = getState().planning.activeCourse;
      const response = await Request({
        method: "POST",
        url: "/subject/weakSubjects/add",
        data: {
          courseId,
          subjectId: weakSubject,
        },
        ...config,
      });

      const { status_code, message, data } = response.data;

      if (status_code === 200) {
        dispatch(
          updateUserInfo({
            weakSubject: [weakSubject],
            newSubject: false,
          })
        );
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const updateStreak = (config) => async (dispatch, getState) => {
  try {
    const streak = getState().user.userInfo.userStreak;
    const response = await Request({
      method: "POST",
      url: "/user/update/streak",
      data: {
        date: new Date(),
      },
      ...config,
    });

    const { Code, data } = response.data;

    if (Code === 1) {
      let date = new Date();
      date.setHours(0, 0, 0, 0);
      dispatch(
        updateUserInfo({
          userStreak: [...streak, date.toISOString()],
        })
      );
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const grantCoins =
  (coins, message = "Coins Added", config) =>
  async (dispatch) => {
    try {
      const response = await Request({
        method: "POST",
        url: "/user/coins/add",
        data: {
          testCoins: coins,
          message,
        },
        ...config,
      });

      const { Code, data } = response.data;

      if (Code === 1) {
        dispatch(setWalletDetails(data?.wallet ?? null, "earn"));
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

export const useCoins =
  (coins, message = "added", config) =>
  async (dispatch) => {
    try {
      const response = await Request({
        method: "POST",
        url: "/user/coins/use",
        data: {
          testCoins: coins,
          message,
        },
        ...config,
      });

      const { Code, data } = response.data;

      if (Code === 1) {
        dispatch(setWalletDetails(data?.wallet ?? null, "spend"));
        return true;
      }

      return {
        error: response?.data?.message,
      };
    } catch (error) {
      return {
        error: "Something went wrong!",
      };
    }
  };

export const addRating = (userRating, config) => async (dispatch) => {
  try {
    const response = await Request({
      method: "POST",
      url: "/user/update/rating",
      data: {
        userRating,
      },
      ...config,
    });

    const { Code, data } = response.data;

    if (Code === 1) {
      dispatch(updateUserInfo({ ratingGiven: true }));
      dispatch(setWalletDetails(data?.wallet, "earn"));
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
  }
};

export const addSignupBonus = (config) => async (dispatch) => {
  try {
    const response = await Request({
      method: "POST",
      url: "user/signup/coins",
      ...config,
    });

    const { Code, data } = response.data;

    if (Code === 1) {
      dispatch(updateUserInfo({ signUpCoinsRedeemed: true }));
      dispatch(setWalletDetails(data?.wallet, "earn"));
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
  }
};

export const updateTourData = (data, path) => (dispatch, getState) => {
  try {
    Request({
      method: "POST",
      url: "/user/tour",
      data,
    });
    dispatch(updateUserInfo(data));
    path != undefined && dispatch(setTourInfo(path));
  } catch (e) {}
};

export const updateQuizUnlocked = (date) => async (dispatch, getState) => {
  try {
    const dailyQuiz = getState().user.userInfo.userDailyQuiz;
    const response = await Request({
      method: "POST",
      url: "/user/update/daily/quiz",
      data: {
        date: date.setHours(12),
      },
    });

    const { Code, data } = response?.data;

    if (Code === 1) {
      date.setHours(0, 0, 0, 0);
      dispatch(
        updateUserInfo({
          userDailyQuiz: [...dailyQuiz, date.toISOString()],
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateQuizCompleted = (date) => async (dispatch, getState) => {
  try {
    const dailyQuizCompleted = getState().user.userInfo.userDailyQuizCompleted;
    const response = await Request({
      method: "POST",
      url: "/user/update/daily/quiz/completed",
      data: {
        date: date.setHours(12),
      },
    });

    const { Code, data } = response?.data;

    if (Code === 1) {
      date.setHours(0, 0, 0, 0);
      dispatch(
        updateUserInfo({
          userDailyQuizCompleted: [...dailyQuizCompleted, date.toISOString()],
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};
