import Request from 'utils/Request';
import { setCourseStats, setScoreData, setStatDetails, setStreakDetails, setStreakList } from 'redux/actions/statActions/actions';
import { trackStreakData } from 'analytics/streak';
import { setPopup } from 'redux/actions/uiActions/actions';
import { setTestRewards } from 'redux/actions/assessmentActions/actions';
import { trackRewardsCollect } from 'analytics/stats';

export const getScoreData = () => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: 'GET',
            url: '/planning/progress',
            params: { courseId },
        });

        const { status_code, data } = response.data;

        if (status_code === 200) {
            dispatch(setScoreData(data));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
    }
};

export const getStatData = () => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: 'GET',
            url: '/user/stats',
            params: { courseId },
        });

        const { status_code, data } = response.data;
        if (status_code === 200) {
            dispatch(setStatDetails(data));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
    }
};

export const getStreakData = (config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: 'GET',
            url: '/streak',
            params: { courseId },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(setStreakDetails(data));
            trackStreakData(data?.currentStreak, data?.longestStreak);
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getMonthlyStreak = (year, month, config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: 'GET',
            url: '/streak/list',
            ...config,
            params: {
                year: year,
                month: month,
                courseId,
            },
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(setStreakList(data?.streakList ?? []));
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getCourseStats = (config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const response = await Request({
            method: 'GET',
            url: '/course/courseDetails',
            params: { courseId },
            ...config,
        });

        const { status_code, message, data } = response.data;

        if (status_code === 200) {
            dispatch(setCourseStats(data ?? []));
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const collectXp = (config) => async (dispatch, getState) => {
    try {
        const courseId = getState().planning.activeCourse;
        const statDetail = getState().stat.statDetail;
        let rewards = getState().assessment.rewards ?? {};
        const response = await Request({
            method: 'POST',
            url: '/scheduler/collectRewards',
            params: {
                courseId,
            },
            ...config,
        });
        const { status_code, message, data } = response.data;
        if (status_code === 200) {
            trackRewardsCollect(data?.xp, statDetail?.stackedxp, data?.level);
            dispatch(
                setStatDetails({
                    ...statDetail,
                    xpEarned: data?.xp,
                    stackedxp: data?.stackedXp,
                    xpProgress: data?.xpProgress,
                    level: data?.level,
                    xpNeeded: data?.xpNeeded,
                    // level: data?.isLevelUp ? (statDetail?.level ?? 0) + 1 : 0,
                })
            );
            if (data?.isLevelUp) {
                rewards['isLevelUp'] = data?.isLevelUp;
                dispatch(setTestRewards({...rewards}));
                dispatch(
                    setPopup(12, {
                        animationPath: require('assets/animations/level-up.json'),
                        share: true,
                        progress: false,
                        type: 'level',
                        xp: data?.xp,
                        Continue: () => console.log(),
                        source: 'level up completion',
                    })
                );
            }
            return true;
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

