import { trackNewEvent } from './base';

export const trackSubscribeButton = () => {
    trackNewEvent('Early Bird Event', { Subscribe_Button_Click: true });
};

export const trackPaymentStart = () => {
    trackNewEvent('Early Bird Event', { Transaction_Start: true });
};

export const trackPaymentSuccess = () => {
    trackNewEvent('Early Bird Event', { Transaction_Successful: true });
};

export const trackPaymentFailure = () => {
    trackNewEvent('Early Bird Event', { Transaction_Failure: true });
};
