import { SETACTIVECOURSE, SETCOURSELIST, SETPLANNINGDATA, SETSUBJECTLIST, SETSUBTOPICS, UPDATESUBTOPIC, SETCONTENT, SETSUBJECTPREFERENCELIST, SETAUTOASSIGNEDPREFERENCELIST } from '../actions/planningTrackingActions/actionType';
import { LOGOUT } from '../actions/authActions/actionType';

const initalState = {
    activeCourse: null,
    courseList: null,
    subjectList: null,
    planningData: {},
    subtopicData: {},
    content: {},
    subjectPreferenceList: null,
    autoAssignedPreferenceList: null
};

const planningTrackingReducer = (state = initalState, action) => {
    switch (action.type) {
        case SETACTIVECOURSE:
            return {
                ...state,
                activeCourse: action.payload,
            };

        case SETCOURSELIST:
            return {
                ...state,
                courseList: action.payload,
            };

        case SETPLANNINGDATA:
            return {
                ...state,
                planningData: action.payload,
            };

        case SETSUBJECTLIST:
            return {
                ...state,
                subjectList: action.payload,
            };

        case SETSUBTOPICS:
            return {
                ...state,
                subtopicData: action.payload,
            };

        case SETCONTENT:
            return {
                ...state,
                content: action.payload,
            };

        case SETSUBJECTPREFERENCELIST:
            return {
                ...state,
                subjectPreferenceList: action.payload,
            };
        
        case SETAUTOASSIGNEDPREFERENCELIST:
            return {
                ...state,
                autoAssignedPreferenceList: action.payload
            };

        case UPDATESUBTOPIC:
            return {
                ...state,
                planningData: action.payload.planningData,
                subtopicData: action.payload.subtopicData,
            };

        case LOGOUT:
            return {
                ...state,
                planningData: {},
                subtopicData: {},
                books: {},
            };

        default:
            return state;
    }
};

export default planningTrackingReducer;
