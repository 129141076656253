export const SETACTIVECOURSE = 'SETACTIVECOURSE';
export const SETCOURSELIST = 'SETCOURSELIST';
export const SETSUBJECTLIST = 'SETSUBJECTLIST';
export const SETPLANNINGDATA = 'SETPLANNINGDATA';
export const SETSUBTOPICS = 'SETSUBTOPIC';
export const SETBOOKS = 'SETBOOKS';
export const UPDATESUBTOPIC = 'UPDATESUBTOPIC';
export const SETCONTENT = 'SETCONTENT';
export const SETSUBJECTPREFERENCELIST = 'SETSUBJECTPREFERENCELIST';
export const SETAUTOASSIGNEDPREFERENCELIST = 'SETAUTOASSIGNEDPREFERENCELIST';
