import {
    SETPLANLIST,
    SETUSERPLANS,
    SETGSTDATA
} from '../actions/planActions/actionType';
import {
    LOGOUT
} from '../actions/authActions/actionType';

const initialState = {
    plans: null,
    userPlans: null,
    gstData: null
};

const planReducer = (state = initialState, action) => {
    switch (action.type) {

        case SETPLANLIST: return {
            ...state,
            plans: action.payload
        };

        case SETUSERPLANS: return {
            ...state,
            userPlans: action.payload
        };

        case SETGSTDATA: return {
            ...state,
            gstData: action.payload
        };

        case LOGOUT: return {
            ...state,
            userPlans: null
        }


        default:
            return state;
    }
};

export default planReducer;
