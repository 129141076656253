import Request from "utils/Request";
import useRedirect from "customhooks/useRedirect";
import { useState } from "react";
import { setPopup } from "redux/actions/uiActions/actions";
import { setUpdateSubscribedPopup } from "redux/actions/EARLYBIRD/actions";
import { Base64 } from "utils/Functions";
import { trackPaymentFailure, trackPaymentStart, trackPaymentSuccess } from "analytics/earlybird";

export const showEarlyBirdPopup = (config) => async (dispatch) => {
  try {
    const response = await Request({
      method: "POST",
      url: "/earlyBird/showPopup",
      ...config,
    });

    const { status_code, data } = response.data;

    // In case of paid
    if (data.status == "paid") {
      dispatch(setUpdateSubscribedPopup("paid"));
      return;
    }

    // In case of unpaid
    if (data.data == false) {
      dispatch(setUpdateSubscribedPopup("skip"));
      return;
    }

    if (data.data == true) {
      dispatch(setUpdateSubscribedPopup("unpaid"));
      return;
    }
  } catch (error) {
    return false;
  }
};

export const updateEarlyBirdPopup = (config) => async (dispatch) => {
  try {
    const response = await Request({
      method: "POST",
      url: "/earlyBird/showPopup",
      params: {
        crossButton: true,
      },
      ...config,
    });

    const { status_code, data } = response.data;

    if (status_code === 200 && data.data == false) {
      return dispatch(setUpdateSubscribedPopup("skip"));
    }
  } catch (error) {
    return;
  }
};

export const paymentGateway = (config) => async (dispatch) => {
  try {
    trackPaymentStart()
    const response = await Request({
      method: "POST",
      url: `/payments/createOrder`,
      params: {
        returnUrl: "https://examarly.com/home/?order_id={order_id}",
        currency: "INR",
      },
      ...config,
    });

    const { status_code, data } = response.data;
    const order_id = data?.order_id;

    if (status_code === 200) {
      const encodedOrderId = Base64.encode(order_id);
      let paymentSessionId = data?.payment_session_id;
      let cashfree = new window.Cashfree(paymentSessionId);
      cashfree.redirect();
      localStorage.setItem("paymentsOrderId", encodedOrderId);
    }
  } catch (error) {
    console.log(error);
  }
};

export const isRedirectedFromCashFree = (config) => async (dispatch) => {
  try {
    const paymentsOrderId = localStorage.getItem("paymentsOrderId");
    const decodedPaymentsOrderId = Base64.decode(paymentsOrderId);
    if (paymentsOrderId) {
      const response = await Request({
        method: "GET",
        url: "/payments/status",
        params: {
          orderId: decodedPaymentsOrderId,
        },
        ...config,
      });
      const { status_code, data } = response.data;
      const order_status = data?.order_status;
      localStorage.removeItem("paymentsOrderId")
      if (order_status === "PAID") {
        trackPaymentSuccess()
        dispatch(setUpdateSubscribedPopup("paid"));
        dispatch(setPopup(17));
        return true;
      } else {
        trackPaymentFailure()
        dispatch(setPopup(19));
        return false;
      }
    }
  } catch (err) {
    console.log(err);
  }
};
