import React, { useEffect } from "react";
import { cloneDeep } from "lodash";
import Lottie from "react-lottie";
import { Options, OptionsForCongratulations } from "./Component";
import "./Congratulations.scss";
import Slider from "react-slick";
import { useSelector, useDispatch } from "react-redux";
import { setPopup } from "redux/actions/uiActions/actions";
import { EarlyBird, earlyBirdOptions } from "./Earlybird";
import { useHistory } from "react-router-dom";
import Bird_Congratulaion from "assets/animations/Bird_Congratulation.json";
import Bird_Congratulation_650X350 from "assets/animations/Bird_Congratulation_650X350.json";
const EarlyBirdCongratulations = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerPadding: "100px",
    arrows: false,
  };
  const dispatch = useDispatch();

  const defaultOptions = {
    loop: false,
    autoplay: true,
  };

  const handleClickButton = () => {
    localStorage.removeItem("paymentsOrderId");
    dispatch(setPopup(null));
  };

  let vh = window.innerHeight * 0.01;

  return (
    <div className="early-bird-congratulations-container">
      <div className="early-bird-examarly-logo">
        <img src={require("assets/svg/logo-examarly.svg")} />
      </div>
      <div className="early-bird-congratulations-logo">
        <Lottie
          options={{
            ...defaultOptions,
            animationData: cloneDeep(Bird_Congratulaion),
          }}
        />
      </div>
      <div className="ealry-bird-congratulations-container">
        <div className="early-bird-congratulations-first-line animate">
          Congratulations
        </div>
        <div className="early-bird-congratulations-second-line animate">
          Soon you’ll be able to enjoy these perks!
        </div>
      </div>
      <div className="early-bird-congratulations-options-container animate">
        {earlyBirdOptions.map((earlyBirdOptions) => {
          return (
            <OptionsForCongratulations
              key={earlyBirdOptions.id}
              earlyBirdOptions={earlyBirdOptions}
            />
          );
        })}
      </div>
      <div className="early-bird-congratulations-options-container-for-mobile-view">
        <Slider {...settings}>
          {earlyBirdOptions.map((earlyBirdOptions) => {
            return (
              <OptionsForCongratulations
                key={earlyBirdOptions.id}
                earlyBirdOptions={earlyBirdOptions}
              />
            );
          })}
        </Slider>
      </div>

      <div className="early-bird-congratulations-footer animate">
        <div className="early-bird-footer-congratulations-first-line">
          You’ll be amongst the first to enjoy these perks and many more. Save
          the date!
        </div>
        <div className="early-bird-footer-congratulations-second-line">
          25th Feb 2023
        </div>

        <div className="early-bird-footer-congratulations-button">
          <button
            className="early-bird-dark-btn"
            onClick={() => {
              handleClickButton();
            }}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default EarlyBirdCongratulations;
