import { trackEvent } from './base';

export const trackRevisionVisited = (source) => {
    trackEvent('Revision Homepage', {
        source
    });
};

export const trackRevisionTaskCompletion = (taskIndex) => {
    trackEvent('Revision Homepage', {
        task_name: 'Task-' + taskIndex,
        completion_date: new Date().toISOString()
    });
};

export const trackTestStarted = () => {
    trackEvent('Revision Test', {
        test_started: true
    });
};

export const tracknextClicked = () => {
    trackEvent('Revision Homepage', {
        next_revision_clicked: true
    })
}